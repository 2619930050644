<template>
  <div id="stats-pane-wrapper" style="overflow: hidden">
    <div id="stats-pane">

      <stats-pane-header :offset-y="_offsetHeader" @action="_scrollToTop"/>

      <div class="stats-results" @scroll="_onScrollResults">
        <div class="stats-results-content">
          <div class="selection-pane-groups" v-for="(key, index) in _notEmptyOrderedGroupementKeys">
            <h1 class="m-2 flex" v-if="_groupedResults[key]" style="line-height: 24px">
              <span class="ml-2 mr-2 mt-0" v-if="_groupedResults[key].leagueUID">
                <league-fav-indicator :uid="_groupedResults[key].leagueUID"/>
              </span>
              <span class="flex-grow text-left">
                {{ _groupedResults[key].label }}

                <a-dropdown :trigger="['click']" class="ml-2" v-if="index === 0">
                <a-tooltip placement="top" title="Personnaliser">
                  <button class="small info">
                    <font-awesome-icon icon="fa-solid fa-filter"/>
                  </button>
                </a-tooltip>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="_setCritereTri('NOTE')">
                      Trier par note de correspondance
                    </a-menu-item>
                    <a-menu-item @click="_setCritereTri('DATETIME')">
                      Trier par date/heure
                    </a-menu-item>
                    <hr/>
                    <a-menu-item @click="_setCritereRegroupement('LEAGUE')">
                      Grouper par compétition
                    </a-menu-item>
                    <a-menu-item @click="_setCritereRegroupement('DATETIME')">
                      Grouper par date/heure
                    </a-menu-item>
                    <a-menu-item @click="_setCritereRegroupement('NONE')">
                      Ne pas grouper
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
                <a-dropdown :trigger="['click']" class="ml-1" v-if="index === 0">
                  <a-tooltip placement="top" title="Cotes affichées">
              <button class="small info mr-1">
                <font-awesome-icon icon="fa-solid fa-bullseye"/>
                {{ _offerTypeLabel }}
              </button>
                  </a-tooltip>
              <template #overlay>
                <a-menu>
              <span v-for="c in _sportOfferFamilyChoices">
                <a-menu-item @click="_onFamilyChange(c.value, null)" v-if="c.args === null">
                  {{ c.label }}
                </a-menu-item>
                <a-sub-menu :key="c.value" :title="c.label" v-else>
                  <a-menu-item v-for="arg in c.args" @click="_onFamilyChange(c.value, arg)">
                    {{ arg }}
                  </a-menu-item>
                </a-sub-menu>
              </span>
                </a-menu>
              </template>
            </a-dropdown>
              </span>
              <span class="mr-2" v-if="_groupedResults[key].leagueUID">
                <league-logo :uid="_groupedResults[key].leagueUID"/>
              </span>
              <span class="mr-2" v-if="_groupedResults[key].area">
                <area-flag :area="_groupedResults[key].area"/>
              </span>


            </h1>
            <stats-game-item v-for="(item, itemIndex) in _groupedResults[key].items" :key="item.gameUID"
                             v-if="_groupedResults[key]"
                             @mouseenter="_updateOverContext(item)"
                             :item="item" :show-ad="index===2 && itemIndex===0" class="mb-3 mx-1"/>

          </div>
          <button class="primary mb-5" v-if="_canLoadMore" @click="_loadNextPage">
            Afficher plus de résultats
          </button>
        </div>
      </div>
      <stats-pane-filters :offset-y="_offsetFilters"/>
    </div>
  </div>
</template>
<script>

import {preferencesStore} from "@/stores/Preferences.store";
import {offerStore} from "@/stores/Offer.store";
import dateService from "@/services/date.service";
import {gameStore} from "@/stores/Game.store";
import {notebookStore} from "@/stores/NotebookStore";
import StatsPaneHeader from "@/components/routes/stats/panes/stats/header/StatsPaneHeader.vue";
import StatsPaneFilters from "@/components/routes/stats/panes/stats/filters/StatsPane.Filters.vue";
import {statsPaneStore} from "@/components/routes/stats/panes/stats/StatsPane.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import StatsGameItem from "@/components/routes/stats/panes/stats/items/StatsGameItem.vue";
import SelectionPaneItem from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.vue";
import LeagueFavIndicator from "@/components/common/LeagueFavIndicator.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import {contextStore} from "@/stores/Context.store";
import {leagueStore} from "@/stores/League.store";
import {message} from "ant-design-vue";

export default {
  name: "StatsPane",
  components: {
    AreaFlag, LeagueLogo, LeagueFavIndicator,
    SelectionPaneItem,
    StatsGameItem,
    StatsPaneFilters,
    StatsPaneHeader,
  },

  data() {
    return {
      filter: '',
      filterTimeout: null,

      offsetYHeader: 0,
      offsetYFilter: 0,
      previousScrollPosition: 0
    }
  },

  mounted() {
    statsPaneStore().init()
  },

  methods: {
    _updateOverContext(item, pin = false) {
      statsRouteStore().setOverContext({
        statUID: item.statUID,
        gameUID: item.gameUID,
        leagueUID: item.leagueUID
      }, pin)
    },
    _scrollToTop() {
      // Scroll to top (64px) in 1 second
      document.querySelector('.stats-results').scrollTo({
        top: 250,
        behavior: 'smooth'
      })
      setTimeout(() => {
        document.querySelector('.stats-results').scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 500)
    },
    _onScrollResults() {
      const scrollPosition = document.querySelector('.stats-results').scrollTop
      if (scrollPosition < 42) return  // Permet de ne pas déclencher l'animation si on est en haut de la page (bug iphone : bounce)

      const diff = scrollPosition - this.previousScrollPosition
      // if diff is positive, we are scrolling down
      if (diff > 0) {
        this.offsetYFilter -= diff
        this.offsetYHeader += diff
        this.offsetYFilter = Math.max(-60, this.offsetYFilter)
        this.offsetYHeader = Math.min(70, this.offsetYHeader)
      } else {
        this.offsetYFilter -= diff * 2
        this.offsetYHeader += diff * 2
        this.offsetYFilter = Math.min(0, this.offsetYFilter)
        this.offsetYHeader = Math.max(0, this.offsetYHeader)
      }

      this.previousScrollPosition = scrollPosition
    },
    _game(uid) {
      if (!uid) return null
      return gameStore().get(uid)
    },
    _onFamilyChange(uid, arg) {

      statsRouteStore().setOfferFamilyFixedUID(uid)
      statsRouteStore().setOfferArgument(arg)

      setTimeout(() => {
        statsRouteStore().updateOffers()
      }, 500)
    },

    _setCritereRegroupement(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le critère de regroupement.");
        return;
      }

      preferencesStore().save('STATS', 'CRITERE_REGROUPEMENT', [option])
      statsPaneStore().setDisplayedPage(1)
    },
    _setCritereTri(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      preferencesStore().save('STATS', 'CRITERE_TRI', [option])
      statsPaneStore().setDisplayedPage(1)
    },
    _loadNextPage() {
      if (!this._canLoadMore) return
      statsPaneStore().incrementPage()
    },
  },

  computed: {
    _offsetFilters() {
      if (!this.offsetYFilter) return 0
      return this.offsetYFilter
    },
    _offsetHeader() {
      if (!this.offsetYHeader) return 0
      return this.offsetYHeader
    },
    _canLoadMore() {
      if (this._resultsCount < 20) return false
      return statsPaneStore().getDisplayedPage() * 20 < this._resultsCount
    },
    _resultsCount() {
      if (!this._results) return 0
      return this._results.length
    },
    _results() {
      return statsRouteStore().getResults()
    },
    _groupedResults() {

      // group by leagueUID
      const critereRegroupement = preferencesStore().getFirstValue('STATS', 'CRITERE_REGROUPEMENT');

      let favGamesUIDs = preferencesStore().getValues('GAME', 'FAVORITE_GAME')
      if (!favGamesUIDs) favGamesUIDs = []

      let notebookGamesUIDs = notebookStore().getItems()
      if (!notebookGamesUIDs) notebookGamesUIDs = []

      let results = [...this._results];
      const notebookGamesInResults = results.filter(r => r.gameUID && notebookGamesUIDs.includes(r.gameUID))
      const gavGamesInResults = results.filter(r => r.gameUID && favGamesUIDs.includes(r.gameUID.toString()))
      const otherGamesInResults = results.filter(r => !favGamesUIDs.includes(r.gameUID.toString()) && !notebookGamesUIDs.includes(r.gameUID))

      const completeGroupedBy = otherGamesInResults.reduce((acc, item) => {

        let regroupementKey
        switch (critereRegroupement) {
          case 'DATETIME':
            regroupementKey = dateService.format(item.gameDatetime, 'DD/MM HH:mm')
            break
          case 'NONE':
            regroupementKey = 'NONE'
            break
          case 'LEAGUE':
          default:
            regroupementKey = item.leagueUID
            break
        }

        if (!acc[regroupementKey]) {
          acc[regroupementKey] = []
        }
        acc[regroupementKey].push(item)
        return acc
      }, {})

      let sortedKeys = Object.keys(completeGroupedBy);
      // Sort keys alphabetically
      sortedKeys.sort((a, b) => {
        if (a === 'NOTEBOOK') return -1
        if (b === 'NOTEBOOK') return 1
        if (a === 'FAVORITES') return -1
        if (b === 'FAVORITES') return 1
        if (a === 'NONE') return -1
        if (b === 'NONE') return 1
        return a.localeCompare(b)
      })


      sortedKeys.forEach(key => {

        let area = null
        let label = null
        let leagueUID = null
        switch (critereRegroupement) {
          case 'DATETIME':
            label = key
            break
          case 'NONE':
            label = 'Tous'
            break
          case 'LEAGUE':
          default:
            let league = leagueStore().get(key)
            if (!league) break

            label = league.name
            area = league.area
            leagueUID = league.uid
            break
        }

        completeGroupedBy[key] = {
          area: area,
          leagueUID: leagueUID,
          label: label,
          items: completeGroupedBy[key]
        }
      })

      // tri des items
      const critereTri = preferencesStore().getFirstValue('STATS', 'CRITERE_TRI')
      let favGames = preferencesStore().getValues('GAME', 'FAVORITE_GAME')
      if (!favGames) favGames = []

      sortedKeys.forEach(key => {
        if (!critereTri || critereTri === 'NOTE') {
          completeGroupedBy[key].items.sort(function (a, b) {

            // On tri sur le fait que le match soit en favoris
            const isFavA = favGames.includes(a.gameUID && a.gameUID.toString())
            const isFavB = favGames.includes(b.gameUID && b.gameUID.toString())

            if (isFavA && !isFavB) return -1
            if (!isFavA && isFavB) return 1

            let onNote = !b.normalizedMatchingScore || !a.normalizedMatchingScore ? null : b.normalizedMatchingScore - a.normalizedMatchingScore;
            if (onNote === 0) {  // Si les notes sont égales, on trie sur la date
              return dateService.compare(a.gameDatetime, b.gameDatetime);
            }

            return onNote;
          })
        }

        if (critereTri === 'DATETIME') {
          completeGroupedBy[key].items.sort(function (a, b) {

            // On tri sur le fait que le match soit en favoris
            const isFavA = favGames.includes(a.gameUID.toString())
            const isFavB = favGames.includes(b.gameUID.toString())

            if (isFavA && !isFavB) return -1
            if (!isFavA && isFavB) return 1

            return dateService.compare(a.gameDatetime, b.gameDatetime);
          })
        }
      })

      // add fav games group
      if (notebookGamesInResults.length > 0) {
        completeGroupedBy['NOTEBOOK'] = {
          area: null,
          leagueUID: null,
          label: 'Notebook',
          items: notebookGamesInResults
        }
      }

      // add fav games group
      if (gavGamesInResults.length > 0) {
        completeGroupedBy['FAVORITES'] = {
          area: null,
          leagueUID: null,
          label: 'Favoris',
          items: gavGamesInResults
        }
      }

      // On ne va garder que this.displayedPage * 20 resultats
      let canBeAddedCount = statsPaneStore().getDisplayedPage() * 20
      const displayedGroupedBy = {}
      sortedKeys.forEach(key => {
        if (canBeAddedCount <= 0) return
        const itemsToDisplay = completeGroupedBy[key].items.slice(0, canBeAddedCount)
        displayedGroupedBy[key] = {
          area: completeGroupedBy[key].area,
          leagueUID: completeGroupedBy[key].leagueUID,
          label: completeGroupedBy[key].label,
          items: itemsToDisplay
        }
        canBeAddedCount -= itemsToDisplay.length
      })

      return displayedGroupedBy
    },
    _orderedGroupementKeys() {
      const critereRegroupement = preferencesStore().getFirstValue('STATS', 'CRITERE_REGROUPEMENT');
      if (!critereRegroupement || critereRegroupement === 'LEAGUE') {
        return ['NOTEBOOK', 'FAVORITES'].concat(this._orderedLeaguesUIDs)
      }
      if (critereRegroupement === 'DATETIME') {
        return ['NOTEBOOK', 'FAVORITES'].concat(Object.keys(this._groupedResults).sort())
      }

      if (critereRegroupement === 'NONE') return ['NOTEBOOK', 'FAVORITES', 'NONE']

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return Object.keys(this._groupedResults)
    },

    _notEmptyOrderedGroupementKeys() {
      return this._orderedGroupementKeys.filter(k => this._groupedResults[k] && this._groupedResults[k].items.length > 0)
    },

    _orderedLeaguesUIDs() {
      // On récupère les ligues favorites
      const favoriteLeagues = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!favoriteLeagues || !favoriteLeagues.length) return Object.keys(this._groupedResults)
      const favoriteLeaguesUIDs = favoriteLeagues.map(l => Number(l))

      const favUIDs = []
      const otherUIDs = []
      Object.keys(this._groupedResults).forEach(leagueUID => {
        if (favoriteLeaguesUIDs.includes(Number(leagueUID))) {
          favUIDs.push(leagueUID)
        } else {
          otherUIDs.push(leagueUID)
        }
      })

      // On place les ligues favorites en premier
      return favUIDs.concat(otherUIDs)
    },

    _sport() {
      return contextStore().getSport
    },

    _sportOfferFamilies() {
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(this._sport);
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },

    _sportOfferFamilyChoices() {
      const results = []
      if (!this._sportOfferFamilies) {
        return results
      }

      this._sportOfferFamilies.forEach(f => {

        // On liste les arguments possibles
        let args = []
        f.choices.forEach(c => {
          if (null !== f.arguments && f.arguments[this._sport]) {
            f.arguments[this._sport].forEach(a => {
              args.push(a)
            })
          }
        })
        // remove duplicates
        args = args.filter((v, i, a) => a.indexOf(v) === i);
        // remove null
        args = args.filter(a => a !== null)
        if (args.length === 0) {
          args = null
        }

        results.push({
          label: offerStore().getOfferFamilyDefinitionLabel(f.code),
          value: f.fixedUID,
          args: args
        })
      })
      return results
    },

    _offerTypeLabel() {
      let familyFixedUID = statsRouteStore().getOfferFamilyFixedUID()
      if (!familyFixedUID) {
        familyFixedUID = preferencesStore().getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_' + contextStore().getSport)
        if (familyFixedUID) {
          const favArg = preferencesStore().getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_ARG_' + contextStore().getSport)
          statsRouteStore().setOfferArgument(favArg)
        }
      }
      if (!familyFixedUID) {
        let firstValue = this._sportOfferFamilyChoices.length > 0 ? this._sportOfferFamilyChoices[0] : null;
        if (firstValue) {
          statsRouteStore().setOfferFamilyFixedUID(firstValue.value)
          statsRouteStore().setOfferArgument(firstValue.args ? firstValue.args[0] : null)
          familyFixedUID = firstValue.value
        }
      }
      if (!familyFixedUID) {
        return 'Type offre'
      }
      if (!this._sportOfferFamilies) {
        return 'Type offre'
      }

      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return 'Type offre'
      }

      let arg = statsRouteStore().getOfferArgument()
      return offerStore().getOfferFamilyDefinitionLabel(family.code) + (arg ? ' ' + arg : '')
    }
  }
}
</script>
<style lang="less">
@import "@/assets/styles/variables.less";


#stats-pane-wrapper {
  background: rgb(94, 53, 43);
  background: linear-gradient(122deg, rgba(94, 53, 43, 1) 0%, rgba(94, 53, 43, 0.95) 3%, rgba(37, 37, 42, 0.85) 21%, rgba(37, 37, 42, 0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: @selection-pane-width !important;
  min-width: @selection-pane-width !important;
  margin-top: @padding-small;
  margin-left: @padding-small;
  overflow: hidden;
  border-radius: @radius-regular;

  .stats-results {
    padding-top: 90px;
    height: calc(100dvh - 40px);
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 60px;
  }
}

.mobile {
  #stats-pane-wrapper {
    display: none;
  }
}

</style>