<script>

import {sportStore} from "@/stores/Sport.store";
import {gameStore} from "@/stores/Game.store";
import {offerStore} from "@/stores/Offer.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import GameDate from "@/components/common/GameDate.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";

export default {
  name: "PaperTicketBet",
  components: {AreaFlag, GameDate, LeagueName, TeamName, TeamLogo},
  props: {
    bet: {
      type: Object,
      required: true
    }
  },
  computed: {
    _sportIcon() {
      const sport = sportStore().get(this.bet.sport)
      if (!sport) {
        console.warn('Unknown sport', this.bet.sport)
        return 'fa-solid fa-question'
      }
      return sport.icon
    },

    _betGameUID() {
      if (!this._betGame) return null
      return this._betGame.uid
    },

    _betGame() {
      if (!this.bet.gameUID) return null
      return gameStore().get(this.bet.gameUID)
    },

    _betGameLeagueUID() {
      if (!this._betGame) return null
      return this._betGame.leagueUID
    },

    _getGameHomeTeamUID() {
      return gameStore().getHomeTeamUID(this._betGame)
    },
    _getGameArea() {
      if (!this._betGame) return null
      return this._betGame.area
    },
    _getGameAwayTeamUID() {
      return gameStore().getAwayTeamUID(this._betGame)
    },
    _betLabel() {
      if (!this.bet) {
        return '...'
      }
      if (this.bet.label) {
        return this.bet.label
      }
      const offerFamilyChoice = offerStore().getOfferFamilyChoiceBySportAndFixedUID(this.bet.sport, this.bet.offerFamilyChoiceFixedUID)
      return offerStore().getOfferFamilyChoiceLabel(offerFamilyChoice, this.bet.offerArgument, false)
    },
    _betOdd() {
      if(!this.bet) return '...'
      if(!this.bet.odd) return '...'
      return this.bet.odd.toFixed(2)
    }
  },
  methods: {}
}
</script>

<template>
  <tr class="paper-ticket-bet">
    <td colspan="2">
      <table class="sb">
        <tbody>
        <tr>
          <td class="text-left">
            <font-awesome-icon :icon="_sportIcon" size="xl" class="mr-1 info"/>
            <league-name :uid="_betGameLeagueUID"/>
            ({{ _getGameArea }})
            <game-date :uid="_betGameUID" class="ml-1"/>
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="2">
            <team-name :uid="_getGameHomeTeamUID" class="team-home"/>
            <span> - </span>
            <team-name :uid="_getGameAwayTeamUID" class="team-away"/>
          </td>
        </tr>
        <tr>
          <td class="text-left">
            {{ _betLabel }}
          </td>
          <td class="text-right">
            {{ _betOdd }}
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";
.paper-ticket-bet{
  background-color: #fff !important;
  color: #000 !important;
  border: none !important;
  table{
    background-color: #fff !important;
    color: #000 !important;
    border: none !important;
  }
  tr, td, th{
    background-color: #fff !important;
    color: #000 !important;
    border: none !important;
    padding: 2px 0 2px 0 !important;

    .team-name, .league-name {
      color: #000 !important;
      padding: 0;
    }


  }
  .sb{
    border-bottom: 1px solid #bbb !important;
  }
}
</style>
