<script>

import {modalStore} from "@/stores/Modal.store";
import {notificationStore} from "@/stores/Notification.store";
import NotificationInstance from "@/components/common/modal/notifications/instance/NotificationInstance.vue";
import {contextStore} from "@/stores/Context.store";
import NotificationInstanceItem from "@/components/common/modal/notifications/NotificationInstanceItem.vue";

export default {
  name: "NotificationsModal",
  components: {NotificationInstanceItem, NotificationInstance},
  data() {
    return {
      selectedInstance: null
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'notificationsModal';
    },
    _instances() {
      return notificationStore().getItems;
    },
    _visibleInstances() {
      const visibleInstances = this._instances.filter(i => !i.archivedDate);
      // reverse order sur creationDate desc
      return visibleInstances.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _onSelectedInstance(instance) {
      this.selectedInstance = null
      setTimeout(() => {
        this.selectedInstance = instance
      }, 50)
    },
    _isSelected(instance) {
      return this.selectedInstance && this.selectedInstance.uid === instance.uid
    },
    _archive() {
      if (!this.selectedInstance) return
      notificationStore().archive(this.selectedInstance.uid)
      this.selectedInstance = null

      // On selectionne la premiere instance
      setTimeout(() => {
        if(this._visibleInstances.length === 0) return
        this.selectedInstance = this._visibleInstances[0]
      }, 50)
    }
  },
  watch: {
    _isVisible(val) {
      if (val) {
        // On va update le nombre de notification non lues
        notificationStore().fetch()
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="700" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Notifications
    </header>

    <div class="content flex">
      <a-alert v-if="_visibleInstances.length === 0" message="Aucune notification" type="info" show-icon class="m-1"/>
      <div class="items-wrapper flex-grow">
        <div class="items">
          <notification-instance-item v-for="i in _visibleInstances" :key="i.uid" :instance="i" class="m-1"  @archive="_archive" @selected="_onSelectedInstance" :selected="_isSelected(i)"/>
        </div>
      </div>
      <div class="instance-detail  flex-grow" v-if="!_isMobile">
        <notification-instance v-if="selectedInstance" :instance="selectedInstance" class="m-1"/>
        <div v-else>
          <a-alert message="Sélectionne une notification pour voir le détail" type="info" show-icon class="m-1"/>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <button class="" @click="_onCancel">Fermer</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.content{
  overflow: hidden;

  .items-wrapper{
    overflow-y: auto;
  }
  .items {
    border-right: 1px solid @stroke-color;
  }

  .instance-detail {
    width: 400px;
  }
}


</style>