// stores/counter.js
import { defineStore } from 'pinia'
import axiosService from "@/services/axios.service";

export const notificationStore = defineStore('notificationStore', {
  state: () => {
    return {
      unreadCount: 0,
      items: [],

      updateUnreadCountTimeout: null
    }
  },
  actions: {
    // Permet de récupérer un match par son UID
    planUpdateUnreadCount() {
      clearTimeout(this.updateUnreadCountTimeout)
      this.updateUnreadCountTimeout = setTimeout(() => {
        notificationStore().updateUnreadCount()
      }, 500)
    },

    updateUnreadCount() {
        axiosService.get('/not/unread/count').then(response => {
          this.unreadCount = response.data
        })
    },

    fetch() {
      axiosService.get('/not').then(response => {
        this.items = response.data.results
      })
    },

    markAsRead(uid, callback) {
      axiosService.put('/not/' + uid + '/mark-as-read').then(response => {
        notificationStore().planUpdateUnreadCount()
        if(callback) {
          callback()
        }
      })
    },

    archive(uid) {

      // On met une date d'archivage dans l'item
      const item = this.items.find(item => item.uid === uid)
      item.archivedDate = new Date()

      axiosService.delete('/not/' + uid + '/archive')
    }
  },
  getters: {
    getUnreadCount: (state) => {
      return state.unreadCount
    },
    getItems: (state) => {
      return state.items
    }
  }
})