<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import {statStore} from "@/stores/Statistic.store";
import {notebookStore} from "@/stores/NotebookStore";
import Statistic from "@/components/common/Statistic.vue";
import {teamStore} from "@/stores/Team.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {gameStore} from "@/stores/Game.store";
import {modalStore} from "@/stores/Modal.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import dateService from "@/services/date.service";

export default {
  name: "FrequencyStatsWidgetRow",
  components: {
    Statistic,
    IndicateurCircle,
    OfferChoiceButton,
    OfferBookmakerValue,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    widgetUid: {
      type: Number,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    even: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      againstStatsOpened: false
    }
  },

  computed: {
    _overContext() {
      return statsRouteStore().getOverContext
    },
    _rowClasses() {
      if (!this._overContext) return {
        'frequency-stats-widget-row': true,
        'anonymous': !this.row.teamUID,
        'even': this.even
      }
      return {
        'frequency-stats-widget-row': true,
        'anonymous': !this.row.teamUID,
        'even': this.even,
        'in-notebook': notebookStore().isIn(this.row.gameUID),
        'over-context-team': this._overContext.teamUID && this._overContext.teamUID === this.row.teamUID,
        'over-context-game': this._overContext.gameUID && this._overContext.gameUID === this.row.gameUID,
        'over-context-league': this._overContext.leagueUID && this._overContext.leagueUID === this.row.leagueUID
      }
    },
    _period() {
      let result = perspectiveStore().getParameterValue(this.widgetUid, 'period')
      if (result) return result

      let firstValue = preferencesStore().getFirstValue('FREQUENCY_STATS_ROW_FILTERS', 'PERIOD');
      if (!firstValue) {
        return 'CURRENT_SEASON'
      }
      return firstValue
    },
    _leagueUID() {
      if (!this.row) return null
      return this.row.leagueUID
    },
    _leagueArjel() {
      const league = leagueStore().get(this._leagueUID)
      if (!league) return false
      return league.isArjel === true
    },
    _teamUID() {
      if (!this.row) return null
      return this.row.teamUID
    },
    _againstTeamUID() {
      if (!this.row) return null
      return this.row.againstTeamUID
    },
    _location() {
      if (!this.row) return null
      return this.row.location
    },

    _locationLabel() {
      if (!this._location) return null
      if (this._location === 'HOME') return 'Reçoit'
      if (this._location === 'AWAY') return 'Chez'
      return 'Inconnu'
    },

    _percentage() {
      if (!this.row) return null
      if (!this.row.percentage) return null
      return this.row.percentage
    },
    _formattedPercentage() {
      if (!this._percentage) return '-'
      return (this._percentage * 100.0).toFixed(1) + '%'
    },
    _absolute() {
      if (!this.row) return '-'
      if (!this.row.absolute) return '-'
      return this.row.absolute
    },
    _gameCount() {
      if (!this._absolute) return '-'
      if (!this._percentage) return '-'
      return Math.round(this._absolute / this._percentage)
    },
    _statFixedUID() {
      if (!this.row) return null
      return this.row.statFixedUID
    },
    _offerChoiceFixedUIDs() {
      if (!this._statFixedUID) return null
      return statStore().getOfferChoiceFixedUIDBySdefFixedUID(this._statFixedUID);
    },
    _offerChoiceFixedUID() {
      if (!this._offerChoiceFixedUIDs) return null
      if (!this._location) return null
      return this._offerChoiceFixedUIDs[this._location]
    },
    _obv() {
      if (!this.row) return null
      if (!this.row.offerBookmakerValues) return null
      if (!this._offerChoiceFixedUID) return null

      let matches = this.row.offerBookmakerValues.filter(obv => obv.offerFamilyChoiceFixedUID === this._offerChoiceFixedUID)

      const bookmakers = bookmakerStore().getAll

      // On supprime les matchs dont on ne trouve pas le bookmaker
      matches = matches.filter(match => bookmakers.find(b => b.uid === match.bookmakerUID))

      // chaque bookmaker a un attribut 'priority' qui permet de trier les bookmakers (1 = le plus prioritaire)
      // On veut la cote du bookmaker le plus prioritaire
      matches = matches.sort((a, b) => {
        const aBookmaker = bookmakers.find(book => book.uid === a.bookmakerUID)
        const bBookmaker = bookmakers.find(book => book.uid === b.bookmakerUID)
        if (!aBookmaker) {
          console.warn('Bookmaker not found', a.bookmakerUID, 'in', bookmakers)
          return 0
        }
        if (!bBookmaker) {
          console.warn('Bookmaker not found', b.bookmakerUID, 'in', bookmakers)
          return 0
        }
        return aBookmaker.priority - bBookmaker.priority
      })
      return matches[0]
    },
    _odd() {
      if (!this._obv) return null
      return this._obv.value
    },
    _obvBookmakerUID() {
      if (!this._obv) return null
      return this._obv.bookmakerUID
    },
    _bookmakerLogoSrc() {
      if (!this._obvBookmakerUID) return null
      const bookmaker = bookmakerStore().get(this._obvBookmakerUID)
      if (!bookmaker) return null
      const lowerCaseBookName = bookmaker.name.toLowerCase().replaceAll(' ', '')
      return '/images/bookmakers/' + lowerCaseBookName + '.png'
    },
    _formattedOdd() {
      if (!this._odd) return '-.--'
      return this._odd.toFixed(2)
    },
    _oddProbability() {
      if (!this._obv) return null
      return 1.0 / this._obv.value
    },
    _minimalValueOdd() {
      return (1 / this._percentage).toFixed(2)
    },
    _probabilityDifference() {
      if (!this._oddProbability) return null
      if (!this._percentage) return null
      return this._percentage - this._oddProbability
    },
    _formattedProbabilityDifference() {
      if (!this._probabilityDifference) return '-'
      if (this._probabilityDifference === 0) return '∞'
      return (this._probabilityDifference * 100.0).toFixed(1) + '%'
    },
    _isOddValue() {
      if (!this._probabilityDifference) return null
      return this._probabilityDifference > 0
    },
    _againstStats() {
      if (!this.row) return []
      if (!this.row.againstStats) return []
      return this.row.againstStats
    },
    _againstTeam() {
      if (!this._againstTeamUID) return null
      return teamStore().get(this._againstTeamUID)
    },
    _againstTeamName() {
      if (!this._againstTeam) return null
      return this._againstTeam.name
    },
    _gameUID() {
      if (!this.row) return null
      return this.row.gameUID
    },
    _game() {
      if (!this._gameUID) return null
      return gameStore().get(this._gameUID)
    },

    _gameDate() {
      if (!this._game) return null
      return this._game.date
    },

    _dateLabel(){
      if(!this._gameDate) return ''
      // Si le match est aujourd'hui, on affiche l'heure
      let now = Date.now();
      if(dateService.isBefore(this._gameDate, dateService.getEndOf(now))){
        return 'à ' + dateService.formatHHMM(this._gameDate)
      }
      // Si le match est demain, on affiche "Demain"
      if(dateService.isBefore(this._gameDate, dateService.getEndOf(dateService.addDays(now, 1)))){
        return 'demain'
      }

      // Sinon on affiche la date
      return 'le ' + dateService.formatDDMM(this._gameDate)
    },

    _teamIsFav() {
      if (!this._game) return false
      if (!this._location) return false
      switch (this._location) {
        case 'HOME':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart < -0.199
        case 'AWAY':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart > 0.199
        default:
          return false
      }
    },
    _teamIsHyperFav() {
      if (!this._game) return false
      if (!this._location) return false
      switch (this._location) {
        case 'HOME':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart < -0.399
        case 'AWAY':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart > 0.399
        default:
          return false
      }
    },

    _againstIsFav() {
      if (!this._game) return false
      if (!this._location) return false
      switch (this._location) {
        case 'HOME':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart > 0.199
        case 'AWAY':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart < -0.199
        default:
          return false
      }
    },
    _againstIsHyperFav() {
      if (!this._game) return false
      if (!this._location) return false
      switch (this._location) {
        case 'HOME':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart > 0.399
        case 'AWAY':
          if (!this._game.odd1x2Ecart) return false
          return this._game.odd1x2Ecart < -0.399
        default:
          return false
      }
    }
  },
  methods: {
    _toggleAgainstStats() {
      this.againstStatsOpened = !this.againstStatsOpened
    },
    _setPeriod(period) {
      preferencesStore().save('FREQUENCY_STATS_ROW_FILTERS', 'PERIOD', [period])
    },
    _openStatModal(event) {
      event.stopPropagation()
      modalStore().openModal({
        uid: 'statisticModal', payload: {
          'uid': this.row.statUID,
          'uidBase': this.row.statBaseUID,
          'uidCombined': this.row.statCombinedUID,
          'period': this._period
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div :class="_rowClasses">
      <table @click.stop="_toggleAgainstStats">
        <tbody>
        <tr>
          <td rowspan="3" style="text-align: center; width: 34px">
            <team-logo :uid="_teamUID"/>
          </td>
          <td>
            <team-name :uid="_teamUID" style="padding: 0 !important; line-height: 20px"/>
            <img v-if="_teamIsFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" alt="favori"/>
            <img v-if="_teamIsHyperFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" alt="hyper favori"/>
          </td>
          <td class="relative-value">
            <button class="tiny right-label filled" style="font-size: 9pt !important; padding: 0 4px !important;"
                    v-if="_againstStats.length > 0">+{{ _againstStats.length }}
            </button>
            <button class="tiny right-label" style="font-size: 9pt !important; padding: 0 4px !important;"
                    @click.stop="_openStatModal">({{ _absolute }})
            </button>
            <div class="right-value">{{ _formattedPercentage }}</div>
          </td>
        </tr>
        <tr>
          <td class="context-data">
            <span>{{ _locationLabel }}</span>
            <team-name :uid="_againstTeamUID" :anonymous="!_againstTeamUID"/>
            <img v-if="_againstIsFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" alt="favori"/>
            <img v-if="_againstIsHyperFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" alt="hyper favori"/>
            <span>{{ _dateLabel }}</span>

          </td>
          <td class="odd-value">
            <font-awesome-icon icon="fa-solid fa-bullseye" class="right-label px-1" style="font-size: 8pt"/>
            <div class="right-value">{{ _minimalValueOdd }}</div>
          </td>
        </tr>
        <tr>
          <td class="context-data">
            <span style="line-height: 20px">Arrivé {{ _absolute }}x/{{ _gameCount }} matchs</span>
          </td>
          <td class="odd-value">
          <span v-if="_probabilityDifference && _isOddValue"
                class="value-percentage green">+{{ _formattedProbabilityDifference }}</span>
            <span v-if="_probabilityDifference && !_isOddValue"
                  class="value-percentage red">{{ _formattedProbabilityDifference }}</span>
            <img :src="_bookmakerLogoSrc" style="height: 12px; width: 12px; display: inline-block" alt="" v-if="_bookmakerLogoSrc"/>
            {{ _formattedOdd }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="againstStatsOpened">

      <fieldset class="fieldset-bloc mt-1">
        <legend>Statistique {{ _againstTeamName }}</legend>
        <div class="mb-2">
          <button class="tiny info ml-1 mt-1" :class="{'accent' : _period === 'CURRENT_SEASON'}" @click="_setPeriod('CURRENT_SEASON')">S</button>
          <button class="tiny info ml-1 mt-1" :class="{'accent' : _period === 'LAST_5_GAMES'}" @click="_setPeriod('LAST_5_GAMES')">L5</button>
          <button class="tiny info ml-1 mt-1 mr-1" :class="{'accent' : _period === 'LAST_10_GAMES'}" @click="_setPeriod('LAST_10_GAMES')">L10</button>
          <button class="tiny info ml-1 mt-1 mr-1" :class="{'accent' : _period === 'ALL_TIME'}" @click="_setPeriod('ALL_TIME')">All</button>
        </div>
        <statistic :period="_period" :stat="s" v-for="s in _againstStats"/>
      </fieldset>
      <hr/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.frequency-stats-widget-row {
  cursor: pointer;
  display: flex;
  border-top: 1px solid @stroke-color;
  border-left: 8px solid transparent;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid @stroke-color;
  }

  &.even {
    background-color: @background-color-dark;
  }

  tr, td {
    background: transparent !important;
    text-align: left;
    border: none !important;
    padding: 0;
  }

  &.in-notebook {
    border-left: 8px solid @selected-color;
  }

  &.over-context-league {
    background: fade(@hover-color, 50%);
  }

  &.over-context-game {
    background: fade(@selected-color, 40%);
  }

  &.over-context-team {
    background: fade(@primary-color, 15%);
  }

  &:hover {
    background: fade(@primary-color, 15%);
  }

  &.anonymous {
    opacity: 0.25;
  }

  .context-data {
    font-size: 8pt;
    color: @text-color-dark;
  }

  .relative-value {
    min-width: 104px;
    text-align: right;
    padding-right: @padding-small;
  }

  .right-label {
    color: @text-color-dark !important;
  }

  .right-value {
    display: inline-block;
    max-width: 44px;
  }

  .odd-value {
    min-width: 104px;
    text-align: right;
    padding: 0 @padding-small;
  }

  .value-percentage {
    font-size: 10pt;
    margin-right: @padding-regular;

    &.green {
      color: @positive-color;
    }

    &.red {
      color: @negative-color;
    }

  }
}
</style>