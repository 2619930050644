<script>
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "TeamMarkovWidget",
  components: {
    WidgetLoadingContent,
    WidgetTeamParamButton,
    WidgetLeagueParamButton,
    StatisticValueButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      data: null
    }
  },
  mounted() {

    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _rows() {
      if (!this.data) return []
      const results = []
      Object.keys(this.data.results).forEach((key) => {
        let splittedKey = key.split("_AFTER_");
        results.push(splittedKey[0])
      })

      // remove duplicates
      return results.filter((value, index) => results.indexOf(value) === index)
    },
    _cols() {
      if (!this.data) return []
      const results = []
      Object.keys(this.data.results).forEach((key) => {
        let splittedKey = key.split("_AFTER_");
        results.push(splittedKey[1])
      })

      // remove duplicates
      return results.filter((value, index) => results.indexOf(value) === index)
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {}
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        teamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        leagueUID: perspectiveStore().getParameterLeagueValue(this.item.uid),
        period: 'CURRENT_SEASON',
        sport: perspectiveStore().getCurrentSport()
      }
      axiosService.put('/wid/team-markov-law', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _sum(suffix) {
      if (!this.data) return 0
      let sum = 0
      Object.keys(this.data.results).forEach((key) => {
        if (key.endsWith(suffix)) {
          sum += this.data.results[key]
        }
      })
      return sum
    },
    _calc(row, col) {
      if (!this.data) return 0
      const typeSum = this._sum('_AFTER_' + col)
      if (typeSum === 0) return '-'
      let key = row + '_AFTER_' + col
      const value = this.data.results[key]
      const result = (value / typeSum * 100.0).toFixed(0)
      if (result === '0') return '-'
      return result + '%'
    },
    _tooltip(row, col) {
      switch (row + '_AFTER_' + col) {
        case 'WIN_AFTER_WIN':
          return 'Pourcentage de victoire après une victoire'
        case 'WIN_AFTER_DRAW':
          return 'Pourcentage de victoire après un match nul'
        case 'WIN_AFTER_LOSS':
          return 'Pourcentage de victoire après une défaite'
        case 'DRAW_AFTER_WIN':
          return 'Pourcentage de match nul après une victoire'
        case 'DRAW_AFTER_DRAW':
          return 'Pourcentage de match nul après un match nul'
        case 'DRAW_AFTER_LOSS':
          return 'Pourcentage de match nul après une défaite'
        case 'LOSS_AFTER_WIN':
          return 'Pourcentage de défaite après une victoire'
        case 'LOSS_AFTER_DRAW':
          return 'Pourcentage de défaite après un match nul'
        case 'LOSS_AFTER_LOSS':
          return 'Pourcentage de défaite après une défaite'
      }
      return '?' + row + '_' + col
    },
    _firstLetter(str) {
      switch (str) {
        case 'WIN':
          return 'V'
        case 'DRAW':
          return 'N'
        case 'LOSS':
          return 'D'
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Loi de Markov
      <div class="widget-header-params flex" v-if="item">
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>

      <table>
        <tbody>
        <tr>
          <th></th>
          <th v-for="col in _cols">
            {{ _firstLetter(col) }}
          </th>
        </tr>
        <tr v-for="row in _rows">
          <td>{{ _firstLetter(row) }}</td>
          <td v-for="col in _cols">
            <a-tooltip :title="_tooltip(col, row)">
              {{ _calc(col, row) }}
            </a-tooltip>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  color: @text-color;

  table {
    width: 100%;

    th, td {
      border-top: 1px solid @stroke-color;
      padding: 3px @padding-regular;
      text-align: center;
    }

    tr {
      &:nth-child(2n) {
        background-color: @background-color-light;
      }
    }
  }
}

</style>