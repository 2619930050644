<script>

import StatsDataPerspective from "@/components/routes/stats/panes/data/perspective/StatsDataPerspective.vue";
import StatsDataHeader from "@/components/routes/stats/panes/data/header/StatsDataHeader.vue";
import StatsPaneHeader from "@/components/routes/stats/panes/stats/header/StatsPaneHeader.vue";
import {contextStore} from "@/stores/Context.store";
import StatsPaneFilters from "@/components/routes/stats/panes/stats/filters/StatsPane.Filters.vue";
import {modalStore} from "@/stores/Modal.store";
import {message} from "ant-design-vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";

export default {
  name: "StatsDataPane",
  components: {
    StatsPaneFilters,
    StatsPaneHeader,
    StatsDataHeader,
    StatsDataPerspective
  },

  data() {
    return {
      offsetYHeader: 0,
      offsetYFilter: 0,
      previousScrollPosition: 0
    }
  },

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _offsetHeader() {
      if (!this.offsetYHeader) return 0
      return this.offsetYHeader
    },
    _offsetFilters() {
      if (!this.offsetYFilter) return 0
      return this.offsetYFilter
    },
    _isPerspectiveEmpty() {
      const perspectiveUID = statsRouteStore().getSelectedPerspectiveUID()
      if (!perspectiveUID) return true
      const widgets = perspectiveStore().getWidgetsByPerspectiveUID(perspectiveUID)
      if(!widgets) return true
      return widgets.length === 0
    }
  },

  methods: {

    _onScrollPerspective() {

      if (!this._isMobile) return

      const scrollPosition = document.querySelector('#stats-data-content-wrapper').scrollTop
      if (scrollPosition < 42) return  // Permet de ne pas déclencher l'animation si on est en haut de la page (bug iphone : bounce)

      const diff = scrollPosition - this.previousScrollPosition
      // if diff is positive, we are scrolling down
      if (diff > 0) {
        this.offsetYFilter -= diff
        this.offsetYHeader += diff
        this.offsetYFilter = Math.max(-60, this.offsetYFilter)
        this.offsetYHeader = Math.min(70, this.offsetYHeader)
      } else {
        this.offsetYFilter -= diff * 2
        this.offsetYHeader += diff * 2
        this.offsetYFilter = Math.min(0, this.offsetYFilter)
        this.offsetYHeader = Math.max(0, this.offsetYHeader)
      }

      this.previousScrollPosition = scrollPosition
    },
    _openStatSelectorModal() {
      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          statFixedUID: null,
          sport: contextStore().getSport,
          callback: (category, item) => {

            if (!contextStore().isAuthenticated()) {
              message.warn("Tu dois être connecté pour ajouter une data")
              return
            }

            if (!statsRouteStore().getSelectedPerspectiveUID()) {
              message.warn("Tu dois sélectionner une perspective pour ajouter une data")
              return
            }

            const widgets = perspectiveStore().getWidgetsByPerspectiveUID(statsRouteStore().getSelectedPerspectiveUID())
            switch (contextStore().getLicenseType()) {
              case 'NONE':  // si pas de licence, limiter le nombre de widgets à 5
                if (widgets.length >= 5) {
                  message.warn("Sans licence, tu ne peux pas ajouter plus de 5 widgets")
                  return
                }
                break
              case 'BASIC':  // si pas de licence ULTIMATE, limiter le nombre de widgets à 8
                if (widgets.length >= 8) {
                  message.warn("Sans licence ULTIMATE, tu ne peux pas ajouter plus de 8 widgets.")
                  return
                }
                break
              case 'FULL': // si licence ULTIMATE, on limite a 16 widgets
                if (widgets.length >= 16) {
                  message.warn("Il n'est pas possible d'ajouter plus de 16 widgets par perspective.")
                  return
                }
                break
            }

            let widgetType = null
            switch (category) {
              case 'stat':
                switch (item.type) {
                  case 'FREQUENCY':
                    widgetType = 'FrequencyStatsWidget'
                    break
                  case 'RAW':
                    widgetType = 'RawStatsWidget'
                    break
                  case 'SERIE':
                    widgetType = 'SerieStatsWidget'
                    break
                }
                break
              case 'selection':
                switch (item.type) {
                  case 'GAME':
                    widgetType = 'GameSelectionStatsWidget'
                    break
                  case 'PLAYER':
                    widgetType = 'PlayerSelectionStatsWidget'
                    break
                }
                break
            }


            let selectedPerspectiveUID = statsRouteStore().getSelectedPerspectiveUID();
            perspectiveStore().addWidget(selectedPerspectiveUID, widgetType, () => {
              // On récupère le widget ajouté
              const perspectiveWidgets = perspectiveStore().getWidgetsByPerspectiveUID(selectedPerspectiveUID)
              const widget = perspectiveWidgets[perspectiveWidgets.length - 1]

              if (!widget) {
                message.error("Erreur lors de l'ajout du widget")
                return
              }

              // On modifie la configuration du widget pour spécifié la statistique utilisée.
              let parameterDefinition = {
                'key': category === 'stat' ? 'stat_fixed_uid' : 'selection_uid',
                'multiple': false,
              }
              let key = category === 'stat' ? item.fixedUID : item.uid
              perspectiveStore().setWidgetParameterValue(widget.uid, parameterDefinition, {'key': key})
              perspectiveStore().saveWidgetParameters(widget.uid)
            })

          }
        }

      });

    }
  }
}
</script>

<template>
  <div id="stats-data-pane-wrapper">
    <div id="stats-data-pane">
      <stats-pane-header :offset-y="_offsetHeader" v-if="_isMobile"/>
      <stats-data-perspective :on-scroll="_onScrollPerspective"/>

    </div>

    <div class="controls-wrapper">
      <button class="floating accent large" @click="_openStatSelectorModal">Ajouter une data</button>
    </div>
    <stats-pane-filters v-if="_isMobile && !_isPerspectiveEmpty" :offset-y="_offsetFilters"/>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

#stats-data-pane-wrapper {
  overflow: hidden;

  #stats-data-pane {
    margin: @padding-small @padding-small 0 @padding-small;
    border-radius: @radius-regular;
    background: rgb(34, 34, 34);
    background: linear-gradient(215deg, rgba(255, 255, 255, 0.05) 0%, rgba(44, 44, 46, 0.80) 30%, rgba(44, 44, 46, 0.80) 40%, rgba(34, 34, 34, 0.90) 100%);
    height: calc(100dvh - @toolbar-regular);;
  }

  .controls-wrapper {
    position: fixed;
    bottom: 0;
    left: calc(@selection-pane-width + @padding-regular * 2);
    padding: @padding-small @padding-small @padding-regular @padding-small;
    border-radius: @radius-large @radius-large 0 0;;
  }
}


.mobile {
  .controls-wrapper {
    background: @background-color;
    left: @padding-small !important;
    width: calc(100% - 2 * @padding-small);

    button {
      width: 100%;
    }
  }
}

</style>