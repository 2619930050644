<script>

import GeneratedTicketBet from "@/components/routes/selection/modals/ticket_generator/ticket/PaperTicketBet.vue";

export default {
  name: "PaperTicket",
  components: {GeneratedTicketBet},
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  computed: {
    _odd() {
      if (!this.ticket) return null;
      return (this.ticket.odd).toFixed(2);
    },
    _bets() {
      if (!this.ticket) return null;
      return this.ticket.bets;
    }
  },
  methods: {}
}
</script>

<template>
  <div class="generated-ticket">

    <div class="ticket">
      <table>
        <tbody>
        <tr>
          <td rowspan="2" class="text-center" style="width: 40px; height: 40px">
            <img src="../../../../../../assets/images/logo-header-black.png" alt="DoctoBET" style="height: 40px; opacity: 0.1"/>
          </td>
          <td>
            <div class="text-left pl-2" style="font-weight: bold !important; font-size: 12pt;">
              <strong>DoctoBET</strong>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="text-left pl-2">
              <strong>Soigne ta bankroll avec des stats.</strong>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <table>
        <tbody>
        <tr style="border-bottom: 1px solid black !important;">
          <td class="text-left">
            Pari
          </td>
          <td class="text-right">
            Cote
          </td>
        </tr>
        <generated-ticket-bet v-for="bet in _bets" :bet="bet"/>
        <tr>
          <td class="text-left">
            Côte totale
          </td>
          <td class="text-right" style="font-weight: bold !important;">
            {{ _odd }}
          </td>
        </tr>
        </tbody>
      </table>

      <table>
        <tbody>
        <tr style="border-top : 1px solid black">
          <td style="width: 40px">-----</td>
          <td style="font-weight: bolder !important; font-size: 11pt">Avertissement</td>
          <td style="width: 40px">-----</td>
        </tr>
        <tr>
          <td></td>
          <td>Ce ticket ne vaut pas conseil, DoctoBET ne peut pas être tenu responsable des pertes potentielles.</td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.generated-ticket {
  color: #000 !important;
  border: none !important;
  padding: @padding-regular;

  .ticket {
    padding: @padding-regular;
    background-color: #fff !important;
    table {
      background-color: #fff !important;
      color: #000 !important;
      border: none !important;
      padding: 0 !important;
    }

    tr, td, th {
      background-color: #fff !important;
      color: #000 !important;
      border: none !important;
      padding: @padding-small 0 !important;
    }
  }
}
</style>