<script>
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {bankrollPaneStore} from "@/components/routes/bankroll/panes/bankroll/BankrollPane.store";
import axiosService from "@/services/axios.service";
import LeagueName from "@/components/common/LeagueName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import {leagueStore} from "@/stores/League.store";
import WidgetParamButton from "@/components/common/widgets/parameter/WidgetParamButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";

export default {
  name: "LeagueSuccessRateWidget",
  components: {
    WidgetParamButton,
    AreaFlag,
    LeagueLogo,
    LeagueName,
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      refreshTimeout: null,
      data: {}
    }
  },
  mounted() {

    this._onBankrollUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('bankroll-pane:bankroll-uid:change', (bankrollUID) => {
      this._onBankrollUIDChange()
    })
    window.emitter.on('bankroll-pane:bankroll-filters:change', (bankrollUID) => {
      this._onBankrollUIDChange()
    })

  },
  beforeUnmount() {
    window.emitter.off('bankroll-pane:bankroll-uid:change')
    window.emitter.off('bankroll-pane:bankroll-filters:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _bankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    },
    _sortedCategories() {
      if (!this.data || !this.data.categories) return []
      const sorted = [...this.data.categories]
      let sortByParam = perspectiveStore().getParameterValue(this._itemUID, 'order_by')
      switch (sortByParam) {
        case 'RELATIVE':
          sorted.sort((a, b) => {
            return this._rate(b) - this._rate(a)
          })
          break
        case 'ABSOLUTE':
          sorted.sort((a, b) => {
            return b.winCount - a.winCount
          })
          break
        case 'ROI':
          sorted.sort((a, b) => {
            return this._roi(b) - this._roi(a)
          })
          break
        case 'COUNT':
          sorted.sort((a, b) => {
            return b.ticketCount - a.ticketCount
          })
          break
      }
      return sorted
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onBankrollUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!this._bankrollUID) return;

      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const payload = bankrollPaneStore().buildPayload()
      axiosService.put('/wid/bk/league-success-rate', payload).then(response => {
        this.data = response.data
      })
    },

    _rate(c) {
      if (c.ticketCount === 0)
        return null

      return (c.winCount * 100.0 / c.ticketCount).toFixed(0)
    },

    _roi(c) {
      if (!c.roi)
        return '-'

      return c.roi.toFixed(1)
    },

    _styleROI(c){
      return {
        color: c.roi > 0 ? '#6DFFC2' : '#FF846E'
      }
    },

    _color(c) {
      const rate = this._rate(c)
      if (rate < 40)
        return '#FF846E'
      if (rate < 60)
        return '#FFC86D'

      return '#6DFFC2'
    },

    _leagueUID(c) {
      return c ? c.leagueUID : null
    },
    _areaCode(c) {
      let leagueUID = this._leagueUID(c);
      if(!leagueUID) return null
      const league = leagueStore().get(leagueUID)
      if(!league) return null
      return league.area
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Taux de réussite par ligue
      <div class="widget-header-params flex" v-if="item">
        <widget-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content" v-else>
      <table>
        <tbody>
        <tr>
          <th colspan="2"></th>
          <th class="league text-left">
            Ligue
          </th>
          <th>
            Nb.
          </th>
          <th class="frequence">
            Frq%
          </th>
          <th class="text-right" colspan="2">
            Réussite
          </th>
          <th class="text-right">
            ROI
          </th>
        </tr>
        <tr class="league-row" v-for="c in _sortedCategories" style="color:white">
          <td style="width: 24px">
            <area-flag :area="_areaCode(c)"/>
          </td>
          <td style="width: 24px">
            <league-logo :uid="c.leagueUID"/>
          </td>
          <td class="league">
            <league-name :uid="c.leagueUID" show-area/>
          </td>
          <td class="ticket-count">
            {{ c.ticketCount }}
          </td>
          <td class="frequence">
            {{ (c.frequence * 100.0).toFixed(1) }}
          </td>
          <td class="bar-container">
            <div class="bar" :style="{width: _rate(c) + '%', backgroundColor: _color(c)}" v-if="_rate(c)">
            </div>
          </td>
          <td class="rate">
          <span v-if="_rate(c)">
          {{ _rate(c) }}%
            </span>
          </td>
          <td class="roi" :style="_styleROI(c)">
            <span v-if="_roi(c)">{{ _roi(c) }}%</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  height: calc(314px - 29px);
  overflow-y: scroll;
}

@row-height: 17px;
.league-row {
  padding: 0 4px;


  .rate, .league, .ticket-count {
    line-height: @row-height;
  }

  .ticket-count {
    width: 20px;
    text-align: left;
  }

  .frequence {
    width: 44px;
    text-align: right;
  }

  .rate {
    width: 30px;
    text-align: right;
  }

  .roi {
    width: 64px;
    text-align: right;
  }

  .league {
    width: 140px;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }

  .bar-container {
    margin-top: 2px;
    flex-grow: 1;
    height: calc(@row-height - 4px);

    .bar {
      height: calc(@row-height - 4px);
    }
  }
}
</style>