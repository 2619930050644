<script>
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";
import {modalStore} from "@/stores/Modal.store";
import {notebookStore} from "@/stores/NotebookStore";
import {notificationStore} from "@/stores/Notification.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {message} from "ant-design-vue";

export default {
  name: "UserDropdownMenu",

  data() {
    return {
      prices: null
    }
  },

  mounted() {
    // On va update le nombre de notification non lues
    notificationStore().updateUnreadCount()
  },

  computed: {
    _contextStore() {
      return contextStore()
    },
    _isAuthenticated() {
      return this._contextStore.isAuthenticated()
    },
    _notebookItemsCount() {
      return notebookStore().getItems().length
    },
    _currentRoute() {
      return this.$route.name
    },
    _canAddGameInNoteBookUID() {

      // get current route name
      let gameUID = null
      switch (this._currentRoute) {
        case 'main':
          return gameUID
        case 'stats':
          return gameUID
        case 'bankroll':
          return gameUID
        case 'players':
          return gameUID
        case 'games':
          gameUID = selectionPaneStore().getSelectedGameUID()
          break
        case 'profiler':
          return gameUID
        default:
          return gameUID
      }

      // Si le gameUID est déjà dans le notebook, on retourne null
      if (notebookStore().getItems().indexOf(gameUID) >= 0) return null
      return gameUID
    },
    _notebookIsEmpty() {
      return notebookStore().getItems().length === 0
    },
    _canAddGameInNoteBook() {
      return this._canAddGameInNoteBookUID != null
    },
    _unreadNotificationsCount() {
      let unreadCount = notificationStore().getUnreadCount;
      if (!unreadCount) {
        return 0
      }
      if (unreadCount > 99) {
        return 99
      }
      return unreadCount
    },
  },
  methods: {
    _openUserPreferencesModal() {
      modalStore().openModal({uid: 'userPreferencesModal'})
    },
    _openNotificationsModal() {
      modalStore().openModal({uid: 'notificationsModal'})
    },
    _signWithGoogle() {
      userStore().signInWithGoogle()
    },
    _openCalculatorModal() {
      modalStore().openModal({uid: 'calculatorModal'})
    },
    _openNotebookModal() {
      modalStore().openModal({uid: 'notebookModal'})
    },
    _showSearchModal() {
      modalStore().openModal({
        uid: 'searchModal',
        payload: {
          filter: '',
        }
      })
    },
    _addToNotebook() {
      if (!this._canAddGameInNoteBook) return
      notebookStore().switch(this._canAddGameInNoteBookUID)
      message.info("Match ajouté au notebook.")
    }
  }
}
</script>

<template>

  <span id="user-dropdown-menu" class="pr-1">

    <a-button-group class="mr-1 inline-block" v-if="this._isAuthenticated">
      <a-tooltip placement="left" title="Ajouter au notebook" v-if="_canAddGameInNoteBook">
      <button :class="{'info filled square no-radius-right' : true}" @click="_addToNotebook">
        <font-awesome-icon icon="fa-solid fa-plus"/>
      </button>
      </a-tooltip>
      <button :class="{'translucide' : true, 'accent': !_notebookIsEmpty, 'no-radius-left' : _canAddGameInNoteBook}" @click="_openNotebookModal" style="width: 36px">
        <font-awesome-icon icon="fa-solid fa-book-bookmark"/>
        <span class="ml-1" v-if="_notebookItemsCount > 0">{{ _notebookItemsCount }}</span>
      </button>
    </a-button-group>

    <a-tooltip placement="left" title="Notifications" v-if="this._isAuthenticated">
        <button class="mr-1" @click="_openNotificationsModal" :class="{'translucide' : _unreadNotificationsCount === 0, 'primary' : _unreadNotificationsCount > 0}" style="min-width: 36px">
          <font-awesome-icon icon="fa-solid fa-bell"/>
          <span v-if="_unreadNotificationsCount > 0" class="badge ml-1">{{ _unreadNotificationsCount }}</span>
        </button>
    </a-tooltip>

    <a-tooltip placement="left" title="Connexion"  v-if="!this._isAuthenticated">
      <button class="primary mr-1" @click="_signWithGoogle()">
        <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
      Connexion
      </button>
    </a-tooltip>

    <a-dropdown :trigger="['click']" class="ml-1">
      <button class="translucide" style="width: 36px">
        <font-awesome-icon icon="fa-solid fa-ellipsis"/>
      </button>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="_showSearchModal" class="text-right">
            <span class="mr-2">Recherche</span>
            <font-awesome-icon icon="fa-solid fa-search"/>
          </a-menu-item>
          <a-menu-item @click="_openCalculatorModal" class="text-right">
            <span class="mr-2">Outils</span>
            <font-awesome-icon icon="fa-solid fa-calculator"/>
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_openUserPreferencesModal" v-if="this._isAuthenticated" class="text-right">
            <span class="mr-2">Préférences</span>
            <font-awesome-icon icon="fa-solid fa-user"/>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

  </span>

</template>
