// stores/counter.js
import { defineStore } from 'pinia'
import axiosService from "@/services/axios.service";
import { teamStore } from "@/stores/Team.store";
import { leagueStore } from "@/stores/League.store";
import { playerStore } from "@/stores/Player.store";
import { message } from "ant-design-vue";

export const statStore = defineStore('statStore', {
  state: () => {
    return {
      // Definitions des stats chargées au démarrage
      definitions: null,

      loadingUIDs: [],
      fetchByUIDTimeout: null,
      items: [],

      // labels
      labels: {

        // Football
        1: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        2: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        3: "%Period%, %team% a fait nul dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        4: "%Period%, %team% ne perd pas dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        5: "%Period%, %team% ne gagne pas dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        6: "%Period%, %team% ne fait pas nul dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        7: "%Period%, %team% a gagné la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        8: "%Period%, %team% a perdu la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        9: "%Period%, %team% a fait nul en 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        10: "%Period%, %team% ne perd pas la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        11: "%Period%, %team% ne gagne pas la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        12: "%Period%, %team% ne fait pas nul en 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        13: "%Period%, %team% a gagné la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        14: "%Period%, %team% a perdu la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        15: "%Period%, %team% a fait nul en 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        16: "%Period%, %team% ne perd pas la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        17: "%Period%, %team% ne gagne pas la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        18: "%Period%, %team% ne fait pas nul en 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        19: "%Team% sur %COUNT% victoires %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        20: "%Team% sur %COUNT% défaites %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        21: "%Team% sur %COUNT% matchs sans défaites %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        22: "%Team% sur %COUNT% matchs sans victoires %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        23: "%Team% sur %COUNT% matchs sans nuls %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        24: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs avec +1 but d'écart %location% %league% %against%.",
        25: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs avec +1 but d'écart %location% %league% %against%.",
        26: "%Period%, %team% gagne au moins une MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        27: "%Team% sur %COUNT% match nul %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        28: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against% en étant favori.",
        29: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against% en étant hyper favori.",
        30: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against% en étant outsider.",
        31: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against% en étant hyper outsider.",

        101: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% %against% de %team% se termine avec un BTTS.",
        102: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% %against% de %team% présente un BTTS en 1ère MT.",
        103: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% %against% de %team% présente un BTTS en 2ème MT.",
        104: "%Team% est sur %COUNT% matchs %location% %league% %against% avec BTTS. %Period%, dans %PERCENT% des cas la série s'arrete.",
        105: "%Team% est sur %COUNT% matchs %location% %league% %against% sans BTTS. %Period%, dans %PERCENT% des cas la série s'arrete.",

        201: "%Period%, %team% fait 0 - 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        202: "%Period%, %team% fait 1 - 1 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        203: "%Period%, %team% fait 2 - 2 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        204: "%Period%, %team% fait 3 - 3 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        205: "%Period%, %team% gagne 1 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        206: "%Period%, %team% perd 1 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        207: "%Period%, %team% gagne 2 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        208: "%Period%, %team% perd 2 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        209: "%Period%, %team% gagne 2 à 1 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        210: "%Period%, %team% perd 2 à 1 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        211: "%Period%, %team% gagne 3 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        212: "%Period%, %team% perd 3 à 0 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        213: "%Period%, %team% gagne 3 à 1 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        214: "%Period%, %team% perd 3 à 1 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        215: "%Period%, %team% gagne 3 à 2 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        216: "%Period%, %team% perd 3 à 2 dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",

        217: "%Period%, %team% gagne avec 1 but d'écart dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        218: "%Period%, %team% perd avec 1 but d'écart dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        219: "%Period%, %team% gagne ou perd avec 1 but d'écart dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        220: "%Team% est sur %COUNT% 0 à 0 à la mi-temps %location% %league% %against%. %period%, dans %PERCENT% des cas la série s'arrete.",

        301: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        302: "%Period%, les matchs %location% %league% %against% de %team% comptent +1.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        303: "%Period%, les matchs %location% %league% %against% de %team% comptent +2.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        304: "%Period%, les matchs %location% %league% %against% de %team% comptent +3.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        305: "%Period%, les matchs %location% %league% %against% de %team% comptent +4.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        306: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        307: "%Period%, les matchs %location% %league% %against% de %team% comptent +1.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        308: "%Period%, les matchs %location% %league% %against% de %team% comptent +2.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        309: "%Period%, les matchs %location% %league% %against% de %team% comptent +3.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        310: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        311: "%Period%, les matchs %location% %league% %against% de %team% comptent +1.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        312: "%Period%, les matchs %location% %league% %against% de %team% comptent +2.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        313: "%Period%, les matchs %location% %league% %against% de %team% comptent +3.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        314: "%Period%, les matchs %location% %league% %against% de %team% comptent -0.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        315: "%Period%, les matchs %location% %league% %against% de %team% comptent -1.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        316: "%Period%, les matchs %location% %league% %against% de %team% comptent -2.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        317: "%Period%, les matchs %location% %league% %against% de %team% comptent -3.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        318: "%Period%, les matchs %location% %league% %against% de %team% comptent -4.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        319: "%Period%, les matchs %location% %league% %against% de %team% comptent -0.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        320: "%Period%, les matchs %location% %league% %against% de %team% comptent -1.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        321: "%Period%, les matchs %location% %league% %against% de %team% comptent -2.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        322: "%Period%, les matchs %location% %league% %against% de %team% comptent -3.5 buts à la MT dans %VALUE% (%ABS_VALUE%) des cas.",
        323: "%Period%, les matchs %location% %league% %against% de %team% comptent -0.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        324: "%Period%, les matchs %location% %league% %against% de %team% comptent -1.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        325: "%Period%, les matchs %location% %league% %against% de %team% comptent -2.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        326: "%Period%, les matchs %location% %league% %against% de %team% comptent -3.5 buts en 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        327: "%Period%, %team% marque +0.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        328: "%Period%, %team% marque +1.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        329: "%Period%, %team% marque +2.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        330: "%Period%, %team% marque +3.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",

        331: "%Period%, %team% encaisse +0.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        332: "%Period%, %team% encaisse +1.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        333: "%Period%, %team% encaisse +2.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        334: "%Period%, %team% encaisse +3.5 buts dans %VALUE% (%ABS_VALUE%)  de ses matchs %location% %league% %against%.",
        335: "%Period%, %team% marque +0.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ème MT de ses matchs %location% %league% %against%.",
        336: "%Period%, %team% marque +1.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ère MT de ses matchs %location% %league% %against%.",
        337: "%Period%, %team% marque +2.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ère MT de ses matchs %location% %league% %against%.",
        338: "%Period%, %team% marque +0.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",
        339: "%Period%, %team% marque +1.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",
        340: "%Period%, %team% marque +2.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",
        341: "%Period%, %team% encaisse +0.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ème MT de ses matchs %location% %league% %against%.",
        342: "%Period%, %team% encaisse +1.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ère MT de ses matchs %location% %league% %against%.",
        343: "%Period%, %team% encaisse +2.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 1ère MT de ses matchs %location% %league% %against%.",
        344: "%Period%, %team% encaisse +0.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",
        345: "%Period%, %team% encaisse +1.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",
        346: "%Period%, %team% encaisse +2.5 buts dans %VALUE% (%ABS_VALUE%)  dans la 2ème MT de ses matchs %location% %league% %against%.",

        347: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% se terminent avec un +0.5 buts.",
        348: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% se terminent avec un +1.5 buts.",
        349: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% se terminent avec un +2.5 buts.",
        350: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% se terminent avec un +3.5 buts.",
        351: "%Period%, %VALUE% (%ABS_VALUE%) des matchs %location% %league% se terminent avec un -0.5 buts.",

        352: "%Team% est sur %COUNT% matchs %location% %league% %against% avec -1.5 buts. %period%, dans %PERCENT% des cas la série s'arrete.",
        353: "%Team% est sur %COUNT% matchs %location% %league% %against% avec -2.5 buts. %period%, dans %PERCENT% des cas la série s'arrete.",
        354: "%Team% est sur %COUNT% matchs %location% %league% %against% avec -0.5 buts. %period%, dans %PERCENT% des cas la série s'arrete.",

        355: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans les 15 premieres min de la 1ère MT. dans %VALUE% (%ABS_VALUE%) des cas.",
        356: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts entre la 15eme et la 30eme min de la 1ère MT dans %VALUE% (%ABS_VALUE%) des cas.",
        357: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans les 15 dernières min. de la 1ère MT dans %VALUE% (%ABS_VALUE%) des cas.",

        358: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans les 15 premieres min de la 2ème MT. dans %VALUE% (%ABS_VALUE%) des cas.",
        359: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts entre la 15eme et la 30eme min de la 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",
        360: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans les 15 dernières min. de la 2ème MT dans %VALUE% (%ABS_VALUE%) des cas.",

        401: "%VALUE% buts marqués par %team% %Period% %location% %league% %against%.",
        402: "%VALUE% buts encaissés par %team% %Period% %location% %league% %against%.",
        403: "%VALUE% buts marqués par %team% %Period% %location% %league% %against% en 1ère MT.",
        404: "%VALUE% buts encaissés par %team% %Period% %location% %league% %against% en 1ère MT.",
        405: "%VALUE% buts marqués par %team% %Period% %location% %league% %against% en 2ème MT.",
        406: "%VALUE% buts encaissés par %team% %Period% %location% %league% %against% en 2ème MT.",
        409: "%Period%, %VALUE% buts sont marqués par match %location% %league%.",

        // @Deprecated
        410: "%Period%, %Player% a marqué %ABS_VALUE% buts, soit %VALUE%/m avec %team% %location% %league%.",
        411: "%Period%, %Player% a marqué dans %VALUE% (%ABS_VALUE%) de ses matchs avec %team% %location% %league% %against%.",
        // @Deprecated

        412: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque. %period%, dans %PERCENT% des cas la série s'arrete.",
        413: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque en P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        414: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque en P2. %period%, dans %PERCENT% des cas la série s'arrete.",
        415: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque +1.5 buts. %period%, dans %PERCENT% des cas la série s'arrete.",
        416: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque +1.5 buts en P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        417: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle marque +1.5 buts en P2. %period%, dans %PERCENT% des cas la série s'arrete.",

        418: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse. %period%, dans %PERCENT% des cas la série s'arrete.",
        419: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse en P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        420: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse en P2. %period%, dans %PERCENT% des cas la série s'arrete.",
        421: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse +1.5 buts. %period%, dans %PERCENT% des cas la série s'arrete.",
        422: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse +1.5 buts en P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        423: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% où elle encaisse +1.5 buts en P2. %period%, dans %PERCENT% des cas la série s'arrete.",

        424: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans marquer. %period%, dans %PERCENT% des cas la série s'arrete.",
        425: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans encaisser. %period%, dans %PERCENT% des cas la série s'arrete.",
        426: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% avec autant de but dans chaque MT. %period%, dans %PERCENT% des cas la série s'arrete.",
        427: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but après la 75ème minute. %period%, dans %PERCENT% des cas la série s'arrete.",
        428: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but avant la 16ème minute. %period%, dans %PERCENT% des cas la série s'arrete.",
        429: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but dans 15 dernières minute de P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        430: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but dans 15 premières minute de P2. %period%, dans %PERCENT% des cas la série s'arrete.",
        431: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but dans 2ème quart d'heure de P1. %period%, dans %PERCENT% des cas la série s'arrete.",
        432: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but dans 2ème quart d'heure de P2. %period%, dans %PERCENT% des cas la série s'arrete.",
        433: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but en 1ère MT. %period%, dans %PERCENT% des cas la série s'arrete.",
        434: "%Team% est sur une série de %COUNT% matchs %location% %league% %against% sans but en 2ème MT. %period%, dans %PERCENT% des cas la série s'arrete.",

        500: "%Period%, après une victoire, %team% enchaine par une victoire dans %VALUE% des cas %location% %league% %against%.",
        501: "%Period%, après un nul, %team% rebondit par une victoire dans %VALUE% des cas %location% %league% %against%.",
        502: "%Period%, après une défaite, %team% se reprend par une victoire dans %VALUE% des cas %location% %league% %against%.",
        503: "%Period%, après une victoire, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        504: "%Period%, après un nul, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        505: "%Period%, après une défaite, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        506: "%Period%, après une victoire, %team% perd dans %VALUE% des cas %location% %league% %against%.",
        507: "%Period%, après un nul, %team% perd dans %VALUE% des cas %location% %league% %against%.",
        508: "%Period%, après une défaite, %team% perd dans %VALUE% des cas %location% %league% %against%.",

        601: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs après avoir ouvert le score %location% %league% %against%.",
        602: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs après avoir ouvert le score %location% %league% %against%.",
        603: "%Period%, %team% a gagné ou fait nul dans %VALUE% (%ABS_VALUE%) de ses matchs après avoir ouvert le score %location% %league% %against%.",
        604: "%Period%, %team% a perdu ou fait nul dans %VALUE% (%ABS_VALUE%) de ses matchs après avoir ouvert le score %location% %league% %against%.",
        605: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs après avoir encaissé le 1er but %location% %league% %against%.",
        606: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs après avoir encaissé le 1er but %location% %league% %against%.",
        607: "%Period%, %team% a gagné ou fait nul dans %VALUE% (%ABS_VALUE%) de ses matchs après avoir encaissé le 1er but %location% %league% %against%.",
        608: "%Period%, %team% a perdu ou fait nul dans %VALUE% (%ABS_VALUE%) de ses matchs après avoir encaissé le 1er but %location% %league% %against%.",
        609: "%Period%, %team% a ouvert le score dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        610: "%Period%, %team% a encaissé le 1er but dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        611: "%Period%, %team% a ouvert le score dans %VALUE% (%ABS_VALUE%) de ses matchs en tant que favori %location% %league% %against%.",
        612: "%Period%, %team% a encaissé le 1er but dans %VALUE% (%ABS_VALUE%) de ses matchs en tant que favori %location% %league% %against%.",
        613: "%Team% a ouvert le score dans ses %COUNT% derniers matchs %location% %league% %against%. %period%, dans %PERCENT% des cas la série s'arrete.",
        614: "%Team% a encaissé le 1er but dans ses %COUNT% derniers matchs %location% %league% %against%. %period%, dans %PERCENT% des cas la série s'arrete.",

        // Statistiques de joueurs
        700: "%Period%, %Player% a marqué %ABS_VALUE% buts, soit %VALUE%/m avec %team% %location% %league%.",
        701: "%Period%, %Player% a marqué %ABS_VALUE% buts après être entré en jeu, soit %VALUE%/m avec %team% %location% %league%.",
        702: "%Period%, %Player% a fait %ABS_VALUE% passes D, soit %VALUE%/m avec %team% %location% %league%.",
        703: "%Period%, %Player% a fait %ABS_VALUE% passes D après être entré en jeu, soit %VALUE%/m avec %team% %location% %league%.",
        704: "%Period%, %Player% a été décisif (but ou passe) %ABS_VALUE% fois, soit %VALUE%/m avec %team% %location% %league%.",
        705: "%Period%, %Player% a été décisif (but ou passe) %ABS_VALUE% fois après être entré en jeu, soit %VALUE%/m avec %team% %location% %league%.",
        706: "%Period%, %Player% a marqué %ABS_VALUE% penalty, soit %VALUE%/m avec %team% %location% %league%.",
        707: "%Period%, %Player% a marqué %ABS_VALUE% penalty après être entré en jeu, soit %VALUE%/m avec %team% %location% %league%.",
        708: "%Period%, %Player% a reçu %ABS_VALUE% cartons jaunes, soit %VALUE%/m avec %team% %location% %league%.",
        709: "%Period%, %Player% a reçu %ABS_VALUE% cartons rouges, soit %VALUE%/m avec %team% %location% %league%.",
        710: "%Period%, %Player% a reçu %ABS_VALUE% cartons (J&R), soit %VALUE%/m avec %team% %location% %league%.",
        711: "%Period%, %Player% a été titularisé %ABS_VALUE% fois avec %team% %location% %league%.",
        712: "%Period%, %Player% est entré en jeu %ABS_VALUE% fois avec %team% %location% %league%.",
        713: "%Period%, %Player% a été remplacé %ABS_VALUE% fois avec %team% %location% %league%.",
        714: "%Period%, %Player% a joué %ABS_VALUE% matchs avec %team% %location% %league%.",
        715: "%Period%, %Player% a marqué dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        716: "%Period%, %Player% a marqué dans %ABS_VALUE% matchs où il est entré en jeu, soit %VALUE% avec %team% %location% %league%.",
        717: "%Period%, %Player% a fait une passe D. dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        718: "%Period%, %Player% a fait une passe D. dans %ABS_VALUE% matchs où il est entré en jeu, soit %VALUE% avec %team% %location% %league%.",
        719: "%Period%, %Player% a été décisif dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        720: "%Period%, %Player% a été décisif dans %ABS_VALUE% matchs où il est entré en jeu, soit %VALUE% avec %team% %location% %league%.",
        721: "%Period%, %Player% a marqué sur penalty dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        722: "%Period%, %Player% a marqué sur penalty dans %ABS_VALUE% matchs où il est entré en jeu, soit %VALUE% avec %team% %location% %league%.",
        723: "%Period%, %Player% a pris un jaune dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        724: "%Period%, %Player% a pris un rouge dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",
        725: "%Period%, %Player% a pris un carton dans %ABS_VALUE% matchs, soit %VALUE% avec %team% %location% %league%.",

        726: "%Player% est sur une série de %COUNT% matchs %location% sans marquer %location% %league%.",

        // Handball
        1001: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1002: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1003: "%Period%, %team% fait match nul dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1004: "%Period%, %team% ne perd pas dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1005: "%Period%, %team% ne gagne pas dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1006: "%Period%, %team% ne fait pas nul dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1007: "%Period%, %team% a gagné la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1008: "%Period%, %team% a perdu la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1009: "%Period%, %team% a fait nul en 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1010: "%Period%, %team% ne perd pas la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1011: "%Period%, %team% ne gagne pas la 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1012: "%Period%, %team% ne fait pas nul en 1ère MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1013: "%Period%, %team% a gagné la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1014: "%Period%, %team% a perdu la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1015: "%Period%, %team% a fait nul en 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1016: "%Period%, %team% ne perd pas la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1017: "%Period%, %team% ne gagne pas la 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        1018: "%Period%, %team% ne fait pas nul en 2ème MT dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",

        1301: "%Period%, les matchs %location% %league% %against% de %team% comptent +43.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1302: "%Period%, les matchs %location% %league% %against% de %team% comptent +46.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1303: "%Period%, les matchs %location% %league% %against% de %team% comptent +48.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1304: "%Period%, les matchs %location% %league% %against% de %team% comptent +49.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1305: "%Period%, les matchs %location% %league% %against% de %team% comptent +50.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1306: "%Period%, les matchs %location% %league% %against% de %team% comptent +51.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1307: "%Period%, les matchs %location% %league% %against% de %team% comptent +52.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1308: "%Period%, les matchs %location% %league% %against% de %team% comptent +53.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1309: "%Period%, les matchs %location% %league% %against% de %team% comptent +54.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1310: "%Period%, les matchs %location% %league% %against% de %team% comptent +55.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1311: "%Period%, les matchs %location% %league% %against% de %team% comptent +56.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1312: "%Period%, les matchs %location% %league% %against% de %team% comptent +57.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1313: "%Period%, les matchs %location% %league% %against% de %team% comptent +58.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1314: "%Period%, les matchs %location% %league% %against% de %team% comptent +59.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1315: "%Period%, les matchs %location% %league% %against% de %team% comptent +60.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1316: "%Period%, les matchs %location% %league% %against% de %team% comptent +61.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1317: "%Period%, les matchs %location% %league% %against% de %team% comptent +63.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1318: "%Period%, les matchs %location% %league% %against% de %team% comptent +65.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        1319: "%Period%, les matchs %location% %league% %against% de %team% comptent +69.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",

        1401: "Points marqués par %team% %Period% %location% %league% %against%.",
        1402: "Points encaissés par %team% %Period% %location% %league% %against%.",

        // Basketball
        2001: "%Period%, %team% a gagné %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        2002: "%Period%, %team% a perdu %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        2003: "%Period%, %team% sur %COUNT% victoires %location% %league% %against%. Dans %PERCENT% des cas, la série s'arrête.",
        2004: "%Period%, %team% sur %COUNT% défaites %location% %league% %against%. Dans %PERCENT% des cas, la série s'arrête.",
        2401: "Points marqués par %team% %Period% %location% %league% %against%.",
        2402: "Points encaissés par %team% %Period% %location% %league% %against%.",

        // Hockey
        3001: "%Period%, %team% gagne %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%",
        3002: "%Period%, %team% perd %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        3003: "%Period%, %team% fait match nul dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        3005: "%Period%, %team% ne gagne pas dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        3007: "%Period%, %team% sur %COUNT% victoires %location% %league% %against%. Dans %PERCENT% des cas, la série s'arrête.",
        3008: "%Period%, %team% sur %COUNT% défaites %location% %league% %against%. Dans %PERCENT% des cas, la série s'arrête.",

        3301: "%Period%, les matchs %location% %league% %against% de %team% comptent +0.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3302: "%Period%, les matchs %location% %league% %against% de %team% comptent +1.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3303: "%Period%, les matchs %location% %league% %against% de %team% comptent +2.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3304: "%Period%, les matchs %location% %league% %against% de %team% comptent +3.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3305: "%Period%, les matchs %location% %league% %against% de %team% comptent +4.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3306: "%Period%, les matchs %location% %league% %against% de %team% comptent +5.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3307: "%Period%, les matchs %location% %league% %against% de %team% comptent +6.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3315: "%Period%, les matchs %location% %league% %against% de %team% comptent +7.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3316: "%Period%, les matchs %location% %league% %against% de %team% comptent +8.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3317: "%Period%, les matchs %location% %league% %against% de %team% comptent +9.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",

        3308: "%Period%, les matchs %location% %league% %against% de %team% comptent -0.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3309: "%Period%, les matchs %location% %league% %against% de %team% comptent -1.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3310: "%Period%, les matchs %location% %league% %against% de %team% comptent -2.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3311: "%Period%, les matchs %location% %league% %against% de %team% comptent -3.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3312: "%Period%, les matchs %location% %league% %against% de %team% comptent -4.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3313: "%Period%, les matchs %location% %league% %against% de %team% comptent -5.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3314: "%Period%, les matchs %location% %league% %against% de %team% comptent -6.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3318: "%Period%, les matchs %location% %league% %against% de %team% comptent -7.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3319: "%Period%, les matchs %location% %league% %against% de %team% comptent -8.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",
        3320: "%Period%, les matchs %location% %league% %against% de %team% comptent -9.5 buts dans %VALUE% (%ABS_VALUE%) des cas.",

        // Rugby
        4001: "%Period%, %team% gagne %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%",
        4002: "%Period%, %team% perd %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        4003: "%Team% sur %COUNT% victoires %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        4004: "%Team% sur %COUNT% défaites %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",

        4401: "Points marqués par %team% %Period% %location% %league% %against%.",
        4402: "Points encaissés par %team% %Period% %location% %league% %against%.",
        4403: "Points marqués par %team% %Period% %location% %league% %against% en 1ère MT.",
        4404: "Points encaissés par %team% %Period% %location% %league% %against% en 1ère MT.",
        4405: "Points marqués par %team% %Period% %location% %league% %against% en 2ème MT.",
        4406: "Points encaissés par %team% %Period% %location% %league% %against% en 2ème MT.",
        4407: "%Period%, %VALUE% Points sont marqués par match %location% %league%.",

        4500: "%Period%, après une victoire, %team% enchaine par une victoire dans %VALUE% des cas %location% %league% %against%.",
        4501: "%Period%, après un nul, %team% rebondit par une victoire dans %VALUE% des cas %location% %league% %against%.",
        4502: "%Period%, après une défaite, %team% se reprend par une victoire dans %VALUE% des cas %location% %league% %against%.",
        4503: "%Period%, après une victoire, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        4504: "%Period%, après un nul, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        4505: "%Period%, après une défaite, %team% fait un nul dans %VALUE% des cas %location% %league% %against%.",
        4506: "%Period%, après une victoire, %team% perd dans %VALUE% des cas %location% %league% %against%.",
        4507: "%Period%, après un nul, %team% perd dans %VALUE% des cas %location% %league% %against%.",
        4508: "%Period%, après une défaite, %team% perd dans %VALUE% des cas %location% %league% %against%.",

        6001: "%Period%, %team% gagne %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%",
        6002: "%Period%, %team% perd %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%.",
        6003: "%Period%, %team% gagne %VALUE% (%ABS_VALUE%) de ses 1er set %location% %league% %against%",
        6004: "%Period%, %team% perd %VALUE% (%ABS_VALUE%) de ses 1er set %location% %league% %against%.",
        6005: "%Team% sur %COUNT% victoires %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        6006: "%Team% sur %COUNT% défaites %location% %league% %against%. %Period%, dans %PERCENT% des cas, la série s'arrête.",
        6007: "%Period%, %team% gagne au moins 1 set dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%",
        6008: "%Period%, %team% gagne au moins 2 sets dans %VALUE% (%ABS_VALUE%) de ses matchs %location% %league% %against%",
      },

      // offer choices
      offerChoices: {
        1: { // Equipe gagne
          'HOME': 101,    // 1X2 : 1
          'AWAY': 103,    // 1X2 : 2
        },
        2: { // Equipe perd
          'HOME': 103,    // Double chance : X2
          'AWAY': 101,    // Double chance : 12
        },
        3: { // Equipe fait match nul
          'HOME': 102,    // 1X2 : N
          'AWAY': 102,    // 1X2 : N
        },
        // Double chance
        4: { // Equipe ne perd pas
          'HOME': 201,    // Double chance : 1X
          'AWAY': 203,    // Double chance : 12
        },
        5: { // Equipe ne gagne pas
          'HOME': 203,    // Double chance : X2
          'AWAY': 201,    // Double chance : 1X
        },
        6: { // Equipe ne fait pas match nul
          'HOME': 202,    // Double chance : 12
          'AWAY': 202,    // Double chance : 12
        },
        // 1X2 - 1ere MT
        7: { // Equipe gagne 1ere MT
          'HOME': 1401,    // 1ere MT : 1
          'AWAY': 1403,    // 1ere MT : 2
        },
        8: { // Equipe perd 1ere MT
          'HOME': 1403,    // 1ere MT : 2
          'AWAY': 1401,    // 1ere MT : 1
        },
        9: { // Equipe fait match nul 1ere MT
          'HOME': 1402,    // 1ere MT : N
          'AWAY': 1402,    // 1ere MT : N
        },
        // Double chance - 1ere MT
        10: { // Equipe ne perd pas 1ere MT
          'HOME': 1601,    // Double chance 1ere MT : 1X
          'AWAY': 1603,    // Double chance 1ere MT : 12
        },
        11: { // Equipe ne gagne pas 1ere MT
          'HOME': 1603,    // Double chance 1ere MT : X2
          'AWAY': 1601,    // Double chance 1ere MT : 1X
        },
        12: { // Equipe ne fait pas match nul 1ere MT
          'HOME': 1602,    // Double chance 1ere MT : 12
          'AWAY': 1602,    // Double chance 1ere MT : 12
        },
        // 1X2 - 2eme MT
        13: { // Equipe gagne 2eme MT
          'HOME': 1501,    // 2eme MT : 1
          'AWAY': 1503,    // 2eme MT : 2
        },
        14: { // Equipe perd 2eme MT
          'HOME': 1503,    // 2eme MT : 2
          'AWAY': 1501,    // 2eme MT : 1
        },
        15: { // Equipe fait match nul 2eme MT
          'HOME': 1502,    // 2eme MT : N
          'AWAY': 1502,    // 2eme MT : N
        },
        // Double chance - 2eme MT
        16: { // Equipe ne perd pas 2eme MT
          'HOME': 1701,    // Double chance 2eme MT : 1X
          'AWAY': 1703,    // Double chance 2eme MT : 12
        },
        17: { // Equipe ne gagne pas 2eme MT
          'HOME': 1703,    // Double chance 2eme MT : X2
          'AWAY': 1701,    // Double chance 2eme MT : 1X
        },
        18: { // Equipe ne fait pas match nul 2eme MT
          'HOME': 1702,    // Double chance 2eme MT : 12
          'AWAY': 1702,    // Double chance 2eme MT : 12
        },
        19: { // Série - Equipe gagne
          'HOME': 203,    // Double chance : X2
          'AWAY': 201,    // Double chance : 1X
        },
        20: { // Série - Equipe perd
          'HOME': 201,    // Double chance : 1X
          'AWAY': 203,    // Double chance : X2
        },
        21: { // Série - Equipe ne perd pas
          'HOME': 103,    // 1X2 : 2
          'AWAY': 101,    // 1X2 : 1
        },
        22: { // Série - Equipe ne gagne pas
          'HOME': 101,    // 1X2 : 1
          'AWAY': 103,    // 1X2 : 2
        },
        23: { // Série - Equipe ne fait pas de nul
          'HOME': 102,    // 1X2 : N
          'AWAY': 102,    // 1X2 : N
        },
        27: { // Série - Equipe fait nul
          'HOME': 202,    // Double chance : 12
          'AWAY': 202,    // Double chance : 12
        },

        101: { // Les 2 équipes marquent
          'HOME': 1201,    // BTTS : Oui
          'AWAY': 1201,    // BTTS : Oui
        },

        // Over/Under
        // Over 0.5
        301: { // Equipe marque +0.5 buts
          'HOME': 301,    // Over
          'AWAY': 301,    // Over
        },
        302: { // Equipe encaisse +1.5 buts
          'HOME': 301,    // Over
          'AWAY': 301,    // Over
        },
        303: { // Equipe encaisse +2.5 buts
          'HOME': 301,    // Over
          'AWAY': 301,    // Over
        },
        304: { // Equipe encaisse +3.5 buts
          'HOME': 301,    // Over
          'AWAY': 301,    // Over
        },
        305: { // Equipe encaisse +4.5 buts
          'HOME': 301,    // Over
          'AWAY': 301,    // Over
        },

        314: { // Equipe encaisse -0.5 buts
          'HOME': 302,    // Under
          'AWAY': 302,    // Under
        },
        315: { // Equipe encaisse -1.5 buts
          'HOME': 302,    // Under
          'AWAY': 302,    // Under
        },
        316: { // Equipe encaisse -2.5 buts
          'HOME': 302,    // Under
          'AWAY': 302,    // Under
        },
        317: { // Equipe encaisse -3.5 buts
          'HOME': 302,    // Under
          'AWAY': 302,    // Under
        },
        318: { // Equipe encaisse -4.5 buts
          'HOME': 302,    // Under
          'AWAY': 302,    // Under
        },


        // Handball
        1001: { // Equipe gagne
          'HOME': 101,    // 1X2 : 1
          'AWAY': 103,    // 1X2 : 2
        },
        1002: { // Equipe perd
          'HOME': 103,    // Double chance : X2
          'AWAY': 101,    // Double chance : 12
        },
        1003: { // Equipe fait match nul
          'HOME': 102,    // 1X2 : N
          'AWAY': 102,    // 1X2 : N
        },

        // Hockey
        // Handball
        3001: { // Equipe gagne
          'HOME': 101,    // 1X2 : 1
          'AWAY': 103,    // 1X2 : 2
        },
        3002: { // Equipe perd
          'HOME': 103,    // Double chance : X2
          'AWAY': 101,    // Double chance : 12
        },
        3003: { // Equipe fait match nul
          'HOME': 102,    // 1X2 : N
          'AWAY': 102,    // 1X2 : N
        }

      }
    }
  },
  actions: {
    // Permet de récupérer un match par son UID
    fetchDefinitions(callback = null) {
      if (this.definitions !== null) {
        if (callback) callback()
        return
      }
      this.definitions = []
      axiosService.get('/stats/definitions').then(response => {
        if (!Array.isArray(response.data.results)) {
          message.error("Impossible de charger les définitions des stats. Relancez l'application.")
          return
        }
        this.definitions = response.data.results
      }).catch(error => {
        message.error("Impossible de charger les définitions des stats. Relancez l'application.")
      }).finally(() => {
        if (callback) callback()
      });
    },
    // Permet de récupérer une stat par son UID
    fetchByUID(uid) {

      if (!uid) return

      if (this.loadingUIDs.indexOf(uid) < 0) {
        this.loadingUIDs.push(uid)
      }

      // Si on a 100 équipes à charger, on le fait tout de suite
      const timeout = this.loadingUIDs.length < 100 ? 250 : 0

      if (this.fetchTeamByUIDTimeout) clearTimeout(this.fetchTeamByUIDTimeout)
      this.fetchTeamByUIDTimeout = setTimeout(() => {
        axiosService.get('/stats/' + this.loadingUIDs.join(',')).then(response => {
          this.items = this.items.concat(response.data.results)

          const loadedUIDs = response.data.results.map(item => item.uid.toString())
          this.loadingUIDs = this.loadingUIDs.filter(uid => loadedUIDs.indexOf(uid.toString()) < 0)
        });

      }, timeout)
    },

    addItems(items) {
      if(!items) return
      this.items = this.items.concat(items)
    }
  },
  getters: {

    getDefinitions: (state) => (sport) => {
      if (!state.definitions) {
        return []
      }
      // check if state.definitions is an array
      if (!Array.isArray(state.definitions)) {
        return []
      }
      return state.definitions.filter(definition => definition.sport === sport)
    },

    getDefinitionByFixedUID: (state) => (fixedUID) => {
      if (!state.definitions) {
        return null
      }
      if (!Array.isArray(state.definitions)) {
        console.error("state.definitions is not an array", state.definitions)
        return null
      }
      return state.definitions.find(definition => parseInt(definition.fixedUID) === parseInt(fixedUID))
    },

    get: (state) => (uid) => {

      if (!uid) {
        return null
      }

      uid = uid.toString()

      if (state.loadingUIDs.indexOf(uid) >= 0) {
        return null
      }

      let item = state.items.find(item => item.uid.toString() === uid);
      if (item) {
        return item
      }

      // Si la team n'est pas dans le store, on le charge
      const ts = statStore()
      ts.fetchByUID(uid)

      return null
    },

    getPercent: (state) => (stat, period) => {
      if (!stat || !period) {
        console.error("stat and period required", stat, period)
        return "?"
      }

      if (stat.type !== 'SERIE') {
        console.error("stat type must be SERIE", stat)
        return "?"
      }

      let percent = null
      switch (period) {
        case "CURRENT_SEASON":
          percent = ((1.0 - stat.currentSeasonSerieFrequencySup) * 100.0).toFixed(1)
          break
        case "ALL_TIME":
          percent = ((1.0 - stat.serieFrequencySup) * 100.0).toFixed(1)
          break
        default:
          console.error("Unknown period", period)
          break
      }

      if (!percent) {
        console.warn("Cannot found stat percent", stat, period)
        return "-"
      }

      // is NaN
      if (isNaN(percent)) {
        return "-"
      }

      return percent
    },

    getValue: (state) => (stat, period, formatted = true) => {
      if (!stat || !period) {
        console.error("stat and period required", stat, period)
        return "?"
      }

      let relative = null
      if (stat.type === 'SERIE') {
        relative = stat.allTimeValue
      } else {
        switch (period) {
          case "LAST_5_GAMES":
            relative = stat.l5GamesValueRelative
            break;
          case "LAST_10_GAMES":
            relative = stat.l10GamesValueRelative
            break;
          case "CURRENT_SEASON":
            relative = stat.currentSeasonValueRelative
            break;
          case "ALL_TIME":
            relative = stat.allTimeValueRelative
            break;
        }
      }
      if (relative === null || typeof relative === 'undefined') {
        console.warn("Cannot found stat val", 'stat=', stat, 'period=', period)
        return "-"
      }

      if (formatted) {
        return statStore().formatValue(relative, stat.type)
      }

      return relative
    },

    getAbsoluteValue: (state) => (stat, period) => {
      if (!stat || !period) {
        console.error("stat and period required", stat, period)
        return "?"
      }

      let absolute = null
      if (stat.type === 'SERIE') {
        switch (period) {
          case "CURRENT_SEASON":
            absolute = stat.currentSeasonValue
            break;
          case "ALL_TIME":
            absolute = stat.allTimeValue
            break;
          default:
            console.error("Unknown period for serie", period)
            break
        }
      } else {
        switch (period) {
          case "LAST_5_GAMES":
            absolute = stat.l5GamesValue
            break;
          case "LAST_10_GAMES":
            absolute = stat.l10GamesValue
            break;
          case "CURRENT_SEASON":
            absolute = stat.currentSeasonValue
            break;
          case "ALL_TIME":
            absolute = stat.allTimeValue
            break;
        }
      }
      if (absolute === null || typeof absolute === 'undefined') {
        console.warn("Cannot found stat val", 'stat=', stat, 'period=', period)
        return "-"
      }

      return absolute
    },

    formatValue: (state) => (value, type) => {
      if (value == null || typeof value === 'undefined') {
        console.warn("Cannot format value", value, type, typeof value)
        return "-"  // value is null
      }

      // If NaN or Infinity
      if (isNaN(value) || !isFinite(value)) {
        return "-.-"
      }

      // convert value to float
      try {
        value = parseFloat(value)

        switch (type) {
          case 'RAW':
            return value.toFixed(1)
          case 'SERIE':
            return value.toFixed(0)
          default:
            return (value * 100.0).toFixed(0) + '%'
        }
      } catch (e) {
        console.warn("Cannot format value", value, type, typeof value)
        return "?"
      }
    },

    getValueByUID: (state) => (uid) => {
      const ss = statStore()
      let stat = ss.get(uid);
      return ss.getValue(stat)
    },

    getLabel: (state) => (stat, period) => {
      if (!stat || !period) {
        console.error("stat and period are required", stat, period)
        return "..."
      }

      if(!stat.definitionFixedUID){
        console.error("stat.definitionFixedUID is required", stat)
        return "..."
      }

      const isCombined = stat.definitionFixedUID.indexOf('x') >= 0 || stat.definitionFixedUID.indexOf('+') >= 0 || stat.definitionFixedUID.indexOf('|') >= 0
      if(isCombined){
        // split on x, + or |
        const operands = stat.definitionFixedUID.split(/x|\+|\|/).map(item => item.trim())
        let label = stat.definitionFixedUID
        operands.forEach(operand => {
          const def = state.definitions.find(item => item.fixedUID === parseInt(operand));
          if (!def) {
            label = label.replace(operand, "?")
          } else {
            label = label.replace(operand, def.name)
          }
        })
        label = label.replace('x', ' et ')
        label = label.replace('+', ' ou ')
        label = label.replace('|', ' si ')
        return label
      }

      let label = state.labels[stat.definitionFixedUID]

      if (!label) {
        console.warn("Cannot found stat label", stat, state.labels)
        const def = state.definitions.find(item => item.uid === stat.definitionUID);
        if (!def) {
          label = "?"
        } else {
          label = def.name
        }
      }

      // On remplace les variables
      const ss = statStore()
      if (label.indexOf('%team%') >= 0 || label.indexOf('%Team%') >= 0) {
        let teamName = "?"
        let team = teamStore().get(stat.whoUID);
        if (team) {
          teamName = team.name
        }
        label = label.replaceAll('%Team%', teamName)
        label = label.replaceAll('%team%', teamName)
      }

      if (label.indexOf('%player%') >= 0 || label.indexOf('%Player%') >= 0) {
        let playerName = "?"
        let player = playerStore().get(stat.playerUID);
        if (player) {
          playerName = player.name
        }
        label = label.replaceAll('%Player%', playerName)
        label = label.replaceAll('%player%', playerName)
      }

      if (label.indexOf('%COUNT%') >= 0) {
        label = label.replaceAll('%COUNT%', stat.allTimeValue)
      }
      if (label.indexOf('%VALUE%') >= 0) {
        const value = ss.getValue(stat, period)
        label = label.replaceAll('%VALUE%', value)
      }
      if (label.indexOf('%ABS_VALUE%') >= 0) {
        const value = ss.getAbsoluteValue(stat, period)
        label = label.replaceAll('%ABS_VALUE%', value)
      }
      if (label.indexOf('%PERCENT%') >= 0) {
        const percent = ss.getPercent(stat, period)
        label = label.replaceAll('%PERCENT%', percent + '%')
      }
      if (label.indexOf('%Period%') >= 0 || label.indexOf('%period%') >= 0) {
        let periodLabel = "??"
        switch (period) {
          case 'LAST_5_GAMES':
            periodLabel = "Sur les 5 derniers matchs"
            break;
          case 'LAST_10_GAMES':
            periodLabel = "Sur les 10 derniers matchs"
            break;
          case 'CURRENT_SEASON':
            periodLabel = "Depuis le début de la saison"
            break;
          case 'ALL_TIME':
            periodLabel = "Depuis toujours"
            break;
        }
        label = label.replaceAll('%Period%', periodLabel)
        label = label.replaceAll('%period%', periodLabel.toLowerCase())
      }
      if (label.indexOf('%Against%') >= 0 || label.indexOf('%against%') >= 0) {
        let againstLabel = "??"
        if (stat.againstUID) {
          let againtTeam = teamStore().get(stat.againstUID);
          if (againtTeam) {
            againstLabel = "contre " + againtTeam.name
          } else {
            againstLabel = "contre son adversaire"
          }

        } else {
          againstLabel = ""
        }
        label = label.replaceAll('%Against%', againstLabel)
        label = label.replaceAll('%against%', againstLabel)
      }
      if (label.indexOf('%League%') >= 0 || label.indexOf('%league%') >= 0) {
        let leagueLabel = "??"
        if (stat.leagueUID) {
          let league = leagueStore().get(stat.leagueUID);
          if (league) {
            leagueLabel = " en " + league.name
            label = label.replaceAll('%League%', leagueLabel)
            label = label.replaceAll('%league%', leagueLabel)
          }
        } else {
          leagueLabel = "Toutes compétitions confondues"
          label = label.replaceAll('%League%', leagueLabel)
          label = label.replaceAll('%league%', leagueLabel.toLowerCase())
        }
      }
      if (label.indexOf('%Location%') >= 0 || label.indexOf('%location%') >= 0) {
        let leagueLabel = "??"
        switch (stat.location) {
          case 'HOME':
            leagueLabel = "à domicile"
            break;
          case 'AWAY':
            leagueLabel = "à l'extérieur"
            break;
          case 'ANY':
            leagueLabel = ""
            break;
        }
        label = label.replaceAll('%Location%', leagueLabel)
        label = label.replaceAll('%location%', leagueLabel.toLowerCase())
      }

      // remove multiple spaces
      label = label.replace(/\s{2,}/g, ' ')

      return label

    },

    getOperandLabel: (state) => (statDef, operand) => {
      if (!statDef || !operand) {
        console.error("statDef and operand are required", statDef, operand)
        return "..."
      }

      let label = state.labels[statDef.fixedUID]

      if (!label) {
        console.warn("Cannot found statDef label", statDef, state.labels)
        const def = state.definitions.find(item => item.uid === statDef.uid);
        if (!def) {
          label = "?"
        } else {
          label = def.name
        }
      }

      // On remplace les variables
      const ss = statStore()
      if (label.indexOf('%team%') >= 0 || label.indexOf('%Team%') >= 0) {
        let teamLabel = "??"
        switch (operand.who) {
          case 'HOME':
            teamLabel = "L'équipe à domicile"
            break;
          case 'AWAY':
            teamLabel = "L'équipe à l'extérieur"
            break;
          case 'ANY':
            teamLabel = "Une des équipes"
            break;
          case 'CURRENT':
          default:
            teamLabel = "Son équipe"
        }
        label = label.replaceAll('%Team%', teamLabel)
        label = label.replaceAll('%team%', teamLabel.toLowerCase())
      }
      if (label.indexOf('%player%') >= 0 || label.indexOf('%Player%') >= 0) {
        let playerLabel = "??"
        switch (operand.player) {
          case 'HOME':
            playerLabel = "Joueur à domicile"
            break;
          case 'AWAY':
            playerLabel = "Joueur à l'extérieur"
            break;
          case 'ANY':
            playerLabel = "Un des joueurs"
            break;
        }
        label = label.replaceAll('%Player%', playerLabel)
        label = label.replaceAll('%player%', playerLabel.toLowerCase())
      }
      if (label.indexOf('%VALUE%') >= 0) {
        let valueLabel = "??"
        switch (operand.condition.operator) {
          case 'UPPER':
            valueLabel = "plus de %VALUE%"
            break;
          case 'LOWER':
            valueLabel = "moins de %VALUE%"
            break;
        }
        label = label.replaceAll('%VALUE%', valueLabel)
      }
      if (label.indexOf('%VALUE%') >= 0) {
        let valueLabel = "??"
        switch (statDef.type) {
          case 'FREQUENCY':
            valueLabel = (operand.condition.value * 100.0).toFixed(1) + '%'
            break;
          case 'SERIE':
            valueLabel = operand.condition
            break;
          case 'RAW':
            valueLabel = operand.condition.value
            break;
        }
        label = label.replaceAll('%VALUE%', valueLabel)
      }

      if (label.indexOf('%ABS_VALUE%') >= 0) {
        const value = (operand.condition.valueAbsolute) ? operand.condition.valueAbsolute : 0
        label = label.replaceAll('%ABS_VALUE%', value) // Non utilisé
      }

      if (label.indexOf('%PERCENT%') >= 0) {
        const percent = (operand.condition.value * 100.0).toFixed(0) + '%'
        label = label.replaceAll('%PERCENT%', percent)
      }
      if (label.indexOf('%COUNT%') >= 0) {
        // Dans opérandes, on a pas le count, on le récupère dans la stat
        let valueAbsolute = operand.condition.valueAbsolute;
        if (!valueAbsolute) {
          valueAbsolute = 1
        }
        label = label.replaceAll('%COUNT%', "série d'au moins " + valueAbsolute)
      }
      if (label.indexOf('%Period%') >= 0 || label.indexOf('%period%') >= 0) {
        let periodLabel = "??"
        switch (operand.period) {
          case 'LAST_5_GAMES':
            periodLabel = "Sur les 5 derniers matchs"
            break;
          case 'LAST_10_GAMES':
            periodLabel = "Sur les 10 derniers matchs"
            break;
          case 'CURRENT_SEASON':
            periodLabel = "Depuis le début de la saison"
            break;
          case 'ALL_TIME':
            periodLabel = "Depuis toujours"
            break;
        }
        label = label.replaceAll('%Period%', periodLabel)
        label = label.replaceAll('%period%', periodLabel.toLowerCase())
      }
      if (label.indexOf('%Against%') >= 0 || label.indexOf('%against%') >= 0) {
        let againstLabel = "??"
        switch (operand.against) {
          case 'CURRENT':
            againstLabel = "Contre l'adversaire du jour"
            break;
          case 'ANY':
            againstLabel = ""
            break;
        }
        label = label.replaceAll('%Against%', againstLabel)
        label = label.replaceAll('%against%', againstLabel.toLowerCase())
      }
      if (label.indexOf('%League%') >= 0 || label.indexOf('%league%') >= 0) {
        let leagueLabel = "??"
        switch (operand.league) {
          case 'CURRENT':
            leagueLabel = "Dans la compétition du jour"
            break;
          case 'ANY':
            leagueLabel = "Toutes compétitions confondues"
            break;
        }
        label = label.replaceAll('%League%', leagueLabel)
        label = label.replaceAll('%league%', leagueLabel.toLowerCase())
      }
      if (label.indexOf('%Location%') >= 0 || label.indexOf('%location%') >= 0) {
        let locationLabel = "??"
        switch (operand.location) {
          case 'CURRENT':
            locationLabel = "(Dom. ou Ext.)"
            break;
          case 'ANY':
            locationLabel = ""
            break;
        }
        label = label.replaceAll('%Location%', locationLabel)
        label = label.replaceAll('%location%', locationLabel.toLowerCase())
      }

      // remove multiple spaces
      label = label.replace(/\s{2,}/g, ' ')

      // First letter in uppercase
      label = label.charAt(0).toUpperCase() + label.slice(1)

      return label

    },

    getDataCount: (state) => (stat, period) => {
      if (!stat || !period) {
        console.error("stat and period are required", stat, period)
        return []
      }

      let result = null
      switch (period) {
        case "LAST_5_GAMES":
          result = stat.l5DataCount
          break;
        case "LAST_10_GAMES":
          result = stat.l10DataCount
          break;
        case "CURRENT_SEASON":
          result = stat.currentSeasonDataCount
          break;
        case "ALL_TIME":
          result = stat.allTimeDataCount
          break;
      }
      if (result === null || typeof result === 'undefined') {
        console.warn("Cannot found stat data count", stat, period, result)
        return "-"
      }

      return result
    },

    getDataUIDs: (state) => (stat, period, matchingOnly = false) => {
      if (!stat || !period) {
        console.error("stat and period are required", stat, period)
        return []
      }

      let uids = null
      switch (period) {
        case "LAST_5_GAMES":
          uids = matchingOnly ? stat.l5DataMatchingUIDs : stat.l5DataUIDs
          break;
        case "LAST_10_GAMES":
          uids = matchingOnly ? stat.l10DataMatchingUIDs : stat.l10DataUIDs
          break;
        case "CURRENT_SEASON":
          uids = matchingOnly ? stat.currentSeasonDataMatchingUIDs : stat.currentSeasonDataUIDs
          break;
        case "ALL_TIME":
          uids = matchingOnly ? stat.allTimeDataMatchingUIDs : stat.allTimeDataUIDs
          break;
      }
      if (!uids) {
        console.warn("Cannot found stat data uids", stat, period, matchingOnly)
        return "-"
      }

      return uids
    },

    getAll: (state) => {
      return state.items
    },

    getOfferChoiceFixedUIDBySdefFixedUID: (state) => (sdefFixedUID) => {
      let offerChoices = state.offerChoices[sdefFixedUID];
      if (!offerChoices) {
        //console.warn("Cannot found offerChoices for sdefFixedUID", sdefFixedUID)
        return {}
      }
      return offerChoices
    }
  }
})