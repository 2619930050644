<script setup>
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
</script>

<script>
import {modalStore} from "@/stores/Modal.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import gameScore from "@/components/common/GameScore.vue";
import {gameStore} from "@/stores/Game.store";
import {teamStore} from "@/stores/Team.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "WelcomePane",
  components: {},

  computed: {
    _selectionPaneSelectedGameUID() {
      return selectionPaneStore().getSelectedGameUID()
    },
    _selectionPaneSelectedGame() {
      if(!this._selectionPaneSelectedGameUID) return null
      return gameStore().get(this._selectionPaneSelectedGameUID)
    },
    _homeTeamUID() {
      if(!this._selectionPaneSelectedGame) return null
      return this._selectionPaneSelectedGame.homeTeamUID
    },
    _awayTeamUID() {
      if(!this._selectionPaneSelectedGame) return null
      return this._selectionPaneSelectedGame.awayTeamUID
    }
  },

  methods: {
    _analyse() {
      // un onglet d'analyse sur desktop
      analysePaneStore().addGame(this._selectionPaneSelectedGameUID, contextStore().getSport)
    },
  }
}
</script>

<template>

  <div id="welcome-pane">
    <section v-if="!_selectionPaneSelectedGame">
      <h1>Comment commencer ?</h1>
      <p>Sélectionne une rencontre dans le panneau de gauche pour commencer à l'analyser</p>
      <youtube-button video-id="bG2dHYSse4M" class="ml-2 large mt-5" label="Comment ça marche ?"/>
    </section>
    <section v-else>
      <table>
        <tbody>
        <tr>
          <td>
            <team-logo :uid="_homeTeamUID" size="large" class="mr-2"/>
          </td>
          <td>
            <team-logo :uid="_awayTeamUID" size="large" class="ml-2"/>
          </td>
        </tr>
        <tr style="background: none">
          <td style="width: 50%;">
            <h1>
            <team-name :uid="_homeTeamUID"/>
            </h1>
          </td>
          <td style="width: 50%">
            <h1>
            <team-name :uid="_awayTeamUID"/>
            </h1>
          </td>
        </tr>
        </tbody>
      </table>
      <p class="mt-5">
        Explore la rencontre en détail grâce à des widgets dédiés en ouvrant une perspective.
      </p>
      <p class="mt-3">
        Chaque perspective offre une analyse approfondie pour affiner tes stratégies
      </p>

      <button class="large accent mt-5" @click="_analyse">Analyser la rencontre</button>
    </section>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";


#welcome-pane {
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: calc(100% - 400px - 3 * @padding-small) !important;

  section {
    text-align: center;
    margin: 200px 100px ;
    border: 1px solid @stroke-color;
    border-radius: @radius-regular;
    padding: 64px;
    background: rgba(0, 0, 0, 0.15);
    h1, p {
      max-width: 450px;
      text-align: center;
      font-size: 2em !important;
      width: 100%;
      margin: auto;
    }
    p{
      font-size: 1.5em !important;
      color: white;
      text-align: center;
      margin-top: 24px !important;
    }
  }
}


.mobile {
  #welcome-pane-wrapper {
    #welcome-pane {
      display: none;
    }
  }
}

</style>