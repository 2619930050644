<script>
import axiosService from "@/services/axios.service";
import TeamLogo from "@/components/common/TeamLogo.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import {contextStore} from "@/stores/Context.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {gameStore} from "@/stores/Game.store";
import {teamStore} from "@/stores/Team.store";
import TeamName from "@/components/common/TeamName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetParamButton from "@/components/common/widgets/parameter/WidgetParamButton.vue";
import WidgetMomentParamButton from "@/components/common/widgets/parameter/WidgetMomentParamButton.vue";

export default {
  name: "GameAllOverWidget",
  components: {
    WidgetMomentParamButton,
    WidgetParamButton,
    LeagueLogo,
    TeamName,
    WidgetLoadingContent,
    StatisticValueButton,
    TeamLogo
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this._onGameUIDChange()
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _game() {
      return gameStore().get(contextStore().gameUID)
    },
    _homeTeam() {
      if (!this._game) return ''
      return teamStore().get(this._game.homeTeamUID)
    },
    _awayTeam() {
      if (!this._game) return ''
      return teamStore().get(this._game.awayTeamUID)
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    }
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const sport = contextStore().getSport
      // Only FOOTBALL and HOCKEY are supported
      if (sport !== 'FOOTBALL' && sport !== 'HOCKEY') {
        this.data = {}
        console.warn('GameAllOverWidget: sport not supported', sport)
        return
      }

      let moment = perspectiveStore().getParameterValue(this.item.uid, 'moment');
      const payload = {
        gameUID: contextStore().gameUID,
        moment: moment ? moment : 'FT',
        sport: sport
      }
      axiosService.put('/wid/game-all-over', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Over
      <div class="widget-header-params flex" v-if="item">
        <widget-moment-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>

      <table>
        <tbody>
        <tr>
          <th colspan="1" rowspan="2">
            <league-logo :uid="_game.leagueUID"/>
          </th>
          <th colspan="2">
            <team-logo :uid="_homeTeam.uid" size="small"/>
            <team-name :uid="_homeTeam.uid"/>
          </th>

          <th colspan="2">
            <team-name :uid="_awayTeam.uid"/>
            <team-logo :uid="_awayTeam.uid" size="small"/>
          </th>
        </tr>
        <tr>
          <th colspan="1">
            Domicile
          </th>
          <th colspan="2">
            Saison
          </th>
          <th colspan="1">
            Extérieur
          </th>
        </tr>
        <template v-for="item in Object.keys(data.homeSide)">
          <tr>
            <th>
              {{ item }}
            </th>
            <td>
              <statistic-value-button :uid="data.homeSide[item]" period="CURRENT_SEASON"/>
            </td>
            <td>
              <statistic-value-button :uid="data.homeAll[item]" period="CURRENT_SEASON"/>
            </td>
            <td>
              <statistic-value-button :uid="data.awayAll[item]"
                                      period="CURRENT_SEASON"/>
            </td>
            <td>
              <statistic-value-button :uid="data.awaySide[item]"
                                      period="CURRENT_SEASON"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.widget-content {
  color: @text-color;

  table {
    width: 100%;

    th, td {
      border-top: 1px solid @stroke-color;
      padding: 0 @padding-regular;
      text-align: center;
    }

    tr {
      &:nth-child(2n) {
        background-color: @background-color-light;
      }
    }
  }
}

</style>