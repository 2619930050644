<script>
import MainHeader from "@/components/common/MainHeader.vue";
import WelcomeBankrollPane from "@/components/routes/bankroll/panes/welcome/WelcomeBankrollPane.vue";
import AnalyseBankrollPane from "@/components/routes/bankroll/panes/analyse/AnalyseBankrollPane.vue";
import BankrollPane from "@/components/routes/bankroll/panes/bankroll/BankrollPane.vue";
// Stores
import {contextStore} from "@/stores/Context.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {bankrollPaneStore} from "@/components/routes/bankroll/panes/bankroll/BankrollPane.store";
import {bankrollRouteStore} from "@/components/routes/bankroll/BankrollRoute.store";

export default {
  name: "BankrollRoute",
  components: {
    AnalyseBankrollPane,
    WelcomeBankrollPane,
    BankrollPane,
    MainHeader
  },
  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _urlUID() {
      // On recupere l'uid de l'url
      return this.$route.query.uid
    },
    _selectedBankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    }
  },
  beforeMount() {
    if (!contextStore().isInitialized()) {
      this.$router.push({name: 'init', query: {redirect: 'bankrolls', query: this._urlUID}})
    }

    // On recupere la bankroll par son uid, si elle n'est pas deja en memoire on la recupere
    bankrollStore().get(this._urlUID)

    bankrollRouteStore().init()
  },
  mounted() {
    // On met a jour le nom de la route courante
    contextStore().setRoute('bankrolls')
    setTimeout(() => {
      if (this._urlUID) {
        bankrollPaneStore().setBankrollActiveUID(this._urlUID)
      }
    }, 250)
  }
}
</script>

<template>
  <div :class="{'mobile': _isMobile}">
    <main-header/>
    <div class="flex">
      <bankroll-pane/>
      <analyse-bankroll-pane v-if="_selectedBankrollUID"/>
      <welcome-bankroll-pane v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>