import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowTrendDown,
  faArrowTrendUp,
  faBagShopping,
  faBan,
  faBars,
  faBarsStaggered,
  faBaseball,
  faBasketball,
  faBell, faBellSlash,
  faBolt,
  faBookBookmark,
  faBriefcase,
  faBullseye,
  faCalculator,
  faCalendar,
  faCalendarDay,
  faCaretDown,
  faChartPie,
  faChartSimple,
  faCheckCircle, faChevronLeft, faChevronRight,
  faCircle,
  faCircleCheck,
  faCircleHalfStroke,
  faCircleQuestion,
  faCircleRight,
  faClose,
  faCodeMerge,
  faCog,
  faComment,
  faCopy,
  faCrown,
  faDatabase,
  faEllipsis,
  faEraser,
  faExclamationTriangle,
  faEye,
  faEyedropper,
  faEyeSlash,
  faFileExcel, faFilter,
  faFootball,
  faFutbol,
  faGift,
  faGlobe,
  faHeart,
  faHeartPulse,
  faHelmetUn,
  faHockeyPuck,
  faHome,
  faHouse,
  faLink,
  faLocationPin,
  faLock,
  faMagnifyingGlass,
  faObjectGroup,
  faPen,
  faPersonRunning,
  faPlane,
  faPlay,
  faPlus,
  faQuestion,
  faRightToBracket,
  faShareFromSquare,
  faShield,
  faShieldHalved, faSliders,
  faSortAmountDesc,
  faSortDown,
  faSortUp,
  faSpider,
  faSpinner,
  faSquare,
  faStar,
  faStethoscope,
  faTrashCan,
  faTrophy,
  faTruckMedical,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faVolleyball,
  faWallet
} from '@fortawesome/free-solid-svg-icons'
import { faCircle as faCircleRegular, faStar as faStarRegular, } from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createI18n } from 'vue-i18n'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Antd from 'ant-design-vue';
import App from './App.vue'
import * as Sentry from "@sentry/vue";

// Styles
import './index.less'
import 'ant-design-vue/dist/reset.css';

import messages from './locales'

import tiny_emitter from "tiny-emitter/instance";
// ApexCharts
import VueApexCharts from "vue3-apexcharts";
// Router
import { createRouter, createWebHashHistory } from "vue-router";
import MainRoute from "@/components/routes/main/MainRoute.vue";
import SelectionRoute from "@/components/routes/selection/SelectionRoute.vue";
import InitRoute from "@/components/routes/init/InitRoute.vue";
import BankrollRoute from "./components/routes/bankroll/BankrollRoute.vue";
import StatsRoute from "./components/routes/stats/StatsRoute.vue";
import BlogRoute from "@/components/routes/blog/BlogRoute.vue";
import PlayerRoute from "@/components/routes/player/PlayerRoute.vue";
import ProfilerRoute from "@/components/routes/profiler/ProfilerRoute.vue";

// Font Awesome
library.add(faBellSlash)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faFilter)
library.add(faSliders)
library.add(faCaretDown)
library.add(faCodeMerge)
library.add(faBaseball)
library.add(faChartSimple)
library.add(faFileExcel)
library.add(faTruckMedical)
library.add(faGift)
library.add(faEllipsis)
library.add(faArrowRightToBracket)
library.add(faArrowRightFromBracket)
library.add(faSquare)
library.add(faYoutube)
library.add(faEyedropper)
library.add(faBookBookmark)
library.add(faCrown)
library.add(faShareFromSquare)
library.add(faBolt)
library.add(faStethoscope)
library.add(faUpRightAndDownLeftFromCenter)
library.add(faBars)
library.add(faChartPie)
library.add(faLink)
library.add(faSortDown)
library.add(faSortUp)
library.add(faEraser)
library.add(faLock)
library.add(faCalculator)
library.add(faPersonRunning)
library.add(faLocationPin)
library.add(faCalendarDay)
library.add(faHome)
library.add(faCopy)
library.add(faHeartPulse)
library.add(faExclamationTriangle)
library.add(faHeart)
library.add(faBullseye)
library.add(faMagnifyingGlass)
library.add(faBan)
library.add(faUser)
library.add(faShield)
library.add(faStar)
library.add(faStarRegular)
library.add(faShieldHalved)
library.add(faBagShopping)
library.add(faBell)
library.add(faCheckCircle)
library.add(faCircle)
library.add(faCircleRegular)
library.add(faCircleRight)
library.add(faCircleCheck)
library.add(faCircleHalfStroke)
library.add(faCircleQuestion)
library.add(faCalendar)
library.add(faPlay)
library.add(faCog)
library.add(faPen)
library.add(faClose)
library.add(faPlus)
library.add(faComment)
library.add(faDatabase)
library.add(faBriefcase)
library.add(faStethoscope)
library.add(faFutbol)
library.add(faBasketball)
library.add(faHockeyPuck)
library.add(faVolleyball)
library.add(faFootball)
library.add(faWallet)
library.add(faSpider)
library.add(faArrowTrendDown)
library.add(faArrowTrendUp)
library.add(faObjectGroup)
library.add(faSortAmountDesc)
library.add(faTrashCan)
library.add(faEye)
library.add(faEyeSlash)
library.add(faHouse)
library.add(faPlane)
library.add(faQuestion)
library.add(faRightToBracket)
library.add(faTrophy)
library.add(faHelmetUn)
library.add(faBarsStaggered)
library.add(faGlobe)
library.add(faSpinner)

const i18n = createI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

// Emitter
window.emitter = tiny_emitter

// Pinia
const pinia = createPinia()

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {path: '/', component: MainRoute, name: 'main'},
        {path: '/init', component: InitRoute, name: 'init'},
        {path: '/bankrolls', component: BankrollRoute, name: 'bankrolls'},
        {path: '/bankroll', component: BankrollRoute, name: 'bankroll'},
        {path: '/stats', component: StatsRoute, name: 'stats'},
        {path: '/games', component: SelectionRoute, name: 'games'},
        {path: '/players', component: PlayerRoute, name: 'players'},
        {path: '/profiler', component: ProfilerRoute, name: 'profiler'},
        {path: '/blog/:article', component: BlogRoute, name: 'blog'},
    ],
})

// Vue App
const app = createApp(App)
    .use(pinia)
    .use(i18n)
    .use(VueApexCharts)
    .use(Antd)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)


if (import.meta.env.PROD) {
    Sentry.init({
        app: app,
        dsn: "https://d2b69465436dda9c7828a94093323e15@o4505571451535360.ingest.sentry.io/4506823098564608",
        release: {
            name: '202410',
        },
        integrations: [

        ],
        telemetry: false,
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["*"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
} else {
    console.warn('Sentry disabled in development mode.')
}


app.mount("#app");
