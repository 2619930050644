<script>
import {modalStore} from "../../../../stores/Modal.store";
import {bankrollStore} from "../../../../stores/Bankroll.store";
import {offerStore} from "@/stores/Offer.store";
import {message} from "ant-design-vue";
import {monitoringStore} from "@/stores/Monitoring.store";
import {selectionStore} from "@/stores/Selection.store";
import SelectionItem from "@/components/common/SelectionItem.vue";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "CreateMonitoringModal",
  components: {SelectionItem},
  data() {
    return {
      bankrollUID: null,
      bookmakerUID: null,
      notificationEnabled: true,
      addBetForm: {
        visible: false,
        offerFamilyFixedUID: null,
        offerArgument: null,
        selectedOfferFamilyChoiceFixedUID: null
      }
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'createMonitoringModal';
    },

    _payload() {
      if (!this._isVisible) return null;
      const payload = modalStore().getVisibleModal.payload
      if (!payload) return null;
      return payload;
    },

    _selectionUID() {
      if (!this._payload) return null;
      return this._payload.selectionUID;
    },

    _selection(){
      if (!this._selectionUID) return null;
      return selectionStore().get(this._selectionUID);
    },

    _sportOfferFamilies() {
      if(!this._selection) return []
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(this._selection.sport);
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },

    _bookmakerChoices() {
      const choices = [
        {
          label: 'La plus haute',
          value: null
        }
      ]
      bookmakerStore().getAll.forEach(b => {
        choices.push({
          label: b.name,
          value: b.uid
        })
      })
      return choices
    },

    _sport(){
      return contextStore().getSport
    },

    _sportOfferFamilyChoices() {
      const results = []
      if (!this._sportOfferFamilies) {
        return results
      }

      this._sportOfferFamilies.forEach(f => {

        // On liste les arguments possibles
        let args = []
        f.choices.forEach(c => {
          if (null !== f.arguments && f.arguments[this._sport]) {
            f.arguments[this._sport].forEach(a => {
              args.push(a)
            })
          }
        })
        // remove duplicates
        args = args.filter((v, i, a) => a.indexOf(v) === i);
        // remove null
        args = args.filter(a => a !== null)
        if (args.length === 0) {
          args = null
        }

        results.push({
          label: offerStore().getOfferFamilyDefinitionLabel(f.code),
          value: f.fixedUID,
          args: args
        })
      })
      return results
    },
    _selectedFamily() {
      //if (!this.item) return null

      let familyFixedUID = this.addBetForm.offerFamilyFixedUID;
      if (!familyFixedUID) {
        return null
      }
      if (!this._sportOfferFamilies) {
        return null
      }

      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(familyFixedUID))
      if (!family) {
        return null
      }
      return family
    },
    _offerTypeLabel() {
      if (!this._selectedFamily) return 'Choisir un type d\'offre'
      const arg = this.addBetForm.offerArgument
      return offerStore().getOfferFamilyDefinitionLabel(this._selectedFamily.code) + (arg ? ' ' + arg : '')
    },
    _bookmakerChoiceLabel() {
      let label = 'La plus haute'
      this._bookmakerChoices.forEach(c => {
        if (c.value === this.bookmakerUID) {
          label = c.label
        }
      })
      return label
    },
    _bankrolls() {
      const all = bankrollStore().getUserBankrolls
      // keep only isMonitoring = true
      return all.filter(b => b.isMonitoring)
    },
    _formCompleted() {
      return this.addBetForm.selectedOfferFamilyChoiceFixedUID !== null
    }
  },
  methods: {

    _onApply() {
      if (!this._formCompleted) {
        message.error('Tu dois choisir un type de bet')
        return
      }

      monitoringStore().create(this._selectionUID, this.bankrollUID, this.bookmakerUID, this.notificationEnabled, this.addBetForm, () => {
        message.success('Monitoring créé')
        this._onCancel()
      })
    },

    _onCancel() {
      modalStore().closeModal()
    },

    _onFamilyChange(fixedUID, arg) {
      this.addBetForm.offerFamilyFixedUID = fixedUID
      this.addBetForm.offerArgument = arg
      this.addBetForm.selectedOfferFamilyChoiceFixedUID = null

      if (this._selectedFamily && this._selectedFamily.choices) {
        if (this._selectedFamily.choices.length > 0) {
          this.addBetForm.selectedOfferFamilyChoiceFixedUID = this._selectedFamily.choices[0].fixedUID
        }
      }
    },

    _onBookmakerChange(choice) {
      this.bookmakerUID = choice.value
    },

    _choiceLabel(choice) {
      return offerStore().getOfferFamilyChoiceLabel(choice, this.addBetForm.offerArgument, true)
    },
    _isSelectedChoice(choice) {
      return this.addBetForm.selectedOfferFamilyChoiceFixedUID === choice.fixedUID
    },
    _setOfferFamilyChoice(choice) {
      this.addBetForm.selectedOfferFamilyChoiceFixedUID = choice.fixedUID
    }
  },
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="328" @cancel="_onCancel">
    <header class="ant-modal-header">
      Nouveau monitoring
    </header>

    <fieldset class="mt-2 mx-1 no-border">
      <legend>Sélection cible</legend>
      <selection-item :value="_selection" :show-controls="false" :show-monitoring="false"/>
    </fieldset>

    <hr class="my-2"/>

    <fieldset class="m-1 no-border">
      <legend>Type de bet</legend>
      <div class="pb-1 flex">
        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="small info mr-1">
            {{ _offerTypeLabel }}
          </button>
          <template #overlay>
            <a-menu>
              <span v-for="c in _sportOfferFamilyChoices">
                <a-menu-item @click="_onFamilyChange(c.value, null)" v-if="c.args === null">
                  {{ c.label }}
                </a-menu-item>
                <a-sub-menu :key="c.value" :title="c.label" v-else>
                  <a-menu-item v-for="arg in c.args" @click="_onFamilyChange(c.value, arg)">
                    {{ arg }}
                  </a-menu-item>
                </a-sub-menu>
              </span>
            </a-menu>
          </template>
        </a-dropdown>

        <div v-if="_selectedFamily">
          <button :class="{'small mr-1':true, 'primary selected' : _isSelectedChoice(c)}"
                  v-for="c in _selectedFamily.choices" @click="_setOfferFamilyChoice(c)">
            {{ _choiceLabel(c) }}
          </button>
        </div>
      </div>
    </fieldset>
    <fieldset class="m-1 no-border">
      <legend>Côte à utiliser de préférence</legend>
      <div class="pb-1 flex">
        <a-dropdown :trigger="['click']" class="flex-grow-0">
          <button class="small info mr-1">
            {{ _bookmakerChoiceLabel }}
          </button>
          <template #overlay>
            <a-menu>
            <span v-for="c in _bookmakerChoices">
              <a-menu-item @click="_onBookmakerChange(c)">
                {{ c.label }}
              </a-menu-item>
            </span>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </fieldset>


    <hr class="my-2"/>

    <fieldset class="m-1 no-border">
      <legend>Notification</legend>
      <a-checkbox v-model:checked="notificationEnabled" class="ml-1">Activer les notification</a-checkbox>
    </fieldset>

    <hr class="my-2"/>

    <fieldset class="m-1 no-border">
      <legend>Bankroll de destination</legend>
      <a-select v-model:value="bankrollUID" style="width: 100%" placeholder="Choisir une bankroll">
        <a-select-option :key="null" :value="null">Nouvelle bankroll</a-select-option>
        <a-select-option v-for="b in _bankrolls" :key="b.uid" :value="b.uid">{{ b.name }}</a-select-option>
      </a-select>
      <a-alert v-if="!bankrollUID" message="Tu peux choisir d'utiliser une bankroll déjà existante ou en créer une nouvelle." type="info" show-icon class="mt-1"/>
    </fieldset>


    <template #footer>
      <div class="my-2 mx-1 flex">
        <button class="" @click="_onCancel">Annuler</button>
        <div class="flex-grow"/>
        <button class="mr-2 primary" @click="_onApply">Créer le monitoring</button>

      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>