<script>

import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";

import AnalysePaneTab from "@/components/routes/selection/panes/analyse/header/tab/AnalysePaneHeaderTab.vue";
import {contextStore} from "@/stores/Context.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";

export default {
  name: "AnalysePaneHeader",
  components: {TeamName, AnalysePaneTab},
  computed: {
    _gameUIDs() {
      return analysePaneStore().getGameUIDsBySport(contextStore().getSport)
    },
    _selectionPaneSelectedGameUID() {
      return selectionPaneStore().getSelectedGameUID()
    },
    _selectionPaneSelectedGame() {
      if(!this._selectionPaneSelectedGameUID) return null
      return gameStore().get(this._selectionPaneSelectedGameUID)
    },
    _homeTeamUID() {
      if(!this._selectionPaneSelectedGame) return null
      return this._selectionPaneSelectedGame.homeTeamUID
    },
    _awayTeamUID() {
      if(!this._selectionPaneSelectedGame) return null
      return this._selectionPaneSelectedGame.awayTeamUID
    },
    _canBeOpen() {
      if(!this._selectionPaneSelectedGameUID) return false
      return !this._gameUIDs.includes(this._selectionPaneSelectedGameUID)
    }

  },
  methods: {
    _analyse() {
      // un onglet d'analyse sur desktop
      analysePaneStore().addGame(this._selectionPaneSelectedGameUID, contextStore().getSport)
    },
  }
}
</script>

<template>
  <header id="analyse-pane-header">
    <analyse-pane-tab v-for="gameUID in _gameUIDs" :gameUID="gameUID"/>
    <button class="accent m-1" v-if="_canBeOpen" @click="_analyse">+ <team-name :uid="_homeTeamUID" short/> - <team-name :uid="_awayTeamUID" short/></button>
  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

#analyse-pane-header {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none; /* For Firefox */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}

</style>