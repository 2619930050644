<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import LeagueName from "@/components/common/LeagueName.vue";
import GameFavIndicator from "@/components/common/GameFavIndicator.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import GoalsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Goals.Tab.vue";
import DiagnosticTab
  from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Diagnostic.Tab.vue";
import StatsTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Stats.Tab.vue";
import FormTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Forme.Tab.vue";
import UnderOverTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.UnderOver.Tab.vue";
import H2HTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.H2H.Tab.vue";
import ScoreTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Score.Tab.vue";
import SimilarTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Similar.Tab.vue";
import StandingTab from "@/components/routes/selection/panes/selection/items/tabs/SelectionPaneItem.Standing.Tab.vue";
import {contextStore} from "@/stores/Context.store";

export default {
  name: "SelectionPaneItemTabs",
  components: {
    ScoreTab, H2HTab, UnderOverTab, FormTab, DiagnosticTab, StatsTab, GoalsTab, SimilarTab, StandingTab,
    LeagueLogo, GameFavIndicator, LeagueName,
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,
  },
  props: {
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      onLeft: true,
      onRight: false
    }
  },

  computed: {
    _game() {
      if (!this.gameUid) return null
      return gameStore().get(this.gameUid)
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _selectedTabLabel() {
      switch (selectionPaneStore().selectedItemTab) {
        case 'FORME':
          return 'Forme'
        case 'STANDING':
          return 'Classement'
        case 'SCORE':
          return 'Score'
        case 'GOALS':
          return 'Points'
        case 'OVER_UNDER':
          return 'Over/Under'
        case 'H2H':
          return 'Head2Head'
        case 'SIM':
          return 'Profilage'
        case 'DIAGNOSTIC':
          return 'Diagnostic'
        default:
          return 'Statistiques'
      }
    },
    _tabsWrapperStyles() {
      return {
        paddingRight : '32px'
      }
    }
  },

  methods: {

    _isTabVisible(tab) {

      if (!this._game) {
        return false  // Pas de game, pas de tab
      }
      const sport = this._game.sport
      switch (sport) {
        case 'FOOTBALL':
          return true
        case 'HOCKEY':
          return !['STANDING'].includes(tab)
        case 'TENNIS':
          return ['FORME', 'H2H', 'SIM'].includes(tab)
        default:
          return !['SCORE', 'UNDER', 'OVER'].includes(tab)
      }
    },

    _isSelectedTab(tab) {
      if (!this._isTabVisible(tab)) {
        return false
      }
      return selectionPaneStore().selectedItemTab === tab
    },

    _setSelectedTab(tab) {
      selectionPaneStore().selectedItemTab = tab
    },

    _scrollLeft() {
      document.querySelector('.tabs-wrapper').scrollTo({
        left: 0,
        behavior: 'smooth'
      })
      this.onLeft = true
      this.onRight = false
    },

    _scrollRight() {
      // Scroll to the right
      document.querySelector('.tabs-wrapper').scrollTo({
        behavior: 'smooth',
        left: document.querySelector('.tabs-wrapper').scrollWidth
      })
      this.onLeft = false
      this.onRight = true
    },
  }
}
</script>

<template>
  <div class="selection-pane-item-tabs">
    <div class="selection-pane-item-tabs-header">
      <div class="selected-tab-wrapper flex">
        <button :class="{'small no-radius tab selected': true}">{{ _selectedTabLabel }}</button>

        <div class="scroll-button left" @click="_scrollLeft" v-if="!_isMobile && !onLeft">
          <font-awesome-icon icon="chevron-left"/>
        </div>
      </div>
      <div class="tabs-wrapper flex-grow" :style="_tabsWrapperStyles">

          <button
              v-if="_isTabVisible('FORME') && !_isSelectedTab('FORME')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('FORME')">Forme
          </button>
          <button
              v-if="_isTabVisible('STANDING') && !_isSelectedTab('STANDING')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('STANDING')">Classement
          </button>
          <button
              v-if="_isTabVisible('SCORE') && !_isSelectedTab('SCORE')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('SCORE')">Score
          </button>
          <button
              v-if="_isTabVisible('GOALS') && !_isSelectedTab('GOALS')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('GOALS')">Points
          </button>
          <button
              v-if="_isTabVisible('OVER_UNDER') && !_isSelectedTab('OVER_UNDER')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('OVER_UNDER')">Over/Under
          </button>
          <button
              v-if="_isTabVisible('H2H') && !_isSelectedTab('H2H')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('H2H')">Head2Head
          </button>
          <button
              v-if="_isTabVisible('SIM') && !_isSelectedTab('SIM')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('SIM')">Profilage
          </button>
          <button
              v-if="_isTabVisible('DIAGNOSTIC') && !_isSelectedTab('DIAGNOSTIC')"
              :class="{'small no-radius tab': true}"
              @click="_setSelectedTab('DIAGNOSTIC')">Diagnostic
          </button>

      </div>

      <div class="scroll-button right" @click="_scrollRight" v-if="!_isMobile && !onRight">
        <font-awesome-icon icon="chevron-right"/>
      </div>
    </div>
    <div>
      <form-tab :game-uid="gameUid" v-if="_isSelectedTab('FORME')"/>
      <standing-tab :game-uid="gameUid" v-if="_isSelectedTab('STANDING')"/>
      <goals-tab :game-uid="gameUid" v-if="_isSelectedTab('GOALS')"/>
      <under-over-tab :game-uid="gameUid" v-if="_isSelectedTab('OVER_UNDER')"/>
      <score-tab :game-uid="gameUid" v-if="_isSelectedTab('SCORE')"/>
      <h2-h-tab :game-uid="gameUid" v-if="_isSelectedTab('H2H')"/>
      <similar-tab :game-uid="gameUid" v-if="_isSelectedTab('SIM')"/>
      <diagnostic-tab :game-uid="gameUid" v-if="_isSelectedTab('DIAGNOSTIC')"/>
    </div>

  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-pane-item-tabs {
  background: @background-color;

  .selection-pane-item-tabs-header {
    padding: 0;
    border-bottom: 1px solid @stroke-color;
    display: flex;
    overflow: hidden;
  }

  .scroll-button {
    width: 24px;
    height: calc(24px + 2 * @padding-regular);
    line-height: calc(24px + 2 * @padding-regular);
    background: @background-color;
    color: @text-color;

    &.left {
      border-right: 1px solid @stroke-color;
    }

    &.right {
      position: absolute;
      right: 0;
      top: 0;
      border-left: 1px solid @stroke-color;
    }
  }

  .selected-tab-wrapper {
    padding: 0;
  }

  .tabs-wrapper {
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
  }

  .tab {
    display: inline;
    border-radius: 12px !important;
    height: 24px !important;
    line-height: 22px !important;
    background: @background-color-light;
    padding: 0 @padding-regular !important;
    margin-left: @padding-small;
    margin-top: @padding-regular;
    margin-bottom: @padding-regular;
    border: none;

    &.selected {
      background: @accent-color;
      color: @text-color;
      font-weight: bold;
    }

    &:hover {
      background: #f97f28;
    }
  }
}
</style>