<script>

import TeamName from "@/components/common/TeamName.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";

export default {
  name: "StatsDataHeaderTab",
  components: {
    TeamLogo,
    TeamName,
    AreaFlag
  },
  props: {
    perspective: {
      type: Object,
      required: true
    }
  },
  computed: {
    _isSelected() {
      return false // TODO
    },
    _perspectiveName() {
      return this.perspective.name
    }
  },
  methods: {

  }
}
</script>

<template>
  <div :class="{'stats-data-header-tab': true, 'selected' : _isSelected}">
    {{_perspectiveName}}
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.stats-data-header-tab {
  display: inline-block;
  height: @toolbar-regular;
  line-height: @toolbar-regular;
  cursor: pointer;
  opacity: 0.7;
  color: @text-color;


  .stats-data-header-tab-close {
    visibility: hidden;
  }

  &:hover, &.selected {
    opacity: 1;

    .analyse-pane-tab-close {
      visibility: visible;
    }

    .active-border {
      opacity: 0.2;
    }
  }

  .active-border {
    width: 100%;
    height: 4px;
    background: -webkit-linear-gradient(0deg, @home-color, @away-color);
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.0;
    border-radius: @radius-regular;

    &.selected {
      opacity: 1;
    }
  }
}
</style>