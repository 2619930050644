<script>
import UserLeaguePreferences from "@/components/common/modal/user_preferences/category/UserLeaguePreferences.vue";
import UserFlagsPreferences from "@/components/common/modal/user_preferences/category/UserFlagsPreferences.vue";
import UserAccountPreferences from "@/components/common/modal/user_preferences/category/UserAccountPreferences.vue";
import {modalStore} from "@/stores/Modal.store";
import UserReferringPreferences from "@/components/common/modal/user_preferences/category/UserReferringPreferences.vue";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";

export default {
  name: "UserPreferencesModal",
  components: {
    UserReferringPreferences,
    UserAccountPreferences,
    UserFlagsPreferences,
    UserLeaguePreferences
  },
  data() {
    return {
      selectedCategory: 'account',
      categories: [
        {label: 'Compte', uid: 'account'},
        {label: 'Flags', uid: 'flags'},
        {label: 'Compétition', uid: 'league'},
        {label: 'Parrainage', uid: 'sponsoring'}
      ],
    }
  },
  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'userPreferencesModal';
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      console.log('visibleModal', visibleModal)
      if (!visibleModal) return {};
      return visibleModal.payload;
    },
    _loggedUser() {
      return contextStore().loggedUser;
    },
  },
  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _isCategorySelected(category) {
      if (!this.selectedCategory) return false;
      return this.selectedCategory === category
    },
    _logout() {
      contextStore().logout()
    },
    copyLinkToClipboard() {
      const value = `https://docto.bet?p=${this._loggedUser.referralCode}`
      // put the value to copy in the clipboard
      const input = document.createElement('input')
      input.value = value
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)

      message.info("Lien copié dans le presse-papier")
    },
    _createFlag(){
      modalStore().openModal({
        uid: 'flagSettingModal',
        payload: null
      })
    }
  },
  watch: {
    _isVisible(val) {
      if (val) {
        console.log(this._payload)
        if (this._payload && this._payload.tab) {
          this.selectedCategory = this._payload.tab
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Préférences
    </header>

    <div class="content">
      <div class="flex" style="overflow-x: scroll; scrollbar-width: none">
        <button :class="{'no-radius': true, 'tab selected': _isCategorySelected(c.uid)}"
                @click="selectedCategory=c.uid" v-for="c in categories">
          {{ c.label }}
        </button>
      </div>
      <hr/>

      <user-account-preferences v-if="selectedCategory === 'account'"/>
      <user-flags-preferences v-if="selectedCategory === 'flags'"/>
      <user-league-preferences v-if="selectedCategory === 'league'"/>
      <user-referring-preferences v-if="selectedCategory === 'sponsoring'"/>
    </div>

    <template #footer>
      <div class="my-2 mx-1">
        <div v-if="selectedCategory === 'account'">
          <button class="secondary" @click="_logout()">Déconnexion</button>
        </div>
        <div v-if="selectedCategory === 'flags'">
          <button class="accent" v-if="selectedCategory === 'flags'" @click="_createFlag">Créer un flag</button>
        </div>
        <div v-if="selectedCategory === 'sponsoring'">
          <button class="accent" @click="copyLinkToClipboard()" v-if="_loggedUser.referralCode">Copier lien de parrainage</button>
        </div>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
</style>