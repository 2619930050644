// stores/counter.js
import { defineStore } from 'pinia'
import { selectionStore } from "@/stores/Selection.store";
import axiosService from "@/services/axios.service";
import { preferencesStore } from "@/stores/Preferences.store";
import { contextStore } from "@/stores/Context.store";
import { gameStore } from "@/stores/Game.store";
import { notebookStore } from "@/stores/NotebookStore";
import { selectionRouteStore } from "@/components/routes/selection/SelectionRoute.store";
import { statsRouteStore } from "@/components/routes/stats/StatsRoute.store";

export const statsPaneStore = defineStore('statsPaneStore', {
  state: () => {
    return {

      dateInterval: null,
      selectedLeagueLabel: 'Toutes',
      selectedLeagueUIDs: [],
      arjelOnly: null,
      filter: '',

      selectedGameUID: null,
      selectedItemTab: 'FORME',

      displayedPage: 1

    }
  },
  actions: {
    init() {
      this.arjelOnly = preferencesStore().getBoolean('BETTING', 'ARJEL_ONLY', null)
    },

    setDateInterval(dateInterval, callback = null) {
      this.dateInterval = dateInterval
      if (callback) {
        callback()
      }
    },

    setSelectedGameUID(gameUID) {
      if (this.selectedGameUID === gameUID) {
        this.selectedGameUID = null
        return
      }
      this.selectedGameUID = gameUID
    },

    setSelectedLeagueLabel(leagueLabel) {
      this.selectedLeagueLabel = leagueLabel
    },
    setSelectedLeagueUIDs(leagueUIDs) {
      this.selectedLeagueUIDs = leagueUIDs
    },
    incrementPage() {
      this.displayedPage++
    },
    setDisplayedPage(page) {
      this.displayedPage = page
    }
  },
  getters: {

    getDisplayedPage: (state) => () => {
      return state.displayedPage
    },

    getSelectedGameUID: (state) => () => {
      return state.selectedGameUID
    },

    getSelectedLeagueUIDs: (state) => () => {
      return state.selectedLeagueUIDs
    }
  }
})