<script>

export default {
  name: "ArjelButton",
  props: {
    arjelOnly: {
      required: true
    },
    callback: {
      type: Function,
      required: false
    }
  },

  computed: {
    _classes() {
      return {
        'arjel-button':true,
        'info' : true,
        'positive' : this.arjelOnly === true,
        'negative' : this.arjelOnly === false
      }
    },
    _src() {
      return '/images/arjel.png'
    },
    _height(){
      return '16px'
    }
  },

  methods: {
    _onClick() {
      if (this.callback){
        this.callback()
      }
    }
  },
}
</script>

<template>
  <button :class="_classes" @click="_onClick()">
    <img :src="_src" alt="Arjel" :style="{height: _height}">
  </button>
</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

.arjel-button {
  &.positive {
    background: fade(@positive-color, 25%) !important;
  }
  &.negative  {
    background: fade(@negative-color, 25%) !important;
  }
}
</style>

