<script>
import {GridItem, GridLayout} from 'vue3-grid-layout-next'


import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Widgets
import OddRankSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/OddRankSuccessRate.Widget.vue";
import LeagueSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/LeagueSuccessRate.Widget.vue";
import ScoreSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/ScoreSuccessRate.Widget.vue";
import TotalGoalsSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/TotalGoalsSuccessRate.Widget.vue";
import TeamSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/TeamSuccessRate.Widget.vue";
import AreaSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/AreaSuccessRate.Widget.vue";
import DayOfWeekSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/DayOfWeekSuccessRate.Widget.vue";
import UnderOverSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/UnderOverSuccessRate.Widget.vue";
import PointDiffSuccessRateWidget from "@/components/routes/bankroll/panes/analyse/widgets/PointDiffSuccessRate.Widget.vue";
import {bankrollRouteStore} from "@/components/routes/bankroll/BankrollRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "AnalyseBankrollPerspective",
  components: {
    FontAwesomeIcon,
    GridItem, GridLayout,

    // Widgets
    OddRankSuccessRateWidget,
    LeagueSuccessRateWidget,
    ScoreSuccessRateWidget,
    TotalGoalsSuccessRateWidget,
    TeamSuccessRateWidget,
    AreaSuccessRateWidget,
    DayOfWeekSuccessRateWidget,
    UnderOverSuccessRateWidget,
    PointDiffSuccessRateWidget
  },
  computed: {
    _selectedPerspectiveUID() {
      return bankrollRouteStore().getSelectedPerspectiveUID()
    },
    _perspective() {
      if (!this._selectedPerspectiveUID) return null
      return perspectiveStore().get(this._selectedPerspectiveUID)
    },
    _widgets() {
      if (!this._selectedPerspectiveUID) return []
      return perspectiveStore().getWidgetsByPerspectiveUID(this._selectedPerspectiveUID)
    },
    _hasWidgets() {
      return this._widgets.length > 0
    },
    _layout() {
      const layout = []
      if (!this._widgets) return layout

      this._widgets.forEach((widget) => {
        const widgetDefinition = widgetDefinitionsStore().getByType(widget.type)
        if (!widgetDefinition) {
          console.warn("Widget definition not found for widget type: " + widget.type)
          return
        }
        layout.push({
          'i': widget.uid,
          'uid': widget.uid,
          'x': widget.x, 'y': widget.y,
          'w': widgetDefinition.dimensions.width, 'h': widgetDefinition.dimensions.height,
          'type': widget.type
        })
      })
      return layout
    },
    _isPerspectiveYours() {
      if (!contextStore().isAuthenticated()) return false
      const perspective = this._perspective
      if (!perspective) return false
      return perspective.userUID === contextStore().getLoggedUserUID()
    },
    _perspectiveInEditMode() {
      const isYours = this._isPerspectiveYours
      if (!isYours) return false
      if(!this._perspective) return false
      if (!this._perspective.editMode) return false
      return this._perspective.editMode
    },
  },
  methods: {
    _openWidgetStore() {
      if (!this._perspective) return
      if (this._perspective.userUID !== contextStore().getLoggedUserUID()) {
        message.error('Tu dois être le propriétaire de la perspective pour ajouter un widget')
        return
      }
      modalStore().openModal({
        uid: 'widgetStoreModal', payload: {
          perspective: this._perspective
        }
      })
    },
    _onLayoutUpdated(layout) {
      if (!this._perspectiveInEditMode) return
      perspectiveStore().updateLayout(this._perspective.uid, layout)
    }
  }
}
</script>

<template>
  <div id="empty-perspective" v-if="!_hasWidgets">
    <p>Cette perspective ne contient aucun widget</p>
    <button class="info" @click="_openWidgetStore">Ajouter un widget</button>
  </div>
  <div v-else id="analyse-bankroll-perspective-wrapper">
    <div id="analyse-bankroll-perspective">
      <grid-layout :layout.sync='_layout'
                   :col-num='128'
                   :row-height='8'
                   :is-resizable='false'
                   :vertical-compact='false'
                   :use-css-transforms='true'
                   :is-draggable='_perspectiveInEditMode'
                   @layout-updated="_onLayoutUpdated">
        <grid-item v-for='item in _layout' class="widget"
                   :x='item.x'
                   :y='item.y'
                   :w='item.w'
                   :h='item.h'
                   :i='item.i'>
          <component :is="item.type" :item="item"/>
        </grid-item>
      </grid-layout>
    </div>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#analyse-bankroll-perspective-wrapper {
  height: calc(100dvh - @toolbar-regular);
  width: calc(100vw - 400px);
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  #analyse-bankroll-perspective {
    height: 2096px;
    width: 2096px;
    overflow: hidden;
  }
}


#empty-perspective {
  margin: auto;
  width: calc(100vw - 400px);

  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

</style>