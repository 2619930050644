<script>

import AnalysePaneTab from "@/components/routes/selection/panes/analyse/header/tab/AnalysePaneHeaderTab.vue";
import {contextStore} from "@/stores/Context.store";
import TeamName from "@/components/common/TeamName.vue";
import StatsDataHeaderTab from "@/components/routes/stats/panes/data/header/tab/StatsDataHeaderTab.vue";
import {perspectiveStore} from "@/stores/Perspective.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {modalStore} from "@/stores/Modal.store";
import {message} from "ant-design-vue";

export default {
  name: "StatsDataHeader",
  components: {StatsDataHeaderTab, TeamName, AnalysePaneTab},
  computed: {
    _selectedSport(){
      return contextStore().getSport
    },
    _perspectives() {
      return perspectiveStore().getBySportAndType(this._selectedSport, 'TEAM')
    },
    _selectedPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    }
  },
  methods: {
    _isSelected(perspective) {
      return perspective.uid === this._selectedPerspectiveUID
    },
    _onClick(perspective) {
      if (this._isSelected(perspective)) {
        this._showPerspectiveSettingsModal(perspective)
      } else {
        statsRouteStore().setSelectedPerspectiveUID(perspective.uid)

        // Save the perspective as default for the user/sport
        preferencesStore().save('DEFAULT_PERSPECTIVE', 'TEAM_PERSPECTIVE', [perspective.uid])
      }
    },
    _showPerspectiveSettingsModal(perspective, creation = false) {
      if (perspective.userUID !== contextStore().getLoggedUserUID()) {
        return
      }

      modalStore().openModal({
        uid: 'perspectiveSettingsModal', payload: {
          uid: perspective.uid,
          creation: creation
        }
      })
    }
  }
}
</script>

<template>
  <header id="stats-data-header">
    <stats-data-header-tab v-for="p in _perspectives" :perspective="p"/>
  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

#stats-data-header {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  scrollbar-width: none; /* For Firefox */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}

</style>