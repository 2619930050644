<script>
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {leagueStore} from "@/stores/League.store";
import {sportStore} from "@/stores/Sport.store";
import {flagStore} from "@/stores/Flag.store";

export default {
  name: "FlagSettingModal",

  data() {
    return {
      shouldBeInitialized: true,
      uid: null,
      label: '',
      level: 'INFO',
      sport: 'FOOTBALL',
      teamUID: null,
      leagueUID: null,
      previousLeagueUID: null,
      nextLeagueUID: null,
    }
  },

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'flagSettingModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload
    },
    _sports() {
      return sportStore().getAll
    },
    _label() {
      if (!this.label) return 'Tu n\'as pas encore spécifié de libellé';
      if (this.label.trim().length === 0) return 'Tu n\'as pas encore spécifié de libellé';
      return this.label;
    },
    _type(){
      switch (this.level) {
        case 'INFO':
          return 'info'
        case 'WARNING':
          return 'warning'
        case 'DANGER':
          return 'error'
        case 'SUCCESS':
          return 'success'
      }
    },
    _currentLeagueName() {
      if (!this.leagueUID) return 'Choisir'
      const league = leagueStore().get(this.leagueUID)
      if (!league) return 'Choisir'
      return league.name
    },
    _previousLeagueName() {
      if (!this.previousLeagueUID) return 'Choisir'
      const league = leagueStore().get(this.previousLeagueUID)
      if (!league) return 'Choisir'
      return league.name
    },
    _nextLeagueName() {
      if (!this.nextLeagueUID) return 'Choisir'
      const league = leagueStore().get(this.nextLeagueUID)
      if (!league) return 'Choisir'
      return league.name
    },
    _canValidate() {
      const hasLabel = this.label.trim().length > 0;
      const hasTeamUID = this.teamUID !== null
      const hasLeagueUID = this.leagueUID !== null || this.previousLeagueUID !== null || this.nextLeagueUID !== null
      return hasLabel && (hasLeagueUID || hasTeamUID)
    }
  },

  methods: {
    _onCancel() {
      this.label = ''
      this.level = 'INFO'
      this.sport = 'FOOTBALL'
      this.teamUID = null
      this.leagueUID = null
      this.previousLeagueUID = null
      this.nextLeagueUID = null
      this.shouldBeInitialized = true
      modalStore().closeModal();
    },
    _setLevel(level) {
      this.level = level
    },
    _setSport(sport) {
      this.sport = sport.code
    },
    _validate() {

      if(!this._canValidate) return

      const params = {
        uid: this.uid,
        label: this.label,
        level: this.level,
        sport: this.sport,
        teamUID: this.teamUID,
        leagueUID: this.leagueUID,
        previousGameLeagueUID: this.previousLeagueUID,
        nextGameLeagueUID: this.nextLeagueUID,
      }
      flagStore().createOrUpdate(params, () => {
        modalStore().closeModal()
      })
    },
    _delete(){
      flagStore().delete(this.uid, () => {
        modalStore().closeModal()
      })
    },
    _openSelectionLeagueModal(type) {

      const selectedUIDs = []
      switch (type){
        case 'current':
          selectedUIDs.push(this.leagueUID)
          break
        case 'previous':
          selectedUIDs.push(this.previousLeagueUID)
          break
        case 'next':
          selectedUIDs.push(this.nextLeagueUID)
          break
      }

      modalStore().openModal({
        uid: 'selectionLeagueModal',
        payload: {
          selectedUIDs: selectedUIDs,
          sport: this.sport,
          unique: true,
          onConfirm: (leagues) => {

            let leagueUID = null
            if(leagues.length > 0) leagueUID = leagues[0].uid

            switch (type){
              case 'current':
                this.leagueUID = leagueUID
                break
              case 'previous':
                this.previousLeagueUID = leagueUID
                break
              case 'next':
                this.nextLeagueUID = leagueUID
                break
            }
          }
        }
      })
    },
  },

  watch: {
    _isVisible(val) {
      if (val) {
        if(!this.shouldBeInitialized) return
        if (this._payload && this._payload.flag) {
          const flag = this._payload.flag
          this.uid = flag.uid
          this.label = flag.label
          this.level = flag.level
          this.sport = flag.sport
          this.teamUID = flag.teamUID
          this.leagueUID = flag.leagueUID
          this.previousLeagueUID = flag.previousGameLeagueUID
          this.nextLeagueUID = flag.nextGameLeagueUID
          this.shouldBeInitialized = false
        }else{
          this.sport = contextStore().getSport
        }
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="600" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Configuration du Flag
    </header>

    <fieldset class="m-1 no-border">
      <legend>Comment ça fonctionne ?</legend>
      <p class="px-2">
        Tu dois spécifier ici, les critères que doit présenter un match pour qu'il soit considéré comme un match à flaguer. Le match devra présenter TOUS les critères que tu auras spécifié.
      </p>
    </fieldset>

    <hr/>

    <div class="px-2 mt-1 mb-2 pt-1">
      <div class="flex">
        <label class="w-2/5">Visuel</label>
        <div class="w-2/5">
          <input v-model="label" placeholder="Texte decrivant le flag" class="w-full" maxlength="32"/>
        </div>
        <div class="w-1/5">
          <a-dropdown :trigger="['click']" class="ml-1">
            <button class="info small float-right">
              {{ level }}
            </button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="_setLevel('SUCCESS')">
                  SUCCESS
                </a-menu-item>
                <a-menu-item @click="_setLevel('INFO')">
                  INFO
                </a-menu-item>
                <a-menu-item @click="_setLevel('WARNING')">
                  WARNING
                </a-menu-item>
                <a-menu-item @click="_setLevel('DANGER')">
                  DANGER
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <div class="flex mt-2">
        <label class="w-2/5">Sport</label>
        <div class="w-3/5">
          <button :class="{'info mr-1': true, 'primary selected' : sport === s.code}" v-for="s in _sports" @click="_setSport(s)">
            <font-awesome-icon :icon="s.icon" style="color: white"/>
          </button>
        </div>
      </div>
      <div class="flex mt-2">
        <label class="w-2/5">Compétition match</label>
        <div class="w-3/5">
          <button class="info" @click="_openSelectionLeagueModal('current')">{{_currentLeagueName}}</button>
        </div>
      </div>
      <div class="flex mt-2">
        <label class="w-2/5">Compétition match précédent</label>
        <div class="w-3/5">
          <button class="info" @click="_openSelectionLeagueModal('previous')">{{_previousLeagueName}}</button>
        </div>
      </div>
      <div class="flex mt-2">
        <label class="w-2/5">Compétition match suivant</label>
        <div class="w-3/5">
          <button class="info" @click="_openSelectionLeagueModal('next')">{{_nextLeagueName}}</button>
        </div>
      </div>
    </div>

    <hr/>

    <fieldset class="m-1 no-border">
      <legend>Prévisualisation du flag</legend>
      <a-alert class="mx-1 mb-1" :message="_label" :type="_type" show-icon/>
    </fieldset>

    <template #footer>
      <div class="m-2 flex">
        <button class="secondary" @click="_delete" v-if="uid !== null">
          Supprimer le flag
        </button>
        <div class="flex-grow"/>
        <button class="accent" @click="_validate()" :disabled="!_canValidate">
          Valider
        </button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">

</style>