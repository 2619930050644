<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferBookmakerValue from "@/components/common/OfferBookmakerValue.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import {notebookStore} from "@/stores/NotebookStore";
import {gameStore} from "@/stores/Game.store";
import ItemMatchingIndicator from "@/components/common/ItemMatchingIndicator.vue";
import SelectionPaneItemOfferMatch from "@/components/routes/selection/panes/selection/items/SelectionPaneItemOfferMatch.vue";
import Statistic from "@/components/common/Statistic.vue";
import SelectionMatch from "@/components/common/SelectionMatch.vue";

export default {
  name: "GameSelectionStatsWidgetRow",
  components: {
    SelectionMatch, Statistic, SelectionPaneItemOfferMatch,
    ItemMatchingIndicator,
    IndicateurCircle,
    OfferChoiceButton,
    OfferBookmakerValue,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    row: {
      type: Object,
      required: true
    },
    minScore: {
      type: Number
    },
    maxScore: {
      type: Number
    },
    even: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      statsOpened: false
    }
  },

  computed: {
    _overContext() {
      return statsRouteStore().getOverContext
    },
    _rowClasses() {
      if (!this._overContext) return {
        'game-selection-stats-widget-row': true,
        'anonymous': !this.row.teamUID,
        'even': this.even
      }
      return {
        'game-selection-stats-widget-row': true,
        'even': this.even,
        'in-notebook': notebookStore().isIn(this.row.gameUID),
        'over-context-team': this._overContext.teamUID && this._overContext.teamUID === this.row.teamUID,
        'over-context-game': this._overContext.gameUID && this._overContext.gameUID === this.row.gameUID,
        'over-context-league': this._overContext.leagueUID && this._overContext.leagueUID === this.row.leagueUID
      }
    },
    _gameUID() {
      if (!this.row) return null
      return this.row.gameUID
    },
    _game() {
      if (!this._gameUID) return null
      return gameStore().get(this._gameUID)
    },
    _leagueUID() {
      if (!this._game) return null
      return this._game.leagueUID
    },
    _homeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },
    _awayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },
    _homeIsFav(){
      if(!this._game) return false
      if(!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart < -0.199
    },
    _homeIsHyperFav(){
      if(!this._game) return false
      if(!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart < -0.399
    },
    _awayIsFav(){
      if(!this._game) return false
      if(!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart > 0.199
    },
    _awayIsHyperFav(){
      if(!this._game) return false
      if(!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart > 0.399
    },
  },

  methods: {
    _toggleStats() {
      this.statsOpened = !this.statsOpened
    }
  }
}
</script>

<template>
  <div>
    <div :class="_rowClasses">
      <table>
        <tbody>
        <tr>
          <td style="width: 24px" rowspan="3">
            <league-logo :uid="_leagueUID"/>
          </td>
          <td style="width: 24px">
            <team-logo :uid="_homeTeamUID" size="small"/>
          </td>
          <td>
            <team-name :uid="_homeTeamUID"/>
            <img v-if="_homeIsFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" />
            <img v-if="_homeIsHyperFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" />
          </td>
          <td style="width: 90px">
            <item-matching-indicator :matchingScore="row.matchingScore" :min-score="minScore" :max-score="maxScore"/>
          </td>
        </tr>
        <tr>
          <td class="text-right" style="width: 24px">
            <team-logo :uid="_awayTeamUID" size="small"/>
          </td>
          <td class="text-right">
            <team-name :uid="_awayTeamUID"/>
            <img v-if="_awayIsFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" />
            <img v-if="_awayIsHyperFav" src="/images/icons/fire.gif" style="height: 10px; width: 10px; display: inline" />
          </td>
          <td>
            <button class="tiny info filled float-end mr-1 mb-1" style="font-size: 9pt !important;"
                    @click.stop="_toggleStats">Détails
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="statsOpened">
      <fieldset class="fieldset-bloc mt-1">
        <legend>Pourquoi ce match ?</legend>
        <span v-for="(statUIDs,period) in row.statsMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="true"/>
        </span>
        <span v-for="(statUIDs,period) in row.statsNotMatch">
          <statistic :uid="statUID" :period="period" v-for="statUID in statUIDs" :positive="false"/>
        </span>
        <span v-for="(note, selectionUID) in row.selectionsMatch">
          <selection-match :uid="selectionUID" :note="note" :positive="true"/>
        </span>
        <selection-pane-item-offer-match :items="row.offerBookmakerValueCriteria[true]" :positive="true"/>
      </fieldset>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.game-selection-stats-widget-row {
  cursor: pointer;
  display: flex;
  border-top: 1px solid @stroke-color;
  border-left: 8px solid transparent;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 1px solid @stroke-color;
  }

  &.even {
    background-color: @background-color-dark;
  }

  tr, td {
    background: transparent !important;
    text-align: left;
    border: none !important;
    padding: 0;
  }

  &.in-notebook {
    border-left: 8px solid @selected-color;
  }

  &.over-context-league {
    background: fade(@hover-color, 50%);
  }

  &.over-context-game {
    background: fade(@selected-color, 40%);
  }

  &.over-context-team {
    background: fade(@primary-color, 15%);
  }

  &:hover {
    background: fade(@primary-color, 15%);
  }

  .context-data {
    font-size: 8pt;
    color: @text-color-dark;
  }

  .relative-value {
    width: 104px;
    text-align: right;
    padding: 0 @padding-small;
  }

  .absolute-value {
    color: @text-color-dark;
  }

  .odd-value {
    width: 104px;
    text-align: right;
    padding: 0 @padding-small;
  }

  .value-percentage {
    font-size: 10pt;
    margin-right: @padding-regular;

    &.green {
      color: @positive-color;
    }

    &.red {
      color: @negative-color;
    }

  }
}
</style>