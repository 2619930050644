// stores/counter.js
import { defineStore } from 'pinia'

import axiosService from "@/services/axios.service";

export const flagStore = defineStore('flagStore', {
  state: () => {
    return {
      items: null
    }
  },
  actions: {

    createOrUpdate(params, callback) {
      const payload = {
        uid: params.uid,
        label: params.label,
        level: params.level,
        sport: params.sport,
        area: params.area,
        teamUID: params.teamUID,
        leagueUID: params.leagueUID,
        previousGameLeagueUID: params.previousGameLeagueUID,
        nextGameLeagueUID: params.nextGameLeagueUID,
        offerFamilyChoiceFixedUID: params.offerFamilyChoiceFixedUID,
        offerFamilyChoiceArgument: params.offerFamilyChoiceArgument,
        offerFamilyChoiceValueMin: params.offerFamilyChoiceValueMin,
        offerFamilyChoiceValueMax: params.offerFamilyChoiceValueMax,
      }
      console.log('flagStore.createOrUpdate', payload)
      axiosService.post('/flg', payload).then(response => {
        const fs = flagStore();
        let flag = response.data.result;
        fs.add(flag)

        if (callback) {
          callback(flag)
        }
      });
    },

    /**
     * Permet l'initialisation des flags
     * @param callback
     */
    fetchAll(callback = null) {
      if (this.items !== null) {
        if (callback) callback()
        return
      }
      this.items = []

      axiosService.get('/flg').then(response => {
        this.items = this.items.concat(response.data.results)
      }).catch(error => {
        console.error('flagStore.fetchAll', error)
      }).finally(() => {
        if (callback) callback()
      })
    },

    add(flag) {
      if (!this.items) {
        this.items = []
      }
      // on retire le flag s'il existe déjà
      const newValue = this.items.filter(item => item.uid !== flag.uid)
      newValue.push(flag)
      this.items = newValue
    },

    addAll(flags) {
      flags.forEach(flag => {
        this.add(flag)
      })
    },

    delete(flagUID, callback) {
      axiosService.delete('/flg/' + flagUID)
        .then(response => {
          this.items = this.items.filter(item => item.uid !== flagUID)

          if (callback) {
            callback()
          }
        })
        .catch(error => {
          console.error('flagStore.deleteFlag', error)
        })
    },
  },
  getters: {
    get: (state) => (uid) => {

      if (uid == null) {
        return null
      }

      if (state.items === null) {
        return null
      }

      const uidInt = parseInt(uid)

      let item = state.items.find(item => item.uid === uidInt);
      if (item) {
        return item
      }

      return null
    },

    getAll: (state) => {
      if (state.items === null) {
        console.warn("flagStore.get", "items is null")
        return []
      }

      return state.items
    },

    matches: (state) => (params) => {
      if (state.items === null) {
        return []
      }
      let results = [...state.items]
      results = results.filter(item => !item.sport || item.sport === params.sport)
      results = results.filter(item => !item.area || item.area === params.area)
      results = results.filter(item => !item.teamUID || item.teamUID === params.teamUID)
      results = results.filter(item => !item.leagueUID || item.leagueUID === params.leagueUID)
      results = results.filter(item => !item.previousGameLeagueUID || item.previousGameLeagueUID === params.previousGameLeagueUID)
      results = results.filter(item => !item.nextGameLeagueUID || item.nextGameLeagueUID === params.nextGameLeagueUID)

      return results
    },
  }
})