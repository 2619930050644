<script>

export default {
  name: "ExpectedWinCalculatorItem",
  data() {
    return {
      results: null,

      form: {
        odd: 1.10,
        target: 1.0
      }
    }
  },
  computed: {
    _stake() {
      if (!this.form.odd || !this.form.target) return 0
      // If I want to win 1€ with a 1.10 odd, I need to bet 10€
      return (this.form.target / (this.form.odd - 1.0)).toFixed(2)
    },
  },
  methods: {

  }
}
</script>

<template>
  <table>
    <tbody>
    <tr>
      <th style="width: 100px" class="text-right">Côte</th>
      <td colspan="2" class="text-right">
        <input type="number" v-model="form.odd" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th style="width: 100px" class="text-right">Gain souhaité</th>
      <td colspan="2" class="text-right">
        <input type="number" v-model="form.target" step='0.01' placeholder="Côte" style="text-align: end; width: 64px">
      </td>
    </tr>
    <tr>
      <th style="width: 100px" class="text-right">Mise à engager</th>
      <td style="width: 64px" class="text-right">
        <input disabled :placeholder="_stake" style="text-align: end; width: 64px">
      </td>
    </tr>
    </tbody>
  </table>
</template>

<style scoped lang="less">

</style>