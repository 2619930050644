<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import {statStore} from "@/stores/Statistic.store";
import {preferencesStore} from "@/stores/Preferences.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";

export default {
  name: "SelectionPaneItem.UnderOver.Tab",
  components: {YoutubeButton, StatisticValueButton, IndicateurCircle, LeagueLogo, TeamName, TeamLogo, GameResultCircle},

  props: {
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      filters: {
        period: 'CURRENT_SEASON',
        moment: 'FT',
        type: 'UNDER',
        value: null
      },

      // proba calculée
      proba: null,


      periods: ['CURRENT_SEASON', 'LAST_5_GAMES', 'LAST_10_GAMES'],

      data: {
        'under': null,
        'over': null
      },

      isSettingsOpened: false
    }
  },

  mounted() {

    // On va chercher la préférence du type
    let period = preferencesStore().getFirstValue('SELECTION_UNDER_OVER_TAB', 'PERIOD');
    if (period) {
      this.filters.period = period
    } else {
      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'PERIOD', [this.filters.period])
    }

    let moment = preferencesStore().getFirstValue('SELECTION_UNDER_OVER_TAB', 'MOMENT_' + this._sport);
    if (moment) {
      this.filters.moment = moment
    } else {
      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    }

    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('SELECTION_UNDER_OVER_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'TYPE', [this.filters.type])
    }

    // On va chercher la préférence du type
    let value = preferencesStore().getFirstValue('SELECTION_UNDER_OVER_TAB', 'VALUE_' + this._sport);
    if (type) {
      this.filters.value = value
    } else {
      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'VALUE_' + this._sport, [this.filters.value])
    }

    this._loadData()
  },

  computed: {
    _game() {
      return gameStore().get(this.gameUid)
    },
    _gameHomeTeamUID() {
      if (!this._game) return null
      return this._game.homeTeamUID
    },
    _gameAwayTeamUID() {
      if (!this._game) return null
      return this._game.awayTeamUID
    },
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },

    _momentLabel() {
      return this.filters.moment
    },
    _key() {
      return this.filters.type.toLowerCase() + '_' + this.filters.moment
    },
    _data() {
      return this.data[this._key]
    },
    _valuesChoices() {
      const results = []
      if (!this._data) {
        return results
      }

      if (!this._data.homeAll) {
        return results
      }

      Object.keys(this._data.homeAll).forEach(key => {
        results.push({
          code: key,
          label: key
        })
      })
      return results
    },
    _moment() {
      switch (this._sport) {
        case 'FOOTBALL':
          return ['FT', 'P1', 'P2']
        case 'BASKETBALL':
          return ['FT', 'P1', 'P2', 'P3', 'P4']
        case 'HANDBALL':
          return ['FT', 'P1', 'P2']
        case 'RUGBY':
          return ['FT', 'P1', 'P2']
        case 'HOCKEY':
          return ['FT', 'P1', 'P2', 'P3']
      }
    }
  },

  methods: {
    _switchPeriod(value) {
      this.filters.period = value

      this.calculateProba()

      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'PERIOD', [this.filters.period])
    },
    _periodLabel(period) {
      switch (period) {
        case 'CURRENT_SEASON':
          return 'Saison'
        case 'LAST_5_GAMES':
          return '5 derniers matchs'
        case 'LAST_10_GAMES':
          return '10 derniers matchs'
      }
    },
    _switchMoment(value) {
      this.filters.moment = value

      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'MOMENT_' + this._sport, [this.filters.moment])

      if (!this.data[this._key]) {
        this._loadData()
        return
      }

      this.calculateProba()
    },
    _setType(type) {
      this.filters.type = type
      this._loadData()
      this.calculateProba()
    },
    _setValue(value) {
      this.filters.value = value
      preferencesStore().save('SELECTION_UNDER_OVER_TAB', 'VALUE_' + this._sport, [this.filters.value])
      this.calculateProba()
    },
    _loadData() {
      if (this._data) {
        return  // already loaded
      }

      const payload = {
        uid: this.gameUid,
        moment: this.filters.moment
      }
      let type = this.filters.type.toLowerCase();
      axiosService.put('/dta/game/basic-data/' + type, payload).then(response => {

        if (!response.data) {
          return  // no data
        }

        this.data[this._key] = response.data
        if (this.filters.value === null) {
          if (this.data[this._key].homeAll) {
            this.filters.value = Object.keys(this.data[this._key].homeAll)[0]
          } else {
            this.filters.value = '1.5'
          }
        }

        this.calculateProba()
      })
    },
    calculateProba() {
      if (!this._data) {
        return
      }

      const homeAll = statStore().get(this._data.homeAll[this.filters.value])
      const awayAll = statStore().get(this._data.awayAll[this.filters.value])
      const homeSide = statStore().get(this._data.homeSide[this.filters.value])
      const awaySide = statStore().get(this._data.awaySide[this.filters.value])

      if (!homeAll || !awayAll || !homeSide || !awaySide) {
        setTimeout(() => {
          this.calculateProba()
        }, 500)
        return
      }

      const homeAllValue = statStore().getValue(homeAll, this.filters.period, false)
      const awayAllValue = statStore().getValue(awayAll, this.filters.period, false)
      const homeSideValue = statStore().getValue(homeSide, this.filters.period, false)
      const awaySideValue = statStore().getValue(awaySide, this.filters.period, false)

      // If one of the value is null or NaN, proba = '-.-'
      if (homeAllValue === null || awayAllValue === null || homeSideValue === null || awaySideValue === null) {
        this.proba = '--.-%'
        return
      }
      if (isNaN(homeAllValue) || isNaN(awayAllValue) || isNaN(homeSideValue) || isNaN(awaySideValue)) {
        this.proba = '--.-%'
        return
      }

      const all = (homeAllValue + awayAllValue) / 2.0
      const side = (homeSideValue + awaySideValue) / 2.0
      this.proba = ((all + side) * 100.0 / 2.0).toFixed(0) + '%'
    },
    _switchSettings() {
      this.isSettingsOpened = !this.isSettingsOpened
    }
  }
}
</script>

<template>
  <div>
    <button class="info small" style="width:30px; position: absolute; top: 4px; right: 8px; z-index: 100" @click="_switchSettings">
      <font-awesome-icon icon="fa-solid fa-sliders" v-if="!isSettingsOpened"/>
      <font-awesome-icon icon="fa-solid fa-times" v-else/>
    </button>
    <div class="card" v-if="isSettingsOpened">
      <div class="card-header">
        Préférences
      </div>
      <div class="card-content text-left pb-2">
        <div>
          <h5>Plutôt under ou over ?</h5>
          <div class="flex mt-2 pl-1">
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.type === 'UNDER'}"
                    @click="_setType('UNDER')">Under
            </button>
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.type === 'OVER'}"
                    @click="_setType('OVER')">Over
            </button>
          </div>
        </div>
        <div>
          <h5>Sur quelle période ?</h5>
          <div class="flex mt-2 pl-1">
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.period === p}" v-for="p in periods"
                    @click="_switchPeriod(p)">{{ _periodLabel(p) }}
            </button>
          </div>
        </div>
        <div>
          <h5>A quel moment du match ?</h5>
          <div class="flex mt-2 pl-1">
            <button :class="{'small translucide ml-1' : true, 'accent' : filters.moment === m}" v-for="m in _moment"
                    @click="_switchMoment(m)">{{ m }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        Taux de réalisation
      </div>
      <div class="card-content">
        <div style="overflow: auto">
          <table v-if="_data">
            <tbody>
            <tr>
              <th style="min-width: 70px">
                {{ _periodLabel(filters.period) }}
              </th>
              <th v-for="item in Object.keys(_data.homeAll)" @click="_setValue(item)">
                {{ filters.type === 'UNDER' ? '-' : '+' }}{{ item }}
              </th>
            </tr>
            <tr>
              <td>
                <team-logo :uid="_gameHomeTeamUID" size="small"/>
              </td>
              <td v-for="item in Object.keys(_data.homeAll)">
                <statistic-value-button :uid="_data.homeAll[item]" :period="this.filters.period"
                                        :type="item === this.filters.value ? 'accent' : ''"/>
              </td>
            </tr>
            <tr>
              <td>
                <team-logo :uid="_gameAwayTeamUID" size="small"/>
              </td>
              <td v-for="item in Object.keys(_data.homeAll)">
                <statistic-value-button :uid="_data.awayAll[item]" :period="this.filters.period"
                                        :type="item === this.filters.value ? 'accent' : ''"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-header">
        Taux de réalisation - Domicile/Extérieur
      </div>
      <div class="card-content">
        <div style="overflow: auto">
          <table v-if="_data">
            <tbody>
            <tr>
              <th style="min-width: 70px">
                D/E
              </th>
              <th v-for="item in Object.keys(_data.homeAll)" @click="_setValue(item)">
                {{ filters.type === 'UNDER' ? '-' : '+' }}{{ item }}
              </th>
            </tr>
            <tr>
              <td>
                <team-logo :uid="_game.homeTeamUID" size="small"/>
              </td>
              <td v-for="item in Object.keys(_data.homeAll)">
                <statistic-value-button :uid="_data.homeSide[item]" :period="this.filters.period"
                                        :type="item === this.filters.value ? 'accent' : ''"/>
              </td>
            </tr>
            <tr>
              <td>
                <team-logo :uid="_game.awayTeamUID" size="small"/>
              </td>
              <td v-for="item in Object.keys(_data.homeAll)">
                <statistic-value-button :uid="_data.awaySide[item]" :period="this.filters.period"
                                        :type="item === this.filters.value ? 'accent' : ''"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr class="mt-2"/>
    <div class="flex p-2">
      <p class="pr-2 m-0 flex-grow">📊 Accède ici aux stats Over/Under des deux équipes ! 🔥 Analyse-les sur différentes périodes et moments clés du match pour affiner tes paris ! 🎯</p>
      <youtube-button video-id="dxVljOmZ1x4"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
      height: 24px;

    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>