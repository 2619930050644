<script>
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import axiosService from "@/services/axios.service";
import DateTime from "@/components/common/DateTime.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "UserReferringPreferences",
  components: {DateTime},
  data() {
    return {
      bonuses: null
    }
  },

  mounted() {
    axiosService.get('/fin/referral-bonus').then(response => {
      this.bonuses = response.data.results
    })
  },

  computed: {
    _loggedUser() {
      return contextStore().loggedUser;
    },
  },

  methods: {


    _openReferringCodeFromModal() {
      modalStore().openModal({uid: 'userReferringCodeFormModal'})
    }
  }
}
</script>

<template>
  <div class="mx-2">
    <div v-if="_loggedUser.referralCode">
      <fieldset class="mt-2">
        <legend>Lien de parrainage</legend>
        <div class="px-2 py-1 flex">
          <label class="flex-grow mr-2">https://docto.bet?p={{ _loggedUser.referralCode }}</label>
        </div>
        <hr/>
        <label class="p-2" style="font-size: 10pt">
          Ton lien a été cliqué {{ _loggedUser.codePromoClickCount }} fois.
        </label>
        <a-alert class="m-2"
                 message="Partage ce lien à un maximum de personne pour toucher des commissions de parrainage."
                 type="info" show-icon/>
      </fieldset>

      <fieldset class="my-2">
        <legend>Bonus de parrainages</legend>

        <a-alert class="m-2"
                 v-if="!bonuses"
                 message="Chargement..."
                 type="info" show-icon/>

        <table>
          <tbody>
          <tr>
            <th class="text-left">Date</th>
            <th class="text-left">Filleul</th>
            <th colspan="2" class="text-left">Montant<sup>*</sup></th>
          </tr>
          <tr v-if="bonuses" v-for="bonus in bonuses">
            <td class="text-left">
              <date-time :value="bonus.date"/>
            </td>
            <td class="text-left">{{ bonus.userName }}</td>
            <td class="text-left">{{ bonus.amount }}€</td>
            <td class="text-left">{{ bonus.taux }}%</td>
          </tr>
          </tbody>
        </table>
        <a-alert class="m-2"
                 v-if="bonuses && bonuses.length === 0"
                 message="Tu n'as pas encore reçu de bonus de parrainage. Partage ton lien de parrainage pour en obtenir."
                 type="info" show-icon/>
      </fieldset>
    </div>

    <!-- Block if the user not has referral code -->
    <div v-else>
      <fieldset class="my-2">
        <legend>Lien de parrainage</legend>
        <div class="px-2 py-1 flex">
          <button class="flex-grow small primary" @click="_openReferringCodeFromModal()">Créer ton lien de parrainage</button>
        </div>
      </fieldset>
    </div>
  </div>
</template>
