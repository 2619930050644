<script>

import {offerStore} from "@/stores/Offer.store";
import {contextStore} from "@/stores/Context.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {modalStore} from "../../stores/Modal.store";

export default {
  name: "OfferChoiceButtonNew",
  props: {
    offerFamily: {
      required: false
    },
    choice: {
      required: false
    },
    argument: {
      required: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _offerStore() {
      return offerStore()
    },
    _contextStore() {
      return contextStore()
    },
    _choiceOffers() {
      if (!this.choice) return null
      return this.choice.offers
    },
    _offerValue() {
      if (!this._choiceOffers) return null
      let selectedBookmakerOffer = this._offerStore.getOfferByBookmakerUID(this._choiceOffers, this._contextStore.bookmakerUID);
      if (selectedBookmakerOffer) {
        return selectedBookmakerOffer
      }
      return this._choiceOffers[0]
    },
    _offerBookmakerUID() {
      if (!this._offerValue) return null
      return this._offerValue.bookmakerUid
    },
    _offerValueValue() {
      if (this.loading) return ''
      return this._offerStore.getOfferValue(this._offerValue)
    },
    _offerValueLabel() {
      if (this.loading) return ''
      return this._offerStore.getOfferFamilyChoiceLabel(this.choice, this.argument, true)
    },
    _offerValueProbability() {
      if (this.loading) return 0
      return this._offerStore.getOfferProbability(this._offerValue)
    },
    _offerValueProbabilityLabel() {
      if (this.loading) return '0%'
      if (!this._offerValueProbability) return '-'
      return this._offerValueProbability > 0 ? (this._offerValueProbability * 100.0).toFixed(0) + '%' : '-'
    },
    _buildCssClasses() {
      return {
        'offer-choice-button': true,
        'translucide': true,
        'shimmer': this.loading,
        'selected': this.selected
      }
    },
    _buildCssStyle() {
      return {
        width: '100%'
      }
    },
    _bookmaker() {
      if (!this._offerBookmakerUID) return null
      return bookmakerStore().get(this._offerBookmakerUID)
    },
    _tooltipTitle() {
      let baseLabel = this.offerFamily.label + " / " + this.choice.label;
      if (!this._bookmaker) return baseLabel
      let bookmakerName = this._bookmaker.name
      return baseLabel + " @ " + bookmakerName
    },

    _histogramProbabilityStyles() {
      if (!this._offerValueProbability) return {}

      let color = '#49aa72'
      if (this._offerValueProbability < 0.50) {
        color = '#f29f00'
      }

      return {
        width: (this._offerValueProbability * 100.0) + '%',
        backgroundColor: color
      }
    }
  },
  methods: {
    _onClick(event) {
      event.stopPropagation()
      if (!this._offerValue) return
      modalStore().openModal({
        uid: 'offerModal',
        payload: {
          offerUID: this._offerValue.offerUID,
          obv: this._offerValue
        }
      })
    }
  },
}
</script>

<template>

  <div class="offer-choice-button-wrapper" v-if="_offerValue || loading">
    <button :class="_buildCssClasses" :style="_buildCssStyle" @click.prevent="_onClick">
      <label class="px-1">
        {{ _offerValueLabel }}
      </label>
      <label class="value">
        {{ _offerValueValue }}
      </label>
    </button>
    <div class="histogram-wrapper">
      <div class="probability">{{ _offerValueProbabilityLabel }}</div>
      <div class="histogram flex-grow">
        <div class="histogram-probability" :style="_histogramProbabilityStyles"/>
        <div class="histogram-filler flex-grow"/>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

.shimmer {
  width: 100%;
  height: 100px;
  background: linear-gradient(to left, #f6f7f800 0%, #edeef111 20%, #f6f7f822 40%, #f6f7f833 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.offer-choice-button-wrapper {
  display: inline-block;

  .offer-choice-button {
    label {
      min-height: 12px;
      max-height: 12px;
      line-height: 12px !important;
      font-size: 10px;

      &.value {
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

  .histogram-wrapper {
    display: flex;
    line-height: 19px;
    align-items: center;

    .probability {
      font-size: 8pt;
      color: @text-color;
      margin-right: 8px;
    }

    .histogram {
      height: 4px;
      background: @background-color;
      border-radius: 2px;
      position: relative;
      margin-top: 2px;
      display: flex;

      .histogram-probability {
        height: 100%;
        border-radius: 2px;
      }

      .histogram-filler {
        height: 100%;
        background: @stroke-color;
        border-radius: 2px;
      }
    }
  }
}

.offer-choice-button {
  height: 36px;
  padding: 0 !important;

  &.selected {
    border: 1px solid #f97f28;
    background: linear-gradient(135deg, #f97f28aa 0%, #f97f2899 33%, #fea32866 66%, #fea32888 100%) !important;
    color: @text-color;
  }

  label {
    display: block;
    min-height: 12px;
    max-height: 12px;
    line-height: 12px !important;

    &.valuable-indicator {
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @negative-color !important;

      &.positive {
        color: @positive-color !important;
      }
    }

    &.value {
      font-size: 13px;
      font-weight: bold;
    }

    &.probability {
      height: 10px;
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @text-color;
    }
  }
}


</style>