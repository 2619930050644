<script>

import SelectionPaneItem from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.vue";
import GameRow from "@/components/common/GameRow.vue";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import SelectionPaneItemLineGameData from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.LineGameData.vue";

export default {
  name: "ProfilerFilterPaneHeader",
  components: {SelectionPaneItemLineGameData, SelectionPaneItemLine1, GameRow, SelectionPaneItem},

  data() {
    return {

    }
  },
  computed: {
    _gameUID() {
      return 309471
    },
    _selectionActiveName() {
      return 'Selection active'
    }
  },
  methods: {
    _onClickSelection() {
      console.log('click')
    }
  }
}
</script>

<template>
  <div id="profiler-filter-pane-header">
    <header class="px-1">
      <button class="accent w-full" @click="_onClickSelection">
        {{ _selectionActiveName }}
      </button>
    </header>
    <div class="game-card mx-1">
      <selection-pane-item-line1 :game-uid="_gameUID" @click="_onClickSelection"/>
    </div>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";
.game-card {
  background: rgb(255,255,255);
  background: linear-gradient(135deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.08) 20%, rgba(115,115,115,0.05) 33%, rgba(115,115,115,0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;

  &:hover {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 20%) 0%, fade(@title-color, 15%) 33%, rgba(115, 115, 115, 0.05) 66%, rgba(115, 115, 115, 0.10) 100%);
  }

  .line0 {
    height: 20px;
  }

}
</style>