<script>
import DateTime from "@/components/common/DateTime.vue";
import {gameStore} from "@/stores/Game.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import GameHour from "@/components/common/GameHour.vue";
import Hour from "@/components/common/Hour.vue";

export default {
  name: "GameDate",
  components: {Hour, GameHour, FontAwesomeIcon, DateTime},
  props: {
    uid: {
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'medium' // small, medium accepeted
    },
    showHour: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _game() {
      if (!this.uid) return null
      return gameStore().get(this.uid)
    },
    _getValue() {
      if (!this._game) return null
      return gameStore().getGameDate(this._game)
    },
    _iconColor() {
      if (!this._game) return 'grey'
      // If game os today, return green
      const date = new Date(this._game.date)
      const today = new Date();
      const isToday = date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear();

      if (isToday) return '#6DFFC2'
      return '#464749'
    },
    _iconStyle() {
      return {
        color: this._iconColor
      }
    }
  },
}
</script>

<template>
  <span>
    <font-awesome-icon v-if="showIcon" icon="fa-solid fa-calendar-day" :style="_iconStyle" size="xs"/>
    <date-time :value="_getValue" :year-if-different="true" :size="size"/>
    <hour :value="_getValue" :size="size" v-if="showHour"/>
  </span>
</template>
