// stores/counter.js
import { defineStore } from 'pinia'
import { preferencesStore } from "@/stores/Preferences.store";
import { perspectiveStore } from "@/stores/Perspective.store";
import { contextStore } from "@/stores/Context.store";

export const bankrollRouteStore = defineStore('bankrollRouteStore', {
  state: () => {
    return {
      // Perspectives
      selectedPerspectiveUID: null
    }
  },
  actions: {
    init() {
      // Rien
    },
    selectFirstPerspective() {
      // On prend la premiere perspective
      const ps = perspectiveStore()
      const allPerspectives = ps.getBySportAndType(contextStore().getSport, 'BANKROLL')
      if (allPerspectives.length > 0) {
        const bk = bankrollRouteStore()
        bk.setSelectedPerspectiveUID(allPerspectives[0].uid)
      }
    },
    updateWidgets() {
      window.emitter.emit('bankroll-route:widgets-need-to-refresh')
    },
    setSelectedPerspectiveUID(selectedPerspectiveUID) {
      this.selectedPerspectiveUID = parseInt(selectedPerspectiveUID)
      preferencesStore().save('DEFAULT_PERSPECTIVE', 'BANKROLL_PERSPECTIVE', [this.selectedPerspectiveUID])
    }
  },
  getters: {
    getSelectedPerspectiveUID: (state) => () => {
      if(state.selectedPerspectiveUID === null) {
        const prefUID = preferencesStore().getFirstValue('DEFAULT_PERSPECTIVE', 'BANKROLL_PERSPECTIVE')
        if(prefUID !== null) {
          bankrollRouteStore().setSelectedPerspectiveUID(prefUID)
        }else{
          bankrollRouteStore().selectFirstPerspective()
        }
      }
      return state.selectedPerspectiveUID
    }
  }
})