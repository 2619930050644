// stores/counter.js
import { defineStore } from 'pinia'
import { contextStore } from "@/stores/Context.store";
import axiosService from "../services/axios.service";
import { bankrollPaneStore } from "../components/routes/bankroll/panes/bankroll/BankrollPane.store";
import { monitoringStore } from "@/stores/Monitoring.store";
import { selectionStore } from "@/stores/Selection.store";

export const bankrollStore = defineStore('bankrollStore', {
  state: () => {
    return {
      loadingUIDs: [],
      fetchBankrollByUIDTimeout: null,
      items: []
    }
  },
  actions: {

    create(name, initialCapital) {
      const payload = {
        initialCapital: initialCapital,
        name: name
      }
      axiosService.post('/bkl', payload).then(response => {
        const bks = bankrollStore();
        let bankroll = response.data.result;
        bks.add(bankroll)
      });
    },

    reset(uid) {
      axiosService.put('/bkl/' + uid + '/reset').then(_ => {
        bankrollPaneStore().loadBankroll()
        bankrollStore().fetchBankrollByUID(uid)
        window.emitter.emit('bankroll-pane:bankroll-uid:change', uid)
      });
    },

    async exportXlsx(uid) {
      const payload = {
        "page": 0,
        "bankrollUID": uid
      }

      const response = await axiosService.getInstance().put('/bkl/' + uid + '/ticket/export-xls', payload, {
        responseType: 'blob', // Ensure response is treated as binary data
        headers: {
          'Content-Type': 'application/json' // Set JSON content type for request body
        }
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `bankroll.xls`;
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    },

    addTicket(payload) {
      axiosService.post('/bkl/ticket', payload).then(response => {
        bankrollPaneStore().lastTicketUID = response.data.result.uid
        setTimeout(() => {
          bankrollPaneStore().loadBankroll()
        }, 250)
      });
    },

    deleteTicket(uid) {
      axiosService.delete('/bkl/ticket/' + uid).then(_ => {
        bankrollPaneStore().loadBankroll()
      });
    },

    updateTicketStatus(bkUID, uid, status) {
      axiosService.put('/bkl/ticket/' + uid + '/status', { status: status }).then(_ => {
        bankrollPaneStore().loadBankroll()
        bankrollStore().fetchBankrollByUID(bkUID)
      });
    },

    addItems(items, callback) {
      let newItems = this.items.concat(items)

      // remove duplicates (compare by uid)
      newItems = newItems.filter((item, index, self) => self.findIndex(t => t.uid === item.uid) === index)

      const allUIDs = newItems.map(item => item.uid)
      this.loadingUIDs = this.loadingUIDs.filter(uid => allUIDs.indexOf(uid) < 0)

      this.items = newItems

      if (callback) callback()
    },

    /**
     * Permet l'initialisation des bankrolls
     * @param callback
     */
    fetchAll(callback = null) {
      axiosService.get('/bkl').then(response => {
        this.addItems(response.data.results)
      }).catch(error => {
        console.error('bankrollStore.fetchAll', error)
      }).finally(() => {
        if (callback) callback()
      })
    },

    fetchBankrollByUID(uid) {
      if (!uid) return

      // Si l'uid est déjà dans le store, on ne le charge pas
      if (this.items.find(item => item.uid === uid)) {
        return
      }

      if (this.loadingUIDs.indexOf(uid) < 0) {
        this.loadingUIDs.push(uid)
      }

      // Si on a 100 équipes à charger, on le fait tout de suite
      const timeout = this.loadingUIDs.length < 100 ? 250 : 0

      if (this.fetchBankrollByUIDTimeout) clearTimeout(this.fetchBankrollByUIDTimeout)
      this.fetchBankrollByUIDTimeout = setTimeout(() => {
        axiosService.get('/bkl/' + this.loadingUIDs.join(',')).then(response => {
          this.addItems(response.data.results)

          if (response.data.monitorings) {
            monitoringStore().addAll(response.data.monitorings)
          }
          if (response.data.selections) {
            selectionStore().addAll(response.data.selections)
          }
        });

      }, timeout)
    },

    add(selection) {
      this.items.push(selection)
    },

    delete(uid) {
      axiosService.delete('/bkl/' + uid).then(_ => {
        this.items = this.items.filter(item => item.uid !== uid)
      }).catch(error => {
        console.error('bankrollStore.delete', error)
      })
    },

    update(uid, name) {
      const payload = {
        name: name
      }
      axiosService.put('/bkl/' + uid, payload).then(_ => {
        const item = this.items.find(item => item.uid === uid)
        item.name = name
      }).catch(error => {
        console.error('bankrollStore.update', error)
      })
    }
  },
  getters: {
    getAll: (state) => {
      return state.items
    },
    get: (state) => (uid) => {

      if (state.items === null) {
        return null
      }

      let item = state.items.find(item => item && (item.uid === uid));
      if (item) {
        return item
      }

      // Si la team n'est pas dans le store, on le charge
      const ts = bankrollStore()
      ts.fetchBankrollByUID(uid)

      return null
    },

    getByUIDs: (state) => (uids) => {

      if (!uids || uids.length === 0) {
        return []
      }

      if (state.items === null) {
        return []
      }

      return state.items.filter(item => uids.indexOf(item.uid) >= 0)
    },

    getActiveBankrollBalance: (state) => (uid) => {
      const bs = bankrollStore()
      const bankroll = bs.get(uid)
      if (bankroll === null) {
        return null
      }
      return bankroll.balance
    },

    getUserBankrolls: (state) => {
      if (state.items === null) {
        console.error("bankrollStore.get", "items is null")
        return null
      }

      const cs = contextStore()
      if (!cs.loggedUser) {
        return []
      }

      return state.items.filter(item => item && (item.userUID === cs.loggedUser.uid))
    },

    isYours: (state) => (uid) => {
      const bankroll = state.items.find(item => item && (item.uid === uid))
      if (!bankroll) {
        return false
      }
      const cs = contextStore()
      if (!cs.loggedUser) {
        return false
      }
      return bankroll.userUID === cs.loggedUser.uid;
    }
  }
})