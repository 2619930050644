<script>
import {GridItem, GridLayout} from 'vue3-grid-layout-next'
import {perspectiveStore} from "@/stores/Perspective.store";


import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Widgets
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import FrequencyStatsWidget from "@/components/routes/stats/widgets/FrequencyStats.Widget.vue";
import RawStatsWidget from "@/components/routes/stats/widgets/RawStats.Widget.vue";
import SerieStatsWidget from "@/components/routes/stats/widgets/SerieStats.Widget.vue";
import GameSelectionStatsWidget from "@/components/routes/stats/widgets/GameSelection.Stats.Widget.vue";

export default {
  name: "StatsDataPerspective",

  props: {
    onScroll: {
      type: Function,
      default: () => {}
    }
  },

  components: {
    YoutubeButton,
    FontAwesomeIcon,
    GridItem, GridLayout,

    // widgets
    FrequencyStatsWidget,
    RawStatsWidget,
    SerieStatsWidget,
    GameSelectionStatsWidget,
  },
  computed: {
    _selectedPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _hasWidgets() {
      if (!this._items) return false
      return this._items.length > 0
    },
    _items() {
      if (!this._selectedPerspectiveUID) return []
      return perspectiveStore().getWidgetsByPerspectiveUID(this._selectedPerspectiveUID)
    },
  },
  methods: {
    _itemType(item) {
      if (!item) return null

      // Fix temporaire pour changement de nom
      if (item.type === 'TeamEventStatsWidget') {
        return 'FrequencyStatsWidget'
      }

      return item.type
    },
  }
}
</script>

<template>
  <div id="stats-data-content-wrapper" @scroll="onScroll">
    <component :is="_itemType(item)" :item="item" v-for="item in _items" v-if="_hasWidgets"/>
    <div id="empty-perspective" v-else-if="!_hasWidgets">
      <h1>Comment commencer ?</h1>
      <p>Choisis parmi une vaste sélection de statistiques et commence à construire tes analyses personnalisées.</p>
      <youtube-button video-id="MDMyT0NFZ8A" class="ml-2 large mt-5" label="Comment ça marche ?"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";


#stats-data-content-wrapper {
  height: calc(100vh - @toolbar-large);
  gap: @padding-small; /* Adjust the gap as needed */
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  padding: @padding-regular @padding-small 54px;
  border-radius: @radius-regular;

  .widget {
    flex: 1 1 350px; /* permet aux div de croître et se contracter, avec une base de 350px */
    box-sizing: border-box;
    width: 350px;
    max-width: calc(100%);
  }
}

#empty-perspective {
  margin: auto 100px;
  width: calc(100vw - 400px);
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  padding: 64px;
  background: rgba(0, 0, 0, 0.15);
  h1, p {
    max-width: 450px;
    text-align: center;
    font-size: 2em !important;
    width: 100%;
    margin: auto;
  }
  p{
    font-size: 1.5em !important;
    color: white;
    text-align: center;
    margin-top: 24px !important;
  }
}

&.mobile{
  #stats-data-content-wrapper {
    padding-top: 100px;
  }

  #empty-perspective {
    width: 100%;
    margin: 0;
    height: auto;
    border: 1px solid @stroke-color;
    border-radius: @radius-regular;
    background: rgba(0, 0, 0, 0.15);
    h1, p {
      text-align: center;
      font-size: 2em !important;
      width: 100%;
      margin: auto;
    }
    p{
      font-size: 1.5em !important;
      color: white;
      text-align: center;
      margin-top: 24px !important;
    }
  }
}

</style>