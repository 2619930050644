<script>

export default {
  name: "Flag",
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    _type() {
      switch(this.item.level) {
        case 'SUCCESS':
          return 'success';
        case 'WARNING':
          return 'warning';
        case 'DANGER':
          return 'error';
        case 'INFO':
        default:
          return 'info';
      }
    },
    _label() {
      return this.item.label;
    }
  }
}
</script>

<template>
  <a-alert :message="_label" :type="_type" show-icon />
</template>