<script>

import {preferencesStore} from "@/stores/Preferences.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {flagStore} from "@/stores/Flag.store";
import Flag from "@/components/common/Flag.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "UserFlagsPreferences",
  components: {Flag},
  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {
    _flags() {
      return flagStore().getAll
    },
    _flagsGroupBySport() {
      if(!this._flags) return []
      return this._flags.reduce((acc, flag) => {
        if(!acc[flag.sport]) acc[flag.sport] = []
        acc[flag.sport].push(flag)
        return acc
      }, {})
    }
  },
  methods: {
    _editFlag(flag){
      modalStore().openModal({
        uid: 'flagSettingModal',
        payload: {
          flag: flag
        }
      })
    }
  }
}
</script>

<template>
  <div class="mx-2 pb-2">
    <fieldset class="mt-2">
      <legend>C'est quoi ?</legend>
      <p class="px-2">
        Les flags sont des indicateurs que tu peux définir, ils te permettent de mettre en avant des caractéristiques que tu recherches ou que tu veux éviter.
      </p>
    </fieldset>
    <div class="mt-2" >
      <fieldset v-for="(flags, sport) in _flagsGroupBySport" :key="sport">
        <legend>{{sport}}</legend>
        <div class="d-flex flex-wrap p-1">
          <flag :item="f" v-for="f in flags" class="mb-1 cursor-pointer" @click="_editFlag(f)"/>
        </div>
      </fieldset>
      <a-alert v-if="_flags.length === 0" message="Aucun flag défini" type="info" show-icon/>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>