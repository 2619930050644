<script>
import axiosService from "@/services/axios.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {offerStore} from "@/stores/Offer.store";
import {message} from "ant-design-vue";
import OfferChoiceButton from "../../../../../common/OfferChoiceButton.vue";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "OddsWidget",
  components: {
    FontAwesomeIcon,
    OfferChoiceButton,
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      // Les données récupérés du server
      data: null,
      parameters: null
    }
  },
  mounted() {
    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widgetUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._widgetUID) return null
      return perspectiveStore().getWidgetByUID(this._widgetUID)
    },
    _sportOfferFamilies() {
      let offerFamilyDefinitionsBySport = offerStore().getOfferFamilyDefinitionsBySport(perspectiveStore().getCurrentSport());
      if (!offerFamilyDefinitionsBySport) return []
      return offerFamilyDefinitionsBySport.items
    },
    _sport () {
      return contextStore().getSport
    },
    _sportOfferFamilyChoices() {
      const results = []
      if (!this._sportOfferFamilies) {
        return results
      }

      this._sportOfferFamilies.forEach(f => {

        // On liste les arguments possibles
        let args = []
        f.choices.forEach(c => {
          if (null !== f.arguments && f.arguments[this._sport]) {
            f.arguments[this._sport].forEach(a => {
              args.push(a)
            })
          }
        })
        // remove duplicates
        args = args.filter((v, i, a) => a.indexOf(v) === i);
        // remove null
        args = args.filter(a => a !== null)
        if (args.length === 0) {
          args = null
        }

        results.push({
          label: offerStore().getOfferFamilyDefinitionLabel(f.code),
          value: f.fixedUID,
          args: args
        })
      })
      return results
    },

    _gameUID() {
      return contextStore().gameUID
    },

    _offerFamilyFixedUID() {
      const result = perspectiveStore().getParameterValue(this.item.uid, 'offerFamilyFixedUID')
      if (!result) {
        if (!this._sportOfferFamilyChoices || this._sportOfferFamilyChoices.length === 0) {
          return null
        }
        return this._sportOfferFamilyChoices[0].value
      }
      return result
    },

    _argument() {
      if(!this._sportOfferFamilyChoices || this._sportOfferFamilyChoices.length === 0) return null
      const result = perspectiveStore().getParameterValue(this.item.uid, 'argument')
      if (!result) {
        if (this._sportOfferFamilyChoices[0].args === null || this._sportOfferFamilyChoices[0].args.length === 0) {
          return null
        }
        return this._sportOfferFamilyChoices[0].args[0]
      }
      return result
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    },
    _offerTypeLabel() {
      const family = this._sportOfferFamilies.find(f => f.fixedUID === parseInt(this._offerFamilyFixedUID))
      if (!family) {
        return 'Type offre'
      }

      let arg = this._argument
      return offerStore().getOfferFamilyDefinitionLabel(family.code) + (arg ? ' ' + arg : '')
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) {
        return;
      }

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) {
        return;
      }

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {}
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        sport: perspectiveStore().getCurrentSport(),
        gameUID: this._gameUID,
        offerFamilyFixedUID: this._offerFamilyFixedUID,
        arg: this._argument
      }
      axiosService.put('/wid/odds', payload).then(response => {
        this.data = response.data
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _onFamilyChange(fixedUID, arg) {
      this._setParameterValue('offerFamilyFixedUID', fixedUID)
      this._setParameterValue('argument', arg)
      perspectiveStore().saveWidgetParameters(this._widgetUID)

      setTimeout(() => {
        this._refresh()
      }, 100)

    },
    _setParameterValue(parameterKey, value) {
      if (!this._isWidgetYours) {
        message.warning("Tu ne peux pas modifier les paramètres d'un widget qui ne t'appartient pas");
        return
      }

      const widget = widgetDefinitionsStore().getByType('OddsWidget')
      const parameterDefinition = widget.parameters.find(p => p.key === parameterKey)

      perspectiveStore().setWidgetParameterValue(this._widgetUID, parameterDefinition, {key: value})
    },
    _deleteWidget() {
      if (!this._isWidgetYours) return
      perspectiveStore().deleteWidget(this._widgetUID, () => {
        modalStore().closeModal();
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <button class="small no-radius" style="position: absolute; left: 0; height: 27px">
          {{ _offerTypeLabel }}
        </button>
        <template #overlay>
          <a-menu>
              <span v-for="c in _sportOfferFamilyChoices">
                <a-menu-item @click="_onFamilyChange(c.value, null)" v-if="c.args === null">
                  {{ c.label }}
                </a-menu-item>
                <a-sub-menu :key="c.value" :title="c.label" v-else>
                  <a-menu-item v-for="arg in c.args" @click="_onFamilyChange(c.value, arg)">
                    {{ arg }}
                  </a-menu-item>
                </a-sub-menu>
              </span>
          </a-menu>
        </template>
      </a-dropdown>
      <button class="secondary small no-radius" @click="_deleteWidget" v-if="_isWidgetYours"
              style="position: absolute; right:0; height: 27px">
        <font-awesome-icon icon="fa-solid fa-trash-can"/>
      </button>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content" v-else style="padding-top: 6px">
      <div v-for="offer in data.results[_gameUID]" v-if="data.results">
        <offer-choice-button :offer-family="offer" :choice="c" v-for="c in offer.choices" v-if="offer"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>