<script>

import {modalStore} from "@/stores/Modal.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import StatsPaneHeaderCalendar from "@/components/routes/stats/panes/stats/header/StatsPaneHeader.Calendar.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";

export default {
  name: "StatsPaneHeader",
  components: {StatsPaneHeaderCalendar, FontAwesomeIcon},

  props: {
    offsetY: {
      type: Number,
      default: 0
    }
  },

  computed: {
    _styles() {
      return {
        top: '-' + this.offsetY + 'px',
      }
    },
    _selectedPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _selectedPerspectiveLabel() {
      if (!this._selectedPerspectiveUID) return 'Choisir une perspective'
      let perspective = perspectiveStore().get(this._selectedPerspectiveUID)
      if (!perspective) return 'Choisir une perspective'
      return perspective.name
    }
  },
  methods: {

    _openPerspectiveSelection() {
      console.log("StatsPaneHeader._openPerspectiveSelection")
      modalStore().openModal({uid: "perspectiveSelectionModal", payload: {type: "STATS", callback: this._onPerspectiveSelected}})
    },

    _onPerspectiveSelected(perspective) {
      console.log("StatsPaneHeader._onPerspectiveSelected", perspective)
    },

    _editPerspective() {
      modalStore().openModal({
        uid: 'perspectiveSettingsModal', payload: {
          uid: this._selectedPerspectiveUID,
          creation: false
        }
      })
    },

    _emitAction() {
      this.$emit('action')
    }
  }
}
</script>

<template>
  <div id="selection-pane-header" :style="_styles">

    <header>
      <div class="flex pt-2 mx-2">
        <button class="accent flex-grow" @click="_openPerspectiveSelection">
          {{ _selectedPerspectiveLabel }}
        </button>
        <button class="translucide square ml-1" @click="_editPerspective">
          <font-awesome-icon icon="fa-solid fa-sliders"/>
        </button>
      </div>
    </header>

    <stats-pane-header-calendar @action="_emitAction"/>

  </div>

</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

#selection-pane-header {
  position: absolute;
  z-index: 10;
  background: @background-color;
  border-radius: @radius-regular;
  overflow: hidden;
  box-shadow: 0px 8px 15px 0px rgba(110, 81, 215, 0.33);
  width: @selection-pane-width;
}

.mobile {
  #selection-pane-header {
    width: 100%;
  }
}
</style>