<script>
import ArjelButton from "@/components/common/ArjelButton.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {message} from "ant-design-vue";
import {modalStore} from "@/stores/Modal.store";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {contextStore} from "@/stores/Context.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "SelectionPaneFilters",
  components: {FontAwesomeIcon, LeagueLogo, ArjelButton},

  props: {
    offsetY: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      filter: '',
      filterTimeout: null,
    }
  },

  computed: {
    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport);
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },

    _favoriteLeagueUIDs() {
      let uids = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!uids) return [];
      if (!uids.length) return [];
      return uids.map(uid => parseInt(uid));
    },

    _favoriteLeagues() {
      return leagueStore().getAll.filter(l => l.sport === contextStore().getSport && this._favoriteLeagueUIDs.includes(l.uid));
    },

    _selectedLeaguesLabel() {
      let label = selectionPaneStore().selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    },

    _wrapperStyles() {
      return {
        bottom: `${this.offsetY}px`
      }
    },

    _arjelOnly() {
      return selectionPaneStore().arjelOnly
    },
  },

  methods: {

    _switchArjelOnly() {
      selectionPaneStore().switchArjelOnly()

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _openFavoritesLeaguesSettings() {
      modalStore().openModal({uid: 'userPreferencesModal', payload: {tab: 'league'}})
    },
    _clearLeaguesSelection() {
      selectionPaneStore().setSelectedLeagueLabel('Toutes')
      selectionPaneStore().setSelectedLeagueUIDs([])

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _selectGroup(group) {
      selectionPaneStore().setSelectedLeagueLabel(group.name)
      selectionPaneStore().setSelectedLeagueUIDs(group.values)

      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _selectOneLeague(league) {
      selectionPaneStore().setSelectedLeagueLabel(league.name)
      selectionPaneStore().setSelectedLeagueUIDs([league.uid])
      // On relance la recherche
      this.displayedPage = 1
      selectionPaneStore().runSelection();
    },
    _openLeagueSelectionModal(){
      modalStore().openModal({
        uid: 'selectionLeagueModal',
        payload: {
          sport: contextStore().getSport,
          onConfirm: (leagues) => {

            if(leagues.length === 0){
              this._clearLeaguesSelection()
              return
            }

            if(leagues.length === 1){
              this._selectOneLeague(leagues[0])
              return
            }

            const leaguesUIDs = leagues.map(l => l.uid)
            selectionPaneStore().setSelectedLeagueUIDs(leaguesUIDs)
            selectionPaneStore().setSelectedLeagueLabel('Plusieurs')
          }
        }
      })
    },
    _openLeagueGroupsModal() {
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: contextStore().getSport
        }
      })
    },
    _showAllMonitoringTicketsModal() {
      modalStore().openModal({uid: "allMonitoringTicketsModal"})
    }
  },
  watch: {
    filter: function (val) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        selectionPaneStore().filter = val
        selectionPaneStore().runSelection()
      }, 300);
    }
  }
}
</script>

<template>
  <div class="selection-results-filters-wrapper" :style="_wrapperStyles">
    <header class="selection-results-filters flex py-1">
      <arjel-button :arjel-only="_arjelOnly" :callback="_switchArjelOnly" class="ml-1"/>

      <a-dropdown :trigger="['click']" class="flex-grow-0">
        <a-tooltip placement="right" title="Sélectionner des compétitions">
          <button class="ml-1 translucide">
            <font-awesome-icon icon="fa-solid fa-trophy"/>
            {{ _selectedLeaguesLabel }}
          </button>
        </a-tooltip>
        <template #overlay>
          <a-menu style="text-align: left">
            <a-menu-item @click="_openLeagueGroupsModal">
              <font-awesome-icon icon="fa-solid fa-sliders" class="mr-1"/>
              Groupes de compétition
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
              {{ g.name }}
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_openFavoritesLeaguesSettings">
              <font-awesome-icon icon="fa-solid fa-sliders" class="mr-1"/>
              Compétitions préférées
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_selectOneLeague(l) " v-for="l in _favoriteLeagues">
              <div  class="flex">
                <div class="flex-grow">
                  {{ l.name }}
                </div>
                <div style="width: 20px; display: inline-block; text-align: right">
                  <league-logo :uid="l.uid"/>
                </div>
              </div>
            </a-menu-item>
            <a-menu-item @click="_openLeagueSelectionModal">
              <div  class="flex">
                <div class="flex-grow">
                  Autre compétitions
                </div>
                <div style="width: 20px; display: inline-block; text-align: center">
                  <font-awesome-icon icon="fa-solid fa-chevron-right"/>
                </div>
              </div>
            </a-menu-item>
            <a-menu-item v-if="_favoriteLeagueUIDs.length === 0">
              <a-alert message="Aucune compétition préférées" type="warning" class="m-0" show-icon/>
            </a-menu-item>
            <hr/>
            <a-menu-item @click="_clearLeaguesSelection">
              <font-awesome-icon icon="fa-solid fa-trophy" class="mr-1"/>
              Toutes les compétitions
            </a-menu-item>

          </a-menu>
        </template>
      </a-dropdown>

      <input v-model="filter" placeholder="Cherche une équipe..." class="ml-1 flex-grow" style="border-radius: 4px">
      <button class="primary square ml-2 mr-1" @click="_showAllMonitoringTicketsModal">
        <font-awesome-icon :icon="['fas', 'bolt']"/>
      </button>
    </header>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-results-filters-wrapper {
  position: fixed;
  width: @selection-pane-width;
  padding: @padding-small;
  background: @background-color;
  border-radius: @radius-large @radius-large 0 0;

  .selection-results-filters {
    height: @toolbar-large;
    border-radius: @radius-regular;
    overflow: hidden;
  }
}

.mobile {
  .selection-results-filters-wrapper {
    width: calc(100% - 2 * @padding-small);
  }
}
</style>