<script>
import BankrollResume from "@/components/common/BankrollResume.vue";
import {bankrollStore} from "@/stores/Bankroll.store";
import BankrollTicket from "@/components/routes/bankroll/panes/bankroll/items/BankrollTicket.vue";

export default {
  name: "NotificationInstanceBetAddedInBankroll",
  components: {BankrollTicket, BankrollResume},

  props: {
    instance: {
      type: Object,
      required: true
    },
    unread: {
      type: Boolean,
      default: false
    }
  },

  computed:{
    _values(){
      if(!this.instance){
        return null;
      }
      return this.instance.values;
    },
    _bankrollUID(){
      if(!this._values){
        return null;
      }
      let value = this._values['BANKROLL_UID'];
      if(!value){
        return null;
      }
      return parseInt(value[0]);
    },
    _bankroll(){
      if(!this._bankrollUID){
        return null;
      }
      return bankrollStore().get(this._bankrollUID);
    },
    _bankrollName(){
      if(!this._bankroll){
        return null;
      }
      return this._bankroll.name;
    },
    _ticketUID(){
      if(!this._values){
        return null;
      }
      let value = this._values['TICKET_UID'];
      if(!value){
        return null;
      }
      return parseInt(value[0]);
    },
    _ticket(){
      if(!this._ticketUID){
        return null;
      }
      return bankrollStore().getTicketByUID(this._ticketUID);
    }
  },

  methods: {
    _archive(){
      this.$emit('archive');
    }
  }
}
</script>

<template>
  <div class="instance-notification" :class="{'unread' : unread}">
    <div class="instance-notification-body pt-1">
      <fieldset class="mt-1">
        <legend>{{ _bankrollName }}</legend>
        <bankroll-ticket :ticket-uid="_ticketUID" :editable="false" class="mx-1 mb-1"/>
      </fieldset>
    </div>
  </div>
</template>

<style scoped lang="less">

</style>