<script>
import {bankrollStore} from "@/stores/Bankroll.store";

export default {
  name: "BankrollResume",

  props: {
    bankrollUID: {
      required: true
    },
    showBalance: {
      type: Boolean,
      default: true
    },
    showWaitingBets: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    _userBankrolls() {
      return bankrollStore().getAll
    },
    _bankroll() {
      if(!this._userBankrolls) return null
      return this._userBankrolls.find(b => b && (b.uid === this.bankrollUID))
    },
    _bankrollColor() {
      if (!this._bankroll) return 'grey'
      if (this._bankroll.balance >= this._bankroll.initialCapital) return '#6DFFC2'
      return '#FF846E'
    },
    _bankrollBalance() {
      if (!this._bankroll) return 0.0
      return this._bankroll.balance.toFixed(2)
    },
    _beneficePerte() {
      if (!this._bankroll) return 0.0
      return (this._bankroll.balance - this._bankroll.initialCapital).toFixed(2)
    },
    _bankrollROI() {
      if (!this._bankroll) return 0.0
      return (this._bankroll.roi * 100.0).toFixed(2)
    },
    _bankrollLast5() {
      if (!this._bankroll) return ['X', 'X', 'X', 'X', 'X']
      // split the last 5 tickets in an array of 5 elements
      let last5 = this._bankroll.last5Tickets.split('')
      while (last5.length < 5) {
        last5.unshift('X')
      }
      return last5.reverse()
    },
    _bankrollAverageOdd() {
      if (!this._bankroll) return 0.0
      return this._bankroll.averageOdd.toFixed(2)
    },
    _bankrollTicketCount() {
      if (!this._bankroll) return 0.0
      return this._bankroll.ticketDoneCount
    },
    _bankrollTicketWaitCount() {
      if (!this._bankroll) return 0.0
      return this._bankroll.ticketWaitCount
    }
  },
  methods: {
    _ticketColor(ticket) {
      if (ticket === 'W') return '#6DFFC2'
      if (ticket === 'L') return '#FF846E'
      if (ticket === 'H') return '#FFC86D'
      return 'grey'
    }
  }
}
</script>

<template>
  <div class="bankroll">
    <table>
      <tbody>
      <tr>
        <th class="bets">Bets</th>
        <th class="waiting-bets" v-if="showWaitingBets">en cours</th>
        <th class="odd">Côte</th>
        <th colspan="2">L5</th>

      </tr>
      <tr>
        <td>
          {{ _bankrollTicketCount }}
        </td>
        <td v-if="showWaitingBets">
          {{ _bankrollTicketWaitCount }}
        </td>
        <td>
          {{ _bankrollAverageOdd }}
        </td>
        <td colspan="2">
          <font-awesome-icon icon="fa-solid fa-circle"
                             v-for="i in _bankrollLast5"
                             class="mr-1" :style="{'width': '8px', 'color': _ticketColor(i)}"/>
        </td>

      </tr>
      <tr>
        <th colspan="2">Balance</th>
        <th>{{ _beneficePerte >= 0 ? 'Bénéf' : 'Perte' }}</th>
        <th class="roi">ROI</th>
      </tr>
      <tr>
        <td colspan="2">
          {{ _bankrollBalance }}
        </td>
        <td :style="{'color': _bankrollColor}">
          {{ _beneficePerte }} €
        </td>
        <td :style="{'color': _bankrollColor}" class="roi">
          {{ _bankrollROI }}%
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped lang="less">
.bankroll {
  table {
    width: 100%;

    .l5, .waiting-bets {
      width: 70px;
    }

    .roi {
      width: 64px;
    }

    .bets, .odd {
      width: 48px;
    }
  }
}
</style>