<script>
import axiosService from "@/services/axios.service";
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import {statStore} from "@/stores/Statistic.store";
import {message} from "ant-design-vue";
import Statistic from "@/components/common/Statistic.vue";
import YoutubeButton from "@/components/common/YoutubeButton.vue";
import {modalStore} from "@/stores/Modal.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import TeamName from "@/components/common/TeamName.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";

export default {
  name: "GameStatisticWidget",
  components: {
    ArjelLogo, TeamName, TeamLogo,
    YoutubeButton,
    Statistic,
    WidgetPeriodParamButton, WidgetTeamParamButton, WidgetLeagueParamButton, WidgetLocationParamButton,
    FontAwesomeIcon,
    WidgetLoadingContent
  },
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      // Les données récupérés du server
      data: null,
      parameters: null,
      forceShowMyStat: false
    }
  },
  mounted() {
    this._onGameUIDChange()

    if (!this.item) return;  // widget store preview
    window.emitter.on('context-store:game-uid:change', (gameUID) => {
      this._onGameUIDChange()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _gameUID() {
      return contextStore().gameUID
    },
    _widgetUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._widgetUID) return null
      return perspectiveStore().getWidgetByUID(this._widgetUID)
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    },
    _frequencyStatDefinitions() {
      return statStore().getDefinitions(contextStore().getSport).filter(stat => stat.type === 'FREQUENCY')
    },
    _leagueUID() {
      if (!this.row) return null
      return this.row.leagueUID
    },
    _percentage() {
      if (!this.data) return null
      if (!this.data.percentage) return null
      return this.data.percentage
    },
    _formattedPercentage() {
      if (!this._percentage) return '-'
      return (this._percentage * 100.0).toFixed(1) + '%'
    },
    _absolute() {
      if (!this.data) return '-'
      if (!this.data.absolute) return '-'
      return this.data.absolute
    },
    _gameCount() {
      if (!this._absolute) return '-'
      if (!this._percentage) return '-'
      return Math.round(this._absolute / this._percentage)
    },
    _statFixedUID() {
      const result = perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid')
      if (!result) {
        return this._frequencyStatDefinitions[0].fixedUID
      }
      return result
    },
    _statFixedUIDCombined(){

      if(!this._period) return null
      if(this._period === 'ALL_TIME') return null

      const result = perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid_combined')
      if (!result) {
        return null
      }
      return result
    },
    _statFixedUIDCombinedOperator(){
      const result = perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid_combined_operator')
      if (!result) {
        return 'AND'
      }
      return result
    },
    _statName() {
      const sdef = this._frequencyStatDefinitions.find(f => f.fixedUID === parseInt(this._statFixedUID))
      if (!sdef) {
        return 'Statistique...'
      }
      return sdef.name
    },
    _period() {
      return perspectiveStore().getParameterValue(this.item.uid, 'period')
    },
    _sdef(){
      if(!this._statFixedUID) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUID)
    },
    _statType() {
      if (!this._sdef) return null
      return this._sdef.type
    },
    _sdefCombined(){
      if(!this._statFixedUIDCombined) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUIDCombined)
    },
    _statCombinedLabel() {
      if (!this._sdefCombined) return 'Choisis une stat à combiner'
      return this._sdefCombined.name
    },
    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this._itemUID)
    },
    _statFixedUIDCombinedOperatorLabel() {
      switch (this._statFixedUIDCombinedOperator) {
        case 'AND':
          return 'ET'
        case 'OR':
          return 'OU'
        case 'IF':
          return 'SI'
      }
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    _onGameUIDChange() {
      this._refresh()
    },

    _refresh() {
      if (!contextStore().gameUID) {
        return;
      }

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) {
        return;
      }

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      if (!this.item) {  // widget store preview
        this.data = {}
        return;
      }

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      const payload = {
        sport: perspectiveStore().getCurrentSport(),
        gameUID: this._gameUID,
        statFixedUID: this._statFixedUID,
        statFixedUIDCombined: this._statFixedUIDCombined,
        statFixedUIDCombinedOperator: this._statFixedUIDCombinedOperator,
        team: perspectiveStore().getParameterValue(this.item.uid, 'team'),
        location: perspectiveStore().getParameterValue(this.item.uid, 'location'),
        period: this._period,
        league: perspectiveStore().getParameterValue(this.item.uid, 'league')
      }
      axiosService.put('/wid/gam/statistic', payload).then(response => {
        this.data = response.data.result
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },

    _setParameterValue(parameterKey, value) {
      if (!this._isWidgetYours) {
        message.warning("Tu ne peux pas modifier les paramètres d'un widget qui ne t'appartient pas");
        return
      }

      const widget = widgetDefinitionsStore().getByType('FinSerieWidget')
      const parameterDefinition = widget.parameters.find(p => p.key === parameterKey)

      perspectiveStore().setWidgetParameterValue(this._widgetUID, parameterDefinition, {key: value})
    },
    _showMyStat() {
      if(this._period === 'ALL_TIME'){
        message.info('MyStat ne fonctionne pas avec la période "Depuis toujours"');
        return;
      }
      this.forceShowMyStat = true
    },

    _switchCombinedOperator(){
      if(!this._statFixedUIDCombinedOperator) {
        perspectiveStore().setWidgetParameterValue(this.item.uid, {'key': 'stat_fixed_uid_combined_operator'}, {'key': 'OR'})
      }else{
        // Il existe 3 opérateur : AND, OR, IF
        // Il faut passer de l'un à l'autre dans cet ordre : AND -> OR -> IF -> AND
        let newOperator = 'OR';
        if(this._statFixedUIDCombinedOperator === 'AND'){
          newOperator = 'OR'
        }else if(this._statFixedUIDCombinedOperator === 'OR'){
          newOperator = 'IF'
        }else{
          newOperator = 'AND'
        }
        perspectiveStore().setWidgetParameterValue(this.item.uid, {'key': 'stat_fixed_uid_combined_operator'}, {'key': newOperator})
      }
      // On sauvegarde les paramètres
      perspectiveStore().saveWidgetParameters(this.item.uid)
    },

    _openStatSelectorModal(stat) {

      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          statFixedUID: this._statFixedUID,
          category:'stat',
          type: this._statType,
          sport: contextStore().getSport,
          callback: (categorie, sdef) => {
            // Si non authentifié, message d'erreur
            if (!contextStore().isAuthenticated()) {
              message.error('Tu dois être connecté pour personnalisé les statistiques');
              return;
            }

            const paramKey = stat === 'base' ? 'stat_fixed_uid' : 'stat_fixed_uid_combined'

            let parameterDefinition = this._parameters.find(p => p.key === paramKey)
            if(!parameterDefinition) {
              // On modifie la configuration du widget pour spécifié la statistique utilisée.
              parameterDefinition = {
                'key': paramKey,
                'multiple': false,
              }
            }

            perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': sdef.fixedUID})
            perspectiveStore().saveWidgetParameters(this.item.uid)

            this.forceShowMyStat = false
          }
        }

      });
    },

    _clearCombined(){
      // Si non authentifié, message d'erreur
      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour personnalisé les statistiques');
        return;
      }
      let parameterDefinition = this._parameters.find(p => p.key === 'stat_fixed_uid_combined')
      perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': null})
      let parameterDefinitionOperator = this._parameters.find(p => p.key === 'stat_fixed_uid_combined_operator')
      perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinitionOperator, {'key': null})

      // On sauvegarde les paramètres
      perspectiveStore().saveWidgetParameters(this.item.uid)
      this.forceShowMyStat = false
    },

    _openStatModal(event) {
      event.stopPropagation()
      modalStore().openModal({
        uid: 'statisticModal', payload: {
          'uid': this.data.statUID,
          'uidBase': this.data.statBaseUID,
          'uidCombined': this.data.statCombinedUID,
          'period': this._period
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Stat.
        <button class="small no-radius" @click="_openStatSelectorModal('base')"
                style="padding-left: 2px !important; padding-right: 2px !important; height:27px">
          {{ _statName }}
        </button>
      <div class="widget-header-params flex" v-if="item">
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
        <button class="accent no-radius" @click="_showMyStat">
          <font-awesome-icon icon="fa-solid fa-code-merge" style=""/>
        </button>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content" v-else-if="data.statUID">
      <div class="widget-header my-stat m-1 flex" v-show="_statFixedUIDCombined || forceShowMyStat">
        <!---->
        <button class="small no-radius" @click="_switchCombinedOperator" style="height: 28px; padding-right: 8px !important; color: #ffa528 !important;">
          {{ _statFixedUIDCombinedOperatorLabel }}
        </button>
        <button class="small no-radius flex-grow text-left" @click="_openStatSelectorModal('combined')" style="height: 28px; padding-left: 3px !important;">
          {{ _statCombinedLabel }}
        </button>
        <button class="small no-radius" style="line-height: 29px !important; height: 27px !important;" v-if="_statFixedUIDCombined  || forceShowMyStat" @click="_clearCombined">
          <font-awesome-icon icon="fa-solid fa-trash-can"/>
        </button>
      </div>
      <table :style="{marginTop : _statFixedUIDCombined  || forceShowMyStat ? '0px' : '20px'}">
        <tbody>
        <tr>
          <td class="text-left pl-2">
            <span style="line-height: 20px">Arrivé {{ _absolute }}x/{{ _gameCount }} matchs</span>
          </td>
          <td style="width: 32px">
            <button class="tiny right-label" style="font-size: 9pt !important; padding: 0 4px !important;"
                    @click.stop="_openStatModal">({{ _absolute }})
            </button>
          </td>
          <td style="width: 64px">
            {{ _formattedPercentage }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="p-2">
      <a-alert message="Oups, la stat que tu cherches n’est pas dispo dans le système. 🔍" type="warning" show-icon/>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

@row-height: 18px;
.stat-label{
  font-size: 8pt;
  text-align: left;
}
.relative-value {
  min-width: 104px;
  text-align: right;
  padding-right: @padding-small;
}
</style>