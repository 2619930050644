<script>
import AnalyseBankrollPerspective from "@/components/routes/bankroll/panes/analyse/perspective/AnalyseBankrollPerspective.vue";
import AnalyseBankrollPerspectivesBottomBar from "@/components/routes/bankroll/panes/analyse/perspective/AnalyseBankrollPerspectiveBottomBar.vue";

export default {
  name: "AnalyseBankrollPane",
  components: {
    AnalyseBankrollPerspectivesBottomBar,
    AnalyseBankrollPerspective
  },
}
</script>

<template>
  <div id="analyse-bankroll-pane">
    <analyse-bankroll-perspective/>
    <analyse-bankroll-perspectives-bottom-bar/>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

#analyse-bankroll-pane {
  height: calc(100dvh - @toolbar-regular);
  width: calc(100% - 400px - 3 * @padding-small) !important;
  margin: @padding-small;
  border-radius: @radius-regular;
  background: rgb(34,34,34);
  background: linear-gradient(215deg, rgba(255,255,255,0.05) 0%, rgba(44,44,46,0.80) 30%, rgba(44,44,46,0.80) 40%, rgba(34,34,34,0.90) 100%);
}

.mobile {
  #analyse-bankroll-pane {
    display: none;
  }
}

</style>