<script>
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

// Stores
import {perspectiveStore} from "@/stores/Perspective.store";
import {modalStore} from "@/stores/Modal.store";


export default {
  name: "WidgetNbEventsParamButton",
  components: {FontAwesomeIcon, LeagueLogo},
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  methods: {
    _openSettingsModal() {
      modalStore().openModal({
        uid: 'widgetSettingsModal', payload: {
          uid: this.widget.uid
        }
      })
    },
  },
  computed: {
    _key() {
      return 'min_event'
    },
    _value() {
      const v = perspectiveStore().getParameterValue(this.widget.uid, this._key)
      if(!v) return '3'
      return v
    },
    _tooltipTitle() {
      return "Nombre d'événements minimum : " + this._value
    }
  }
}
</script>

<template>
  <a-tooltip placement="top" :title="_tooltipTitle">
    <button class="square small no-radius" @click="_openSettingsModal">
      {{ _value }}
    </button>
  </a-tooltip>
</template>
