<script>

import {offerStore} from "@/stores/Offer.store";
import {contextStore} from "@/stores/Context.store";
import {bookmakerStore} from "@/stores/Bookmaker.store";
import {modalStore} from "../../stores/Modal.store";

export default {
  name: "OfferChoiceButton",
  props: {
    offerFamily: {
      type: Object,
      required: true
    },
    choice: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    showProbability: {
      type: Boolean,
      default: true
    },
    showValuableIndicator: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    _offerStore() {
      return offerStore()
    },
    _contextStore() {
      return contextStore()
    },
    _choiceOffers() {
      return this.choice.offers
    },
    _offerValue() {
      if(!this._choiceOffers) return null
      let selectedBookmakerOffer = this._offerStore.getOfferByBookmakerUID(this._choiceOffers, this._contextStore.bookmakerUID);
      if (selectedBookmakerOffer) {
        return selectedBookmakerOffer
      }
      return this._choiceOffers[0]
    },
    _offerBookmakerUID() {
      if(!this._offerValue) return null
      return this._offerValue.bookmakerUid
    },
    _offerValueValue() {
      return this._offerStore.getOfferValue(this._offerValue)
    },
    _offerValueValuableIndicator() {
      return this._offerStore.getOfferValuableIndicator(this._offerValue)
    },
    _offerRecommendedOdd(){
      return this._offerStore.getOfferRecommendedOdd(this._offerValue)
    },
    _offerValueValuableIndicatorLabel() {
      let value = null
      let positiveChar = null
      if(offerStore().valueIndicatorType === 'indicator'){
        value = this._offerValueValuableIndicator
        positiveChar = '+'
      }else{
        value = this._offerRecommendedOdd
        positiveChar = ''
      }

      if (!value) return '-'
      return (value > 0 ? positiveChar : '') + value

    },
    _offerValueProbability() {
      return this._offerStore.getOfferProbability(this._offerValue)
    },
    _offerValueProbabilityLabel() {
      if (!this._offerValueProbability) return '-'
      return this._offerValueProbability > 0 ? (this._offerValueProbability * 100.0).toFixed(0) + '%' : '-'
    },
    _buildCssClasses() {
      return {
        'offer-choice-button': true,
        'selected': this.selected,
        'tiny': this.size === 'tiny',
        'small': this.size === 'small',
      }
    },
    _buildCssStyle() {
      return {
        fontStyle: this._offerBookmakerUID === this._contextStore.bookmakerUID ? '' : 'italic !important',
      }
    },
    _bookmaker(){
      if(!this._offerBookmakerUID) return null
      return bookmakerStore().get(this._offerBookmakerUID)
    },
    _tooltipTitle() {
      let baseLabel = this.offerFamily.label + " / " + this.choice.label;
      if(!this._bookmaker) return baseLabel
      let bookmakerName = this._bookmaker.name
      return baseLabel + " @ " + bookmakerName
    }
  },
  methods: {
    _onClick(event) {
      event.stopPropagation()
      if (!this.choice) return
      modalStore().openModal({
        uid: 'offerModal',
        payload: {
          offerUID: this._offerValue.offerUID,
          obv: this._offerValue
        }
      })
    }
  },
}
</script>

<template>
  <a-tooltip :title="_tooltipTitle">
    <button :class="_buildCssClasses" :style="_buildCssStyle" @click.prevent="_onClick">
      <label :class="{'valuable-indicator': true, 'positive' : _offerValueValuableIndicator > 0}" v-if="showValuableIndicator">
        {{ _offerValueValuableIndicatorLabel }}
      </label>
      <label class="value px-1">
          {{ _offerValueValue }}
      </label>
      <label :class="{'probability': true}" v-if="showValuableIndicator">
        {{ _offerValueProbabilityLabel }}
      </label>
    </button>
  </a-tooltip>
</template>

<style lang="less"  scoped>
@import "@/assets/styles/variables.less";

.offer-choice-button{
  height: 36px;
  padding: 0 !important;
  margin: 0 4px !important;

  &.selected{
    background: fade(@selected-color, 30%) !important;
  }

  label{
    display: block;
    min-height: 12px;
    max-height: 12px;
    line-height: 12px !important;

    &.valuable-indicator {
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @negative-color !important;

      &.positive {
        color: @positive-color !important;
      }
    }

    &.value{
      font-size: 13px;
    }

    &.probability {
      height: 10px;
      font-size: 10px;
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
      color: @text-color;
    }
  }

  &.small{
    height: 12px;
    label{
      &.valuable-indicator {
        font-size: 8px;
      }

      &.value{
        font-size: 9px;
      }

      &.probability {
        font-size: 8px;
      }
    }
  }

}




</style>