<script>

import dateService from "@/services/date.service";
import {statsPaneStore} from "@/components/routes/stats/panes/stats/StatsPane.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";

export default {
  name: "StatsPaneHeaderCalendar",

  data() {
    return {
      applyTimeout: null,
      startIndex: 0,
      endIndex: 0,
      hoverIndex: null
    }
  },

  computed: {
    _days(){
      const days = []
      for(let i = -1; i < 7; i++) {
        days.push({
          index: i,
          label: this._getDayLabel(i),
          date: this._getDateLabel(i)
        })
      }
      return days
    }
  },
  methods: {
    _classes(index) {
      return {
        'calendar-day-button': true,
        'selected': this._isDaySelected(index),
        'hover': this._isInHoverRange(index)
      }
    },
    _getDayLabel(index) {
      if(index < 0) {
        return 'auj.';
      }
      const date = new Date();
      date.setDate(date.getDate() + index);
      return date.toLocaleDateString('fr-FR', {weekday: 'short'});
    },

    _getDateLabel(index) {
      if(index < 0){
        return '00:00'
      }
      const date = new Date();
      date.setDate(date.getDate() + index);
      return date.toLocaleDateString('fr-FR', {day: '2-digit', month: '2-digit'});
    },

    _onClickOnDay(index) {

      if(this.startIndex !== null && this.endIndex !== null) {
        this.startIndex = null;
        this.endIndex = null;
      }

      if (this.startIndex === null) {
        this.startIndex = index;
      } else if (this.endIndex === null) {
        this.endIndex = index;
      }

      // On inverse les dates si la date de fin est inférieure à la date de début
      if(this.startIndex !== null && this.endIndex !== null) {
        if(this.startIndex > this.endIndex) {
          let tmp = this.startIndex;
          this.startIndex = this.endIndex;
          this.endIndex = tmp;
        }
      }

      clearTimeout(this.applyTimeout);
      this.applyTimeout = setTimeout(() => {
        this._applySelection();
      }, 1000);
    },

    _applySelection() {

      if(this.startIndex === null) {
        this.startIndex = 1
      }

      if(this.endIndex === null) {
        this.endIndex = null
      }

      this.hoverIndex = null;

      statsPaneStore().setDateInterval(this._calcDateInterval(), () => {
        statsRouteStore().updateWidgets()
      })
      clearTimeout(this.applyTimeout);
      this.$emit('action')
    },

    _calcDateInterval() {

      if(this.startIndex == null || typeof this.startIndex ==='undefined') return;

      let dateInterval = {
        start: new Date(),
        end: new Date()
      };

      // Calculate start date
      switch (this.startIndex) {
        case -1: // Today from 00:00
          // Get end of day date time
          dateInterval.start.setHours(0, 0, 0, 0);
          break;
        case 0: // Today now
          // Nothing to do, we keep the current date
          break;
        default:
          dateInterval.start.setDate(dateInterval.start.getDate() + this.startIndex);
          dateInterval.start.setHours(0, 0, 0, 0);
          break;
      }

      // Calculate end date
      // Calculate start date
      if(null == this.endIndex) {
        this.endIndex = this.startIndex;
      }
      switch (this.endIndex) {
        case -1: // Today from 00:00
          dateInterval.end.setHours(23, 59, 59, 999);
          break;
        default:
          dateInterval.end.setDate(dateInterval.end.getDate() + this.endIndex);
          dateInterval.end.setHours(23, 59, 59, 999);
          break;
      }

      dateInterval.start = dateService.formatISO(dateInterval.start)
      dateInterval.end = dateService.formatISO(dateInterval.end)

      return dateInterval
    },
    _isDaySelected(index) {
      if(this.startIndex === null) return false;
      if(this.endIndex == null){
        return index === this.startIndex;
      }
      return index >= this.startIndex && index <= this.endIndex;
    },
    _setHoverIndex(index) {
      this.hoverIndex = index;
    },
    _isInHoverRange(index) {

      if (this.startIndex !== null && this.endIndex !== null) {
        return false; // On ne traite pas l'hover si on a déjà une sélection complète
      }
      if (this.startIndex === null) return false;

      if (this.hoverIndex > this.startIndex) {
        return index >= this.startIndex && index <= this.hoverIndex;
      }
      if (this.hoverIndex < this.startIndex) {
        return index <= this.startIndex && index >= this.hoverIndex;
      }
      return index === this.startIndex;
    }
  }
}
</script>

<template>
  <header id="selection-pane-header-calendar">
    <div class="flex">
      <button v-for="d in _days" :class="_classes(d.index)" @click="_onClickOnDay(d.index)" @mouseover="_setHoverIndex(d.index)">
        <span class="day-label">{{d.label}}</span>
        <span class="date-label">{{d.date}}</span>
      </button>

    </div>
  </header>

</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

.calendar-day-button {
  padding: 0;
  flex-grow: 1;
  min-height: @toolbar-large;
  max-height: @toolbar-large;
  border-radius: 0 !important;
  border-bottom: 3px solid #00000022;

  &:first-child {
    border-radius: 0 0 0 @radius-regular  !important;
    overflow: hidden;
  }

  &:last-child {
    border-radius: 0 0 @radius-regular 0 !important;
    overflow: hidden;
  }

  &.selected {
    border-bottom: 3px solid @accent-color;
    background: linear-gradient(0deg, #494081aa 0%, #49408199 7%, #49408166 21%, rgba(37, 37, 42, 0) 90%);
  }

  &:hover, &.hover {
    border-bottom: 3px solid #f97f28;
    background: linear-gradient(135deg, #f97f28aa 0%, #f97f2899 33%, #fea32866 66%, #fea32888 100%) !important;
  }


  span {
    display: block;
    padding: 0 !important;
    margin: 0 !important;
  }

  .day-label {
    font-size: 0.8rem;
    color: #fff;
    max-height: calc(@toolbar-large / 7 * 4);
    line-height: calc(@toolbar-large / 7 * 4);
  }

  .date-label {
    font-size: 0.6rem;
    color: #fff;
    max-height: calc(@toolbar-large / 7 * 3);
    line-height: calc(@toolbar-large / 7 * 3 - 4px);
  }
}
</style>