<script>
import axiosService from "@/services/axios.service";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamLogo from "@/components/common/TeamLogo.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {leagueStore} from "@/stores/League.store";
import TeamName from "@/components/common/TeamName.vue";
import GameDate from "@/components/common/GameDate.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {playerStore} from "@/stores/Player.store";
import GameEvent from "@/components/common/GameEvent.vue";

export default {
  name: "PlayerPaneItem.Forme.Tab",
  components: {GameEvent, GameResultCircle, TeamName, TeamLogo, LeagueLogo, GameDate},

  props: {
    playerUid: {
      required: true
    },
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,

      filters: {
        type: 'ALL',
        moment: 'FT',
        league: 'ANY'
      },

      overGameUID: null
    }
  },
  beforeMount() {

    // On va chercher la préférence du type
    let type = preferencesStore().getFirstValue('PLAYER_FORME_TAB', 'TYPE');
    if (type) {
      this.filters.type = type
    } else {
      preferencesStore().save('PLAYER_FORME_TAB', 'TYPE', [this.filters.type])
    }

    // On va chercher la préférence du moment
    let moment = preferencesStore().getFirstValue('PLAYER_FORM_TAB', 'MOMENT_' + this._sport);
    if (moment) {
      this.filters.moment = moment
    } else {
      preferencesStore().save('PLAYER_FORM_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    }

    // On va chercher la préférence de la league
    let league = preferencesStore().getFirstValue('PLAYER_FORME_TAB', 'LEAGUE');
    if (league) {
      this.filters.league = league
    } else {
      preferencesStore().save('PLAYER_FORME_TAB', 'LEAGUE', [this.filters.league])
    }

    this._refreshData()
  },

  computed: {
    _sport() {
      if (!this._game) return null
      return this._game.sport
    },
    _moment() {
      switch (this._sport) {
        case 'FOOTBALL':
          return ['FT', 'P1', 'P2']
        case 'BASKETBALL':
          return ['FT', 'P1', 'P2', 'P3', 'P4']
        case 'HANDBALL':
          return ['FT', 'P1', 'P2']
        case 'RUGBY':
          return ['FT', 'P1', 'P2']
        case 'HOCKEY':
          return ['FT', 'P1', 'P2', 'P3']
      }
    },
    _momentLabel() {
      return this.filters.moment
    },
    _game() {
      return gameStore().get(this.gameUid)
    },
    _player() {
      return playerStore().get(this.playerUid)
    },
    _games() {
      if (!this.data) return []

      const sortedGames = [...this.data.games].sort((a, b) => {
        if (a.date > b.date) return -1
        if (a.date < b.date) return 1
        return 0
      })
      return sortedGames
    },
  },

  methods: {

    _setType(type) {
      this.filters.type = type
      preferencesStore().save('PLAYER_FORME_TAB', 'TYPE', [this.filters.type])

      this._refreshData()
    },

    _setLeague(league) {
      this.filters.league = league
      preferencesStore().save('PLAYER_FORME_TAB', 'LEAGUE', [this.filters.league])

      this._refreshData()
    },

    _switchMoment() {
      const index = this._moment.indexOf(this.filters.moment)
      this.filters.moment = this._moment[(index + 1) % this._moment.length]

      preferencesStore().save('PLAYER_FORM_TAB', 'MOMENT_' + this._sport, [this.filters.moment])
    },

    _refreshData() {
      const payload = {
        gameUID: this.gameUid,
        playerUID: this.playerUid,
        locationStrict: this.filters.type === 'DOM_EXT',
        league: this.filters.league
      }
      axiosService.put('/dta/player/basic-data/forme', payload).then(response => {
        this.data = response.data
      })
    },
    _isCurrentSeason(game) {
      if (!game) return false
      const league = leagueStore().get(game.leagueUID)
      if (!league) return false
      return game.seasonName === league.currentSeason
    },

    _homeScore(game) {
      if (!game) return '?'
      switch (this.filters.moment){
        case 'FT':
          return game.scoreFtHome
        case 'P1':
          return game.scoreP1Home
        case 'P2':
          return game.scoreP2Home
        case 'P3':
          return game.scoreP3Home
        case 'P4':
          return game.scoreP4Home
        default:
          return '?'
      }
    },
    _awayScore(game) {
      if (!game) return '?'
      switch (this.filters.moment){
        case 'FT':
          return game.scoreFtAway
        case 'P1':
          return game.scoreP1Away
        case 'P2':
          return game.scoreP2Away
        case 'P3':
          return game.scoreP3Away
        case 'P4':
          return game.scoreP4Away
        default:
          return '?'
      }
    },
    _playerTeamUID(game) {
      if (!game) return null
      if(game.homeTeamUID === this._player.domesticTeamUID || game.homeTeamUID === this._player.nationalTeamUID){
        return game.homeTeamUID
      }
      return game.awayTeamUID
    },
    _events(game) {
      if (!game) return []
      if( !this.data || !this.data.gameEventsMap) return []
      return this.data.gameEventsMap[game.uid]
    },
  },
}
</script>

<template>
  <div>
    <div class="flex" v-if="false">
      <fieldset class="m-1 p-0">
        <legend class="m-0">Type</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'ALL'}"
                style="height: 22.3px"
                @click="_setType('ALL')">Tous
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.type === 'DOM_EXT'}"
                style="height: 22.3px"
                @click="_setType('DOM_EXT')">D/E
        </button>
      </fieldset>

      <fieldset class="m-1 p-0">
        <legend class="m-0">Moment</legend>
        <button :class="{'small no-radius' : true}" style="width:46px"
                @click="_switchMoment">{{ _momentLabel }}
        </button>
      </fieldset>

      <fieldset class="m-1 p-0">
        <legend class="m-0">Compétition</legend>
        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'ANY'}"
                style="height: 22.3px"
                @click="_setLeague('ANY')">Toutes
        </button>

        <button :class="{'small no-radius' : true, 'primary selected' : filters.league === 'CURRENT'}"
                style="height: 22.3px"
                @click="_setLeague('CURRENT')">Courante
        </button>
      </fieldset>
    </div>
    <fieldset class="no-border text-left mt-1">
      <legend class="ml-1">Derniers matchs</legend>
      <a-alert v-if="!_games || _games.length === 0" type="info" show-icon message="Aucun match trouvé" class="mx-1 mb-2"/>
      <div v-for="g in _games">
        <table :class="{'last-player-game-row': true}">
          <tbody>
          <tr>
            <td class="date" rowspan="2">
              <game-date :uid="g.uid"/>
            </td>
            <td class="league" rowspan="2">
              <league-logo :uid="g.leagueUID"/>
            </td>
            <td class="team-logo">
              <team-logo :uid="g.homeTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.homeTeamUID"/>
            </td>
            <td class="events" rowspan="2">
                <game-event :item="e" style="margin-right: -6px; display: inline-block" v-for="e in _events(g)"/>
            </td>
            <td class="score">{{ _homeScore(g) }}</td>
            <td class="result" rowspan="2">
              <game-result-circle :team-uid="_playerTeamUID(g)" :game-uid="g.uid" :moment="filters.moment"/>
            </td>
          </tr>
          <tr>
            <td class="team-logo">
              <team-logo :uid="g.awayTeamUID" size="small"/>
            </td>
            <td class="team-name">
              <team-name :uid="g.awayTeamUID"/>
            </td>
            <td class="score">{{ _awayScore(g) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-game-row {
  border-bottom: 1px solid @stroke-color;

  tr, td{
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over{
    background-color: @hover-color;
  }

  .date {
    width: 64px;
  }

  .league {
    width: 32px;
  }

  .team-logo {
    width: 32px;
  }

  .team-name {
    text-align: left;
  }

  .events{
    width: 64px;
    text-align: right;
  }

  .score {
    width: 32px;
  }

  .result {
    width: 36px;
    padding: @padding-regular;
  }
}
</style>