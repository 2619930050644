<script>
import {message} from "ant-design-vue";
import {leagueGroupsStore} from "@/stores/LeagueGroups.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {modalStore} from "@/stores/Modal.store";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";

export default {
  name: "ProfilerRouteHeader",
  components: {ArjelLogo},

  data() {
    return {
      dateIntervalChoices: [
        {
          label: 'Plus tôt aujourd\'hui',
          shortLabel: '+tôt',
          code: 'EARLIER_TODAY'
        },
        {
          label: 'Aujourd\'hui',
          shortLabel: 'Auj.',
          code: 'TODAY'
        },
        {
          label: '24 prochaines heures',
          shortLabel: '24H',
          code: 'NEXT_24_HOURS'
        },
        {
          label: '3 prochains jours',
          shortLabel: '3J',
          code: 'NEXT_3_DAYS'
        },
        {
          label: '7 prochains jours',
          shortLabel: '7J',
          code: 'NEXT_7_DAYS'
        }
      ],

      filter: '',
      filterTimeout: null
    }
  },

  computed: {
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _arjelOnly() {
      return selectionPaneStore().arjelOnly
    },
    _selectedDateInterval() {
      const trs = statsRouteStore()
      return this.dateIntervalChoices.find(c => c.code === trs.selectedDateInterval);
    },
    _selectedDateIntervalLabel() {
      if (!this._selectedDateInterval) return ''
      return this._selectedDateInterval.shortLabel
    },

    _leagueGroups() {
      let groups = leagueGroupsStore().getBySport(contextStore().getSport);
      groups.sort((a, b) => a.name.localeCompare(b.name))
      return groups
    },

    _selectedLeaguesLabel() {
      let label = statsRouteStore().selectedLeagueLabel;
      if (!label) return 'Toutes'
      return label
    }
  },

  methods: {
    _onSelectDateInterval(option) {
      if (option === 'NEXT_3_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 3 jours d'analyse")
          return
        }
      }
      if (option === 'NEXT_7_DAYS') {
        if (!contextStore().isAuthenticated()) {
          message.warn("Connectes toi pour avoir accès à 7 jours d'analyse")
          return
        }
      }
    },
    _switchArjelOnly() {
      selectionPaneStore().switchArjelOnly((arjelOnly) => {
        this.$nextTick(() => {
          console.log("update widgets after switchArjelOnly", arjelOnly)
          statsRouteStore().updateWidgets()
        })
      })

    },
    _useSportFavoritesLeagues() {
      statsRouteStore().setSelectedLeagueLabel('Favorites')
      let values = preferencesStore().getValues('LEAGUE', 'FAVORITE_LEAGUES_' + contextStore().getSport);
      if (!values || !values.length) {
        message.warn("Tu n'as pas définit de compétitions préférées pour ce sport")
        return
      }
      // convert string to number
      values = values.map(v => Number(v))
      statsRouteStore().setSelectedLeagueUIDs(values)

    },
    _clearLeaguesSelection() {
      statsRouteStore().setSelectedLeagueLabel('Toutes')
      statsRouteStore().setSelectedLeagueUIDs([])
    },
    _openLeagueGroupsModal() {
      modalStore().openModal({
        uid: "leagueGroupsModal",
        payload: {
          sport: contextStore().getSport
        }
      })
    },
    _onFilterChange() {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        statsRouteStore().setFilter(this.filter)
      }, 300)
    },
    _selectGroup(group) {
      statsRouteStore().setSelectedLeagueLabel(group.name)
      statsRouteStore().setSelectedLeagueUIDs(group.values)
    },
  }
}
</script>

<template>
  <header class="p-1 flex">
    <a-dropdown :trigger="['click']" class="flex-grow-0">
      <button class="info" @click.prevent>{{ _selectedDateIntervalLabel }}</button>
      <template #overlay>
        <a-menu>
          <a-menu-item v-for="c in dateIntervalChoices" @click="_onSelectDateInterval(c.code)">{{
              c.label
            }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <a-dropdown :trigger="['click']" class="flex-grow-0">
      <a-tooltip placement="right" title="Sélectionner des compétitions">
        <button class="ml-1 info">
          <font-awesome-icon icon="fa-solid fa-trophy"/>
          {{ _selectedLeaguesLabel }}
        </button>
      </a-tooltip>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <div class="flex text-center">
              <arjel-logo :height="24"/>
              <button class="primary ml-2" v-if="_arjelOnly===true" @click="_switchArjelOnly">Uniquement</button>
              <button class="secondary ml-2" v-else-if="_arjelOnly===false" @click="_switchArjelOnly">Hors ARJEL
              </button>
              <button class="info ml-2" v-else @click="_switchArjelOnly">Indifférent</button>
            </div>
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_useSportFavoritesLeagues">
            Compétitions préférées
          </a-menu-item>
          <a-menu-item @click="_clearLeaguesSelection">
            Toutes les compétitions
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_selectGroup(g)" v-for="g in _leagueGroups">
            {{ g.name }}
          </a-menu-item>
          <hr/>
          <a-menu-item @click="_openLeagueGroupsModal">
            Gestion des groupes
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <input v-model="filter" placeholder="Cherche une équipe..." class="ml-1 flex-grow" @keyup="_onFilterChange">
  </header>
</template>

<style scoped lang="less">

</style>