<template>
  <div id="bankroll-pane">

    <bankroll-pane-header/>

    <header class="px-1 py-1 flex">
      <a-dropdown :trigger="['click']" class="mr-1">
        <button class="info filled">
          {{ _selectedStatusLabel }}
        </button>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="_setStatus('WIN')">
              Gagné
            </a-menu-item>
            <a-menu-item @click="_setStatus('LOSE')">
              Perdu
            </a-menu-item>
            <a-menu-item @click="_setStatus('WAITING')">
              En cours
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-input v-model:value="minOdd" placeholder="Côte min" allow-clear style="width: 64px" class="mr-1" @keyup="_clearMinOddValue"/>
      <a-input v-model:value="maxOdd" placeholder="Côte max" allow-clear style="width: 64px" @keyup="_clearMaxOddValue"/>
      <button class="ml-1 info filled" @click="_openSelectionLeagueModal">
        <font-awesome-icon icon="fa-solid fa-trophy"/>
        {{ _selectedLeagueLabel }}
      </button>

      <div class="flex-grow"/>
      <!-- Bouton export excel -->
      <button class="ml-1 square" @click="_exportXlsx" style="background: #0F9D58">
        <font-awesome-icon icon="fa-solid fa-file-excel"/>
      </button>
    </header>
    <div class="text-left p-1">
      <a-button-group>
      <a-dropdown :trigger="['click']" class="flex-grow-0 no-radius-right">
        <button class="small info">
          {{ _critereTriLabel }}
        </button>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="_setCritereTri('GAME_DATETIME')">
              Trier par date de match
            </a-menu-item>
            <a-menu-item @click="_setCritereTri('TICKET_DATETIME')">
              Trier par date de ticket
            </a-menu-item>
            <a-menu-item @click="_setCritereTri('ODD')">
              Trier par côte
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <button class="small info no-radius-left" @click="_switchCritereTriDirection">
        <font-awesome-icon icon="fa-solid fa-sort-down" v-if="_critereTriDirection === 'DESC'"/>
        <font-awesome-icon icon="fa-solid fa-sort-up" v-else/>
      </button>
      </a-button-group>
    </div>
    <hr/>

    <a-alert v-if="!_tickets" message="Veuillez choisir une bankroll" type="info" show-icon class="m-2"/>
    <a-alert v-else-if="_tickets.length === 0" message="Aucun ticket dans la bankroll" type="info" show-icon
             class="m-2"/>

    <div v-else class="bankroll">

      <bankroll-resume :bankroll-u-i-d="_bankrollUID" class="m-2"/>
      <div class="bankroll-tickets p-1 pb-5" @scroll="_loadNextPageIfNecessary">
        <fieldset class="m-0 no-border">
          <legend>Evolution du solde</legend>
          <bankroll-evolution-graphic :bankroll-u-i-d="_bankrollUID" class="m-0"/>
        </fieldset>

        <fieldset class="m-0 no-border bankroll-selections" v-if="_selectionsAttached && _selectionsAttached.length > 0">
          <legend>Sélection attachée</legend>
          <div v-for="selection in _selectionsAttached">
            <selection-item :value="selection" :show-monitoring="false" class="mb-1"/>
          </div>
        </fieldset>

        <fieldset class="m-0 no-border">
          <legend>Tickets</legend>
          <bankroll-ticket class="mb-1" v-for="t in _tickets" :ticket="t" :editable="!_isEditable"
                           :is-last-bankroll-ticket="_isLastBankrollTicket(t)"/>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>

import BankrollPaneHeader from "./header/BankrollPaneHeader.vue";
import BankrollTicket from "./items/BankrollTicket.vue";
import {bankrollPaneStore} from "./BankrollPane.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import BankrollResume from "@/components/common/BankrollResume.vue";
import BankrollEvolutionGraphic from "@/components/common/BankrollEvolutionGraphic.vue";
import {preferencesStore} from "@/stores/Preferences.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import {selectionStore} from "@/stores/Selection.store";
import {monitoringStore} from "@/stores/Monitoring.store";
import SelectionItem from "@/components/common/SelectionItem.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "BankrollPane",
  components: {
    SelectionItem,
    BankrollEvolutionGraphic,
    BankrollResume,
    BankrollTicket,
    BankrollPaneHeader
  },

  data() {
    return {
      minOdd: null,
      maxOdd: null,

      // timeout to load next page
      timeoutLoadNextPage: null
    }
  },
  methods: {
    _exportXlsx() {
      bankrollStore().exportXlsx(this._bankrollUID)
    },
    _isLastBankrollTicket(ticket) {
      return bankrollPaneStore().lastTicketUID === ticket.uid
    },
    _setCritereTri(option) {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      preferencesStore().save('BANKROLL_TICKETS', 'CRITERE_TRI', [option])
      bankrollPaneStore().setSortBy(option)
    },
    _setStatus(status) {
      bankrollPaneStore().setStatus(status)
    },
    _switchCritereTriDirection() {
      const cs = contextStore()
      if (!cs.isAuthenticated()) {
        message.warning("Il faut être connecté pour changer le tri.");
        return;
      }

      const order = this._critereTriDirection
      preferencesStore().save('BANKROLL_TICKETS', 'CRITERE_TRI_DIRECTION', [order])
      bankrollPaneStore().setSortDirection(order === 'ASC' ? 'DESC' : 'ASC')
    },
    _clearMinOddValue() {
      if(!this.minOdd) return
      this.minOdd = this.minOdd.replace(/[^0-9.]/g, '')
    },
    _clearMaxOddValue() {
      if(!this.maxOdd) return
      this.maxOdd = this.maxOdd.replace(/[^0-9.]/g, '')
    },
    _loadNextPageIfNecessary() {
      clearTimeout(this.timeoutLoadNextPage)
      this.timeoutLoadNextPage = setTimeout(() => {
        // check if user scroll to the end of the page
        const element = document.querySelector('.bankroll-tickets')
        if(!element) return
        let diff = element.scrollHeight - element.scrollTop - 1024; // Load next page when 512px from the bottom
        if (diff < element.clientHeight) {
          bankrollPaneStore().loadBankrollNextPage()
        }
      }, 50)
    },
    _openSelectionLeagueModal() {
      modalStore().openModal({
        uid: 'selectionLeagueModal',
        payload: {
          selectedUIDs: bankrollPaneStore().getSelectedLeagues().map(l => l.uid),
          onConfirm: (leagues) => {
            bankrollPaneStore().setSelectedLeagues(leagues)
          }
        }
      })
    },
  },
  computed: {
    _critereTriDirection() {
      return bankrollPaneStore().orderDirection
    },
    _bankrollUID() {
      return bankrollPaneStore().getBankrollActiveUID()
    },
    _bankroll() {
      return bankrollStore().get(this._bankrollUID)
    },
    _tickets() {
      return bankrollPaneStore().getResults()
    },
    _isEditable() {
      if (!this._bankroll) return false
      return this._bankroll.isMonitoring
    },
    _critereTriLabel() {
      const critereTri = bankrollPaneStore().orderBy;
      if (critereTri === 'TICKET_DATETIME') return 'Date ticket'
      if (critereTri === 'GAME_DATETIME') return 'Date match'
      if (critereTri === 'ODD') return 'Côte'

      // si le critère n'est pas défini, on utilise le paramètre par défaut
      return 'Date ticket'
    },
    _selectionsAttached() {
      const monitorings = monitoringStore().getByBankrollUID(this._bankrollUID)
      const selectionUIDs = monitorings.map(m => m.selectionUID)
      return selectionStore().getAllByUIDs(selectionUIDs)
    },
    _selectedLeagueLabel() {
      const selectedLeagues = bankrollPaneStore().getSelectedLeagues()
      if(!selectedLeagues) return 'Toutes'
      if (selectedLeagues.length === 0) return 'Toutes'
      if (selectedLeagues.length === 1) return selectedLeagues[0].name
      return 'Plusieurs'
    },
    _selectedStatusLabel() {
      const selectedStatus = bankrollPaneStore().getStatus()
      if(!selectedStatus) return 'Statut'

      switch (selectedStatus) {
        case 'WIN':
          return 'Gagné'
        case 'LOSE':
          return 'Perdu'
        case 'WAITING':
          return 'En cours'
      }

      return selectedStatus
    }
  },
  watch: {
    minOdd: {
      handler: function (value) {
        // si value est un float ou vide, on le sauvegarde
        const isEmpty = value === ''
        const isFloat = !isNaN(parseFloat(value))
        const onlyNumbers = value.replace(/[^0-9.]/g, '') === value
        const onlyOneDot = value.split('.').length <= 2
        const isLowerThanMaxOdd = !this.maxOdd || parseFloat(value) < parseFloat(this.maxOdd)
        const isValid = isEmpty || (isFloat && onlyNumbers && onlyOneDot && isLowerThanMaxOdd)
        if (isValid) {
          bankrollPaneStore().setMinOdd(value)
        }else{
          console.log('minOdd is not valid', isLowerThanMaxOdd)
        }
      }
    },
    maxOdd: {
      handler: function (value) {
        // si value est un float ou vide, on le sauvegarde
        const isEmpty = value === ''
        const isFloat = !isNaN(parseFloat(value))
        const onlyNumbers = value.replace(/[^0-9.]/g, '') === value
        const onlyOneDot = value.split('.').length <= 2
        const isGreaterThanMinOdd = !this.minOdd || parseFloat(value) > parseFloat(this.minOdd)
        const isValid = isEmpty || (isFloat && onlyNumbers && onlyOneDot && isGreaterThanMinOdd)
        if (isValid) {
          bankrollPaneStore().setMaxOdd(value)
        }
      }
    }
  }
}
</script>
<style lang="less">
@import "@/assets/styles/variables.less";

#bankroll-pane {
  margin: @padding-small 0 @padding-small @padding-small;
  border-radius: @radius-regular;
  background: rgb(94,53,43);
  background: linear-gradient(122deg, rgba(94,53,43,1) 0%, rgba(94,53,43,0.95) 3%, rgba(37,37,42,0.85) 21%, rgba(37,37,42,0.75) 100%);
  height: calc(100dvh - @toolbar-regular);
  width: 400px !important;
  min-width: 400px !important;

  .bankroll-tickets {
    height: calc(100dvh - (@toolbar-regular * 4) - @toolbar-small);
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .bankroll-selections{
    margin-bottom: @padding-regular;
  }
}

.mobile {
  #bankroll-pane {
    width: calc(100vw) !important;
    min-width: calc(100vw) !important;
  }
}

</style>