// stores/counter.js
import { defineStore } from 'pinia'
import { preferencesStore } from "@/stores/Preferences.store";
import { perspectiveStore } from "@/stores/Perspective.store";
import { contextStore } from "@/stores/Context.store";
import { statsPaneStore } from "@/components/routes/stats/panes/stats/StatsPane.store";
import axiosService from "@/services/axios.service";

export const statsRouteStore = defineStore('teamRouteStore', {
  state: () => {
    return {

      // Perspectives
      selectedPerspectiveUID: null,

      // Filtres
      selectedDateInterval: 'NEXT_3_DAYS',
      selectedLeagueLabel: 'Toutes',
      selectedLeagueUIDs: [],
      filter: '',

      // Focus context
      overContext: null,  // Represente le contexte de l'element survolé
      overContextPin: false,

      widgetsData: {},
      offers: {},

      // offers
      _updateOffersTimeout: null,
      offerFamilyFixedUID: null,
      offerArgument: null,
      offersAreLoading: true
    }
  },
  actions: {
    init() {
      const prefUID = preferencesStore().getFirstValue('DEFAULT_PERSPECTIVE', 'STATS_PERSPECTIVE')
      if (prefUID !== null) {
        statsRouteStore().setSelectedPerspectiveUID(prefUID)
      } else {
        statsRouteStore().selectFirstPerspective()
      }
    },
    selectFirstPerspective() {
      // On prend la premiere perspective
      const ps = perspectiveStore()
      const allPerspectives = ps.getBySportAndType(contextStore().getSport, 'STATS')
      if (allPerspectives.length > 0) {
        let uid = allPerspectives[0].uid;
        preferencesStore().save('DEFAULT_PERSPECTIVE', 'STATS_PERSPECTIVE', [uid])
        statsRouteStore().setSelectedPerspectiveUID(uid)
      }
    },
    clearWidgetData() {
      this.widgetsData = {}
    },
    updateWidgets() {
      // On clear les results
      statsRouteStore().clearWidgetData()
      window.emitter.emit('stats-route:widgets-need-to-refresh')
    },
    setSelectedPerspectiveUID(selectedPerspectiveUID) {
      if (!selectedPerspectiveUID) {
        return
      }
      this.selectedPerspectiveUID = parseInt(selectedPerspectiveUID)
      statsRouteStore().updateWidgets()
    },
    setFilter(filter) {
      this.filter = filter
      statsRouteStore().updateWidgets()
    },
    setSelectedLeagueLabel(selectedLeagueLabel) {
      this.selectedLeagueLabel = selectedLeagueLabel
    },
    setSelectedLeagueUIDs(selectedLeagueUIDs) {
      this.selectedLeagueUIDs = selectedLeagueUIDs
      statsRouteStore().updateWidgets()
    },

    switchArjelOnly(callback = null) {
      if (this.arjelOnly === null) {
        this.arjelOnly = true
      } else if (this.arjelOnly === true) {
        this.arjelOnly = false
      } else {
        this.arjelOnly = null
      }
      preferencesStore().save('BETTING', 'ARJEL_ONLY', [this.arjelOnly])
      if (callback) {
        callback(this.arjelOnly)
      }
      statsRouteStore().updateWidgets()
    },

    setOverContext(overContext, pin) {
      if (!this.overContext) {
        this.overContext = overContext
        this.overContextPin = pin
        return
      }

      if (pin === true) {   // Si on veut garder le contexte actuel
        if (this.overContextPin && this.overContext.gameUID === overContext.gameUID) {
          this.overContext = null
          this.overContextPin = false
          return;
        }
        this.overContext = overContext
        this.overContextPin = true
      } else {
        if (this.overContextPin) {
          return
        }
        this.overContext = overContext
      }
    },
    onSportChange() {
      this.selectFirstPerspective()
      setTimeout(() => {
        this.updateWidgets()
      }, 250)
    },

    setWidgetData(widgetUID, data) {
      this.widgetsData[widgetUID] = data

      statsRouteStore().updateOffers()
    },
    _onFamilyChange(uid, arg) {

      statsRouteStore().setOfferFamilyFixedUID(uid)
      statsRouteStore().setOfferArgument(arg)

      setTimeout(() => {
        statsRouteStore().updateOffers()
      }, 500)
    },

    setOfferFamilyFixedUID(offerFamilyFixedUID) {
      this.offerFamilyFixedUID = offerFamilyFixedUID
    },
    setOfferArgument(offerArgument) {
      this.offerArgument = offerArgument
    },

    updateOffers(retry = 0) {

      clearTimeout(this._updateOffersTimeout)

      this._updateOffersTimeout = setTimeout(() => {

        // On va chercher les cotes pour chaque match
        const results = statsRouteStore().getResults()
        const gameUIDs = results.map(result => result.gameUID)
        if (gameUIDs.length === 0) {
          return
        }

        // On récupère la famille d'offre par défaut du sport
        const srs = statsRouteStore()
        const cs = contextStore()

        let offerFamilyFixedUID = srs.getOfferFamilyFixedUID()
        if (!offerFamilyFixedUID) {
          if (retry < 3) {
            setTimeout(() => {
              this.updateOffers(retry + 1)
            }, 1000)
            return
          }
          console.warn('No offer family found for sport ' + cs.getSport + ' after ' + retry + ' retries')
          return;
        }

        let argParam = ''
        let offerArg = srs.getOfferArgument()
        if (offerArg) {
          argParam = '&arg=' + offerArg
        }

        this.offersAreLoading = true

        let url = '/dta/offers?offerFamilyFixedUID=' + offerFamilyFixedUID.toString() + argParam
        const payload = {
          gameUIDs: gameUIDs
        }
        axiosService.post(url, payload).then(response => {
          this.offers = response.data.results
        }).catch(error => {
          console.error('error get offers uids', error)
        }).finally(() => {
          this.offersAreLoading = false
        })
      }, 250)
    },
  },
  getters: {

    isOffersAreLoading: (state) => () => {
      return state.offersAreLoading
    },

    buildBaseWidgetPayload: (state) => () => {
      const arjelOnly = preferencesStore().getBoolean('BETTING', 'ARJEL_ONLY', null)
      return {
        leagueUIDs: state.selectedLeagueUIDs,
        filter: state.filter,
        arjelOnly: arjelOnly,
        dateInterval: statsPaneStore().dateInterval,
        sport: contextStore().getSport,
      }
    },

    getOverContext: (state) => {
      return state.overContext
    },

    getSelectedPerspectiveUID: (state) => () => {
      return state.selectedPerspectiveUID
    },

    getResults: (state) => () => {
      const results = []
      Object.keys(state.widgetsData).forEach(widgetUID => {
        state.widgetsData[widgetUID].forEach(item => {
          const r = {
            gameUID: item.gameUID,
            leagueUID: item.leagueUID,
            gameDatetime: item.gameDate,
            offers: state.offers[item.gameUID] || []
          }
          // If results do not contain the gameUID, we add it
          if (!results.find(result => result.gameUID === r.gameUID)) {
            results.push(r)
          }
        })
      })

      // remove duplicates
      const singletonResults = [...new Set(results)]

      // Pour chaque résultat, on va calculer une note de correspondance
      const statsMapByGameUID = {}
      const minMaxValueByWidgetUID = {}
      Object.keys(state.widgetsData).forEach(widgetUID => {
        let orderBy = perspectiveStore().getParameterValue(widgetUID, 'order_by')

        state.widgetsData[widgetUID].forEach(item => {
          let rawValue = null
          switch (orderBy) {
            case 'RELATIVE':
              rawValue = item.percentage
              break
            case 'ABSOLUTE':
              rawValue = item.absolute
              break
          }
          if (!statsMapByGameUID[item.gameUID]) {
            statsMapByGameUID[item.gameUID] = []
          }
          statsMapByGameUID[item.gameUID].push({
            widgetUID: widgetUID,
            rawValue: rawValue,
          })

          if (!minMaxValueByWidgetUID[widgetUID]) {
            minMaxValueByWidgetUID[widgetUID] = {
              min: rawValue,
              max: rawValue
            }
          } else {
            if (minMaxValueByWidgetUID[widgetUID].min > rawValue) {
              minMaxValueByWidgetUID[widgetUID].min = rawValue
            }
            if (minMaxValueByWidgetUID[widgetUID].max < rawValue) {
              minMaxValueByWidgetUID[widgetUID].max = rawValue
            }
          }
        })
      })

      // On normalise les valeurs
      Object.keys(statsMapByGameUID).forEach(gameUID => {
        statsMapByGameUID[gameUID].forEach(stat => {
          let widgetUID = stat.widgetUID
          let rawValue = stat.rawValue
          let minMaxValue = minMaxValueByWidgetUID[widgetUID]
          stat.normalizedValue = (rawValue - minMaxValue.min) / (minMaxValue.max - minMaxValue.min)
        })
      })

      // Pour chaque résultat, on va calculer une note de correspondance en additionnant les normalizedValue
      let minMatchingScore = 999999
      let maxMatchingScore = 0
      singletonResults.forEach(result => {
        let gameUID = result.gameUID
        let stats = statsMapByGameUID[gameUID]
        let totalNormalizedValue = 0
        stats.forEach(stat => {
          totalNormalizedValue += stat.normalizedValue
        })

        result.rawMatchingScore = totalNormalizedValue
        if (totalNormalizedValue < minMatchingScore) {
          minMatchingScore = totalNormalizedValue
        }
        if (totalNormalizedValue > maxMatchingScore) {
          maxMatchingScore = totalNormalizedValue
        }
      })

      // On normalise les matchingScore
      singletonResults.forEach(result => {
        result.normalizedMatchingScore = (result.rawMatchingScore - minMatchingScore) / (maxMatchingScore - minMatchingScore)
      })

      return singletonResults
    },

    getGameMatchingData: (state) => (gameUID) => {
      const results = []

      if (!gameUID) {
        return results
      }

      const gameUIDAsInt = parseInt(gameUID)

      Object.keys(state.widgetsData).forEach(widgetUID => {
        state.widgetsData[widgetUID].forEach(item => {
          if (item.gameUID === gameUIDAsInt) {
            item.widgetUID = parseInt(widgetUID)
            item.period = perspectiveStore().getParameterValue(widgetUID, 'period')
            results.push(item)
          }
        })
      })
      return results
    },

    getOfferFamilyFixedUID: (state) => () => {
      let offerFamilyFixedUID = state.offerFamilyFixedUID
      if (!offerFamilyFixedUID) {
        const ps = preferencesStore()
        const cs = contextStore()
        offerFamilyFixedUID = ps.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_' + cs.getSport);
      }
      return offerFamilyFixedUID
    },

    getOfferArgument: (state) => () => {
      let offerArgument = state.offerArgument
      if (!offerArgument) {
        const ps = preferencesStore()
        const cs = contextStore()
        offerArgument = ps.getFirstValue('BETTING', 'FAVORITE_OFFER_FAMILY_ARG_' + cs.getSport)
      }

      return offerArgument
    },
  }
})