<script>

import Statistic from "@/components/common/Statistic.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameHour from "@/components/common/GameHour.vue";
import GameDate from "@/components/common/GameDate.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import AreaFlag from "@/components/common/AreaFlag.vue";
import OfferChoiceButton from "@/components/common/OfferChoiceButton.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {gameStore} from "@/stores/Game.store";
import {contextStore} from "@/stores/Context.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {leagueStore} from "@/stores/League.store";
import LeagueName from "@/components/common/LeagueName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";

export default {
  name: "SelectionPaneItemLine1",
  components: {
    LeagueLogo,
    LeagueName,
    FontAwesomeIcon,
    OfferChoiceButton,
    TeamLogo,
    GameDate,
    GameHour,
    AreaFlag,
    TeamName,
    Statistic,
  },
  props: {
    gameUid: {
      type: Number,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    _gameStore() {
      return gameStore();
    },
    _contextStore() {
      return contextStore();
    },
    _game() {
      if (!this.gameUid) return null
      return this._gameStore.get(this.gameUid)
    },
    _leagueUID() {
      if (!this._game) return null
      return this._game.leagueUID
    },
    _league() {
      if (!this._leagueUID) return null
      return leagueStore().get(this._leagueUID)
    },
    _gameArea() {
      if (!this._league) return null
      return this._league.area
    },
    _getGameHomeTeamUID() {
      if (!this._game) return null
      return this._gameStore.getHomeTeamUID(this._game)
    },
    _getGameAwayTeamUID() {
      if (!this._game) return null
      return this._gameStore.getAwayTeamUID(this._game)
    },
    _isSelected() {
      return selectionPaneStore().getSelectedGameUID() === this.gameUid;
    },
    _homeIsFav() {
      if (!this._game) return false
      if (!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart < -0.199
    },
    _homeIsHyperFav() {
      if (!this._game) return false
      if (!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart < -0.399
    },
    _awayIsFav() {
      if (!this._game) return false
      if (!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart > 0.199
    },
    _awayIsHyperFav() {
      if (!this._game) return false
      if (!this._game.odd1x2Ecart) return false
      return this._game.odd1x2Ecart > 0.399
    },
  },

  methods: {}
}
</script>

<template>

  <div class="game-item-header">
    <div class="game-item-header-top">
      <area-flag :area="_gameArea" class="ml-1 inline-block" size="small"/>
      <league-logo :uid="_leagueUID" size="small" style="margin-top: 1px"/>
      <league-name :uid="_leagueUID" class="ml-1 inline-block" size="small" :show-area="true" :show-arjel="true"/>
    </div>
    <div class="game-item-header-middle">
      <div class="game-item-header-middle-left">
        <team-name :uid="_getGameHomeTeamUID" class="team-home"/>
      </div>
      <div class="game-item-header-middle-center">
        <team-logo :uid="_getGameHomeTeamUID"/>
        <div class="separator"> -</div>
        <team-logo :uid="_getGameAwayTeamUID"/>
      </div>
      <div class="game-item-header-middle-right">
        <team-name :uid="_getGameAwayTeamUID" class="team-away"/>
      </div>
    </div>
    <div class="game-item-header-bottom">
      <div class="game-item-header-bottom-left">
        <img v-if="_homeIsFav" src="/images/icons/fire.gif" style="height: 12px; width: 12px;" alt="" class="team-home-fav"/>
        <img v-if="_homeIsHyperFav" src="/images/icons/fire.gif" style="height: 12px; width: 12px;" alt="" class="team-home-fav"/>
      </div>
      <div class="game-item-header-bottom-center">
        <game-date v-if="_game" :uid="gameUid" class="inline-block" show-icon size="small" show-hour/>
      </div>
      <div class="game-item-header-bottom-right">
        <img v-if="_awayIsHyperFav" src="/images/icons/fire.gif" style="height: 12px; width: 12px;" alt="" class="team-home-fav"/>
        <img v-if="_awayIsFav" src="/images/icons/fire.gif" style="height: 12px; width: 12px;" alt="" class="team-home-fav"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.game-item-header {
  padding: @padding-small 0 0 0;
}

.game-item-header-top, .game-item-header-bottom {
  font-size: 8pt;
  color: @text-color-dark;
  display: flex;
  // align center horizontally
  justify-content: center;
}

@bottom-center-width: 100px;

.game-item-header-bottom {

  .game-item-header-bottom-left, .game-item-header-bottom-right {
    width: calc(100% - @bottom-center-width) !important;
    max-width: calc(100% - @bottom-center-width) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
    height: 24px;

    img {
      display: inline-block;
    }
  }

  .game-item-header-bottom-left {
    text-align: right;
    padding-right: @padding-regular;
  }

  .game-item-header-bottom-right {
    text-align: left;
    padding-left: @padding-regular;
  }


  .game-item-header-bottom-center {
    width: @bottom-center-width;
    min-width: @bottom-center-width;
    text-align: center;
    line-height: 24px;
    height: 24px;
  }

}

.game-item-header-middle {
  color: @text-color-dark;
  display: flex;
  padding: @padding-regular 0 calc(@padding-regular / 2) 0;
  justify-content: center;

  .game-item-header-middle-left, .game-item-header-middle-right {
    width: calc(100% - 24px * 3 - 16px) !important;
    max-width: calc(100% - 24px * 3 - 16px) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
  }

  .game-item-header-middle-left {
    text-align: right;
  }

  .game-item-header-middle-right {
    text-align: left;
  }

  .game-item-header-middle-center {
    display: flex;
    width: calc(24px * 3 + 8px);
    margin: 0 8px;
    text-align: center;
  }

  .separator {
    width: 24px;
    text-align: center;
    line-height: 24px;
  }
}

.line1 {
  display: flex;
  flex-direction: row;
  padding: @padding-small;
  border-radius: @radius-regular @radius-regular 0 0;

  img {
    display: inline-block;
  }

  .label {
    flex-grow: 1;
    text-align: center;

    .team-name {
      position: absolute;
      max-width: 45%;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 160px;
      white-space: nowrap;

      &.team-home {
        left: 0;
        text-align: left;
      }

      &.team-home-fav {
        right: 0;
        text-align: right;
      }

      &.team-away {
        right: 0;
        text-align: right;
      }
    }

  }
}
</style>