<script>
import TeamLogo from "@/components/common/TeamLogo.vue";
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import axiosService from "@/services/axios.service";
import TeamName from "@/components/common/TeamName.vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {statStore} from "@/stores/Statistic.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import LeagueName from "@/components/common/LeagueName.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import ArjelLogo from "@/components/common/ArjelLogo.vue";
import {leagueStore} from "@/stores/League.store";
import AreaFlag from "@/components/common/AreaFlag.vue";
import PlayerName from "@/components/common/PlayerName.vue";
import PlayerPicture from "@/components/common/PlayerPicture.vue";

export default {
  name: "RawStatsWidget",
  components: {
    PlayerPicture,
    PlayerName,
    AreaFlag,
    ArjelLogo,
    WidgetLoadingContent,
    LeagueLogo,
    FontAwesomeIcon,
    LeagueName,
    WidgetTeamParamButton,
    WidgetLocationParamButton,
    WidgetLeagueParamButton,
    WidgetPeriodParamButton,
    TeamName, TeamLogo
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      refreshTimeout: null,
      data: null,
      hasMoreResult: false,

      // Gestion du click long sur une ligne
      rowClickTimer: null,
    }
  },

  mounted() {
    window.emitter.on('stats-route:widgets-need-to-refresh', () => {
      this._refresh()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
    this._refresh()
  },

  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _currentPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _sdef(){
      if(!this._statFixedUID) return null
      return statStore().getDefinitionByFixedUID(this._statFixedUID)
    },

    _statScope() {
      if (!this._sdef) return null;
      return this._sdef.scope;
    },

    _statLabel() {
      if (!this._sdef) return '...'
      return this._sdef.name
    },

    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this.item.uid)
    },
    _statFixedUID() {
      return perspectiveStore().getParameterValue(this.item.uid, 'stat_fixed_uid')
    },
    _statType() {
      if (!this._sdef) return null
      return this._sdef.type
    },
    _overContext() {
      return statsRouteStore().getOverContext
    },
    _sortedData() {
      if (!this.data) return []
      // On crée une copie des data pour ne pas modifier le state
      const sortedData = [...this.data]
      return sortedData.sort((a, b) => {
        if (b.absolute - a.absolute) return 1
        if (a.absolute - b.absolute) return -1

        return a.gameDate - b.gameDate
      })
    },

    _hasFullLicence() {
      return contextStore().hasFullLicence();
    },
  },
  watch: {
    _statFixedUID() {
      this._refresh()
    },
    _widget() {
      this._refresh()
    }
  },

  methods: {

    _refresh() {

      if(!this._widget) {
        return
      }

      const isInCurrentPerspective = this._widget.perspectiveUID === this._currentPerspectiveUID
      if(!isInCurrentPerspective) {
        return
      }

      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.statFixedUID = this._statFixedUID
      payload.location = perspectiveStore().getParameterValue(this.item.uid, 'location')
      payload.period = perspectiveStore().getParameterValue(this.item.uid, 'period')
      payload.league = perspectiveStore().getParameterValue(this.item.uid, 'league')
      payload.orderBy = perspectiveStore().getParameterValue(this.item.uid, 'order_by')
      payload.orderByDirection = perspectiveStore().getParameterValue(this.item.uid, 'order_by_direction')

      axiosService.put('/wid/stats/raw', payload).then(response => {
        this.data = response.data.results
        this.hasMoreResult = response.data.hasMoreResult

        statsRouteStore().setWidgetData(this.item.uid, this.data)
      }).catch(error => {
        console.error(error)
      })
    },

    _percentage(row) {
      if (!row) return '-'
      if (!row.percentage) return '-'
      return (row.percentage).toFixed(2)
    },

    _absolute(row) {
      if (!row) return '-'
      if (!row.absolute) return '-'
      return row.absolute
    },

    _locationIcon(row) {
      if (!row) return '-'
      if (row.location === 'HOME') return 'fa-solid fa-house'
      if (row.location === 'AWAY') return 'fa-solid fa-plane'
      return 'fa-solid fa-question'
    },

    _updateOverContext(row, pin = false) {
      statsRouteStore().setOverContext({
        statUID: row.statUID,
        teamUID: row.teamUID,
        gameUID: row.gameUID,
        leagueUID: row.leagueUID
      }, pin)
    },

    _rowClasses(row) {
      if (!this._overContext) return {
        'anonymous' : !row.teamUID,
      }
      return {
        'anonymous' : !row.teamUID,
        'over-context-team': this._overContext.teamUID && this._overContext.teamUID === row.teamUID,
        'over-context-game': this._overContext.gameUID && this._overContext.gameUID === row.gameUID,
        'over-context-league': this._overContext.leagueUID && this._overContext.leagueUID === row.leagueUID
      }
    },

    _openStatSelectorModal() {
      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          category:'stat',
          statFixedUID: this._statFixedUID,
          type: this._statType,
          sport: 'FOOTBALL',
          callback: (categorie, sdef) => {
            // Si non authentifié, message d'erreur
            if (!contextStore().isAuthenticated()) {
              message.error('Tu dois être connecté pour personnalisé les statistiques');
              return;
            }
            const parameterDefinition = this._parameters.find(p => p.key === 'stat_fixed_uid')

            perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': sdef.fixedUID})
            perspectiveStore().saveWidgetParameters(this.item.uid)
          }
        }

      });
    },

    _expend(){

      if(!this._hasFullLicence){
        message.error('Tu dois être abonné à l\'offre ULTIMATE pour voir plus de résultats');
        return;
      }

      modalStore().openModal({
        uid: "rawStatsModal",
        payload: {
          widgetUID: this.item.uid,
          statFixedUID: this._statFixedUID
        }
      });
    },

    _startClickTimer(row) {
      if (!row.gameUID) {
        return
      }
      clearTimeout(this.rowClickTimer)
      this.rowClickTimer = null
      const self = this
      this.rowClickTimer = setTimeout(() => {
        self._updateOverContext(row, true)
        this.rowClickTimer = null
      }, 400)
    },

    _endClick(row) {
      if(this.rowClickTimer) {
        clearTimeout(this.rowClickTimer)
        this.rowClickTimer = null
      }

      if (!row.gameUID) {
        message.info("Tu dois être connecté pour accéder aux détails du match")
        return
      }
      modalStore().openModal({
        uid: "gameDetailsModal",
        payload: {
          gameUID: row.gameUID
        }
      });
    },

    _leagueArjel(leagueUID) {
      const league = leagueStore().get(leagueUID)
      if(!league) return false
      return league.isArjel === true
    },

    _leagueArea(leagueUID) {
      const league = leagueStore().get(leagueUID)
      if (!league) return null
      return league.area
    }
  }
}
</script>

<template>
  <div class="widget team-event-stats">
    <div class="widget-header flex" style="padding: 0 !important;">
      <button class="small no-radius flex-grow text-left" @click="_openStatSelectorModal" style="height: 28px">
        {{ _statLabel }}
      </button>
      <div class="widget-header-params flex">
        <widget-league-param-button :widget="item"/>
        <widget-period-param-button :widget="item"/>
        <widget-location-param-button :widget="item"/>
      </div>
    </div>
    <div class="widget-content">
      <table v-if="data">
        <tbody>
        <tr v-for="r in _sortedData" @mouseenter="_updateOverContext(r)"
            @mousedown="_startClickTimer(r)" @mouseup="_endClick(r)"
            :class="_rowClasses(r)">
          <td class="logo">
            <area-flag :area="_leagueArea(r.leagueUID)" size="small" style="display: inline-block"/>
          </td>
          <td class="logo">
            <team-logo :uid="r.teamUID" size="small"/>
          </td>
          <td class="name">
            <team-name :uid="r.teamUID" :anonymous="!r.teamUID" v-if="_statScope === 'TEAM'"/>
            <player-name :uid="r.playerUID" :anonymous="!r.playerUID" v-if="_statScope === 'PLAYER'"/>
            <span class="against-data">
              <arjel-logo :height="10" style="display: inline-block" v-if="_leagueArjel(r.leagueUID)"/>
              <team-name :uid="r.againstTeamUID"  :anonymous="!r.againstTeamUID"/>
              <font-awesome-icon :icon="_locationIcon(r)"/>
              <league-logo :uid="r.leagueUID" size="small" class="ml-1"/>
            </span>
          </td>
          <td class="relative-value">{{ _percentage(r) }}</td>
          <td class="absolute-value">{{ _absolute(r) }}</td>
        </tr>
        </tbody>
      </table>
      <button v-if="hasMoreResult" class="w-full small" @click="_expend">+ de résultats</button>
      <widget-loading-content v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.team-event-stats {
  overflow: hidden;

  .widget-header {
    background: -webkit-linear-gradient(0deg, @selected-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);
  }

  .widget-content {
    height: auto;
    overflow: hidden;
  }
}

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }

  .relative-value {
    width: 30px;
    text-align: right;
    color: @text-color-dark;
  }

  .absolute-value {
    width: 29px;
    text-align: right;
  }

  tr {
    cursor: pointer;

    &.over-context-league {
      background: fade(@hover-color, 50%);
    }

    &.over-context-game {
      background: fade(@selected-color, 75%);
    }

    &.over-context-team {
      background: fade(@primary-color, 40%);
    }

    &:hover {
      background: fade(@primary-color, 40%);
    }

    &.anonymous{
      opacity: 0.25;
    }

    .against-data {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 7pt;
      color: @text-color-dark;
    }
  }
}

</style>