<script>
import {loadScript} from "@paypal/paypal-js";
import axiosService from "@/services/axios.service";
import {modalStore} from "@/stores/Modal.store";
import {message} from "ant-design-vue";
import {leagueStore} from "@/stores/League.store";
import {contextStore} from "@/stores/Context.store";
import {userStore} from "@/stores/User.store";

export default {
  name: "PaypalModal",
  data() {
    return {
      prices: null,
      appliedCodePromo: "",
      codePromo: ""
    }
  },
  computed: {
    _isAuthenticated() {
      return contextStore().isAuthenticated()
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'paypalModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.payload;
    },
    _formule() {
      if (!this._payload) return null
      return this._payload.formule
    },
    _pricing() {
      if (!this.prices) return null
      if (this._formule === 'BASIC') {
        return this.prices.basic
      } else if (this._formule === 'FULL') {
        return this.prices.full
      } else {
        return null
      }
    },
    _amountTotal() {
      if (!this._pricing) return '...'
      return this._pricing.amountTotal
    },
    _fullAmountTotal() {
      if (!this.prices) return null
      if(!this.prices.full) return null
      return this.prices.full.amountTotal
    },
    _amountFromAccount() {
      if (!this._pricing) return '...'
      return this._pricing.amountFromAccount
    },
    _amountFromPaypal() {
      if (!this._pricing) return '...'
      return this._pricing.amountFromPaypal
    },
    _codePromoMessage() {
      if (!this.prices) return null
      if (!this.prices.codePromo) return null
      let reduction = (this.prices.codePromo.reduction * 100.0).toFixed(0)
      return this.prices.codePromo.description + ' : -' + reduction + '%'
    },
    _formuleLabel() {
      if (!this._formule) return '...'
      switch (this._formule) {
        case 'BASIC':
          return 'Pro'
        case 'FULL':
          return 'Ultimate'
        case 'TEST':
          return 'Test'
        default:
          return '...'
      }
    },
    _canTakeUltimate() {
      return this._formule === 'BASIC' && this.prices !== null
    }
  },
  mounted() {
    this._refreshPrices()
  },
  methods: {
    _onCancel() {
      modalStore().closeModal()
    },

    _refreshPrices() {
      this._destroyPaypalButton()
      let codePromoArg = this.appliedCodePromo ? '?c=' + this.appliedCodePromo : ''
      // get prices from the server
      axiosService.get('/fin/prices' + codePromoArg)
          .then(response => {
            this.prices = response.data;
            this.$nextTick(() => {
              this._buildPaypalButton()
            })
          })
          .catch(error => {
            console.error('Cannot load prices', error);
          });
    },

    _destroyPaypalButton() {
      const paypalButton = document.getElementById('paypal-button-container')
      if (paypalButton) {
        paypalButton.innerHTML = ''
      }
    },

    _buildPaypalButton() {

      const paypalButton = document.getElementById('paypal-button-container')
      if(!paypalButton) {
        console.warn('Cannot create paypal button, container not found')
        return
      }

      loadScript({
        "client-id": "ASDjFKEDe0LYb4soGXUf-vjv00iwxdq-_SiSikMvjMmjV_UPi-1Fnj7p8QQK7bIjLscSm61dj2L02gov",
        currency: "EUR",
        integrationDate: "2024-06-11",
        disableFunding: "bancontact,sofort",
      })
          .then((paypal) => {

            const formule = this._formule
            const codePromo = this.appliedCodePromo

            paypal.Buttons({
              style: {
                layout: 'vertical',
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
                height: 40
              },
              createOrder: function (data, actions) {
                let payload = {licenceType: formule, codePromo: codePromo};
                return axiosService.post('/pay/order', payload)
                    .then(response => {
                      console.debug('Paypal order created', response.data)
                      return response.data.orderId;
                    })
                    .catch(error => {
                      message.error('Erreur lors de la création de la commande')
                      console.error('Cannot create paypal order', error);
                    });
              },
              onApprove: function (data, actions) {
                const _modalStore = modalStore()
                const _router = this.$router
                return axiosService.post('/pay/order/' + data.orderID)
                    .then(response => {
                      // On recharge la page pour mettre à jour les licences
                      message.success('Commande validée')

                      _modalStore.closeModal()

                      // On redirige vers la page d'accueil
                      setTimeout(() => {
                        if (_router) {
                          _router.push({name: 'init'})
                        }
                      }, 1000)
                    })
                    .catch(error => {
                      message.error('Erreur lors de la validation de la commande')
                      console.error('paypal approve', error);
                    });
              }
            }).render("#paypal-button-container");
          });
    },

    _applyCodePromo() {
      console.log('Apply code promo', this.codePromo)
      this.appliedCodePromo = this.codePromo
      this._refreshPrices()
    },

    _seeUltimate() {
      modalStore().closeModal()
      modalStore().openModal({
        uid: 'paypalModal', payload: {
          formule: 'FULL'
        }
      })
    },

    _showLeagues() {
      modalStore().openModal({
        uid: 'licenceLeagueModal'
      })
    },

    _countLeagues(sport, formule) {
      let leagues = leagueStore().getBySportAndLicenceType(sport, formule);
      if (!leagues) return 0
      return leagues.length
    },
    _countLeaguesByFormule(formule) {
      let leagues = leagueStore().getByLicenceType(formule);
      if (!leagues) return 0
      return leagues.length
    },
    _signWithGoogle() {
      userStore().signInWithGoogle()
    },
  },
  watch: {
    _isVisible(isVisible) {
      if (isVisible) {
        this.codePromo = ''
        this.appliedCodePromo = ''

        if (!this._isAuthenticated) {
          // On ne peut pas payer si on n'est pas connecté
          return
        }

        this._refreshPrices()
      }
    }
  }
}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="400" @cancel="_onCancel" :class="{'mobile': _isMobile}">
    <header class="ant-modal-header">
      Achat licence {{ _formuleLabel }}
    </header>

    <div class="content">

      <div class="m-2 text-center">
        <fieldset class="formule">
          <legend>Avantages</legend>

          <ul v-if="_formule === 'BASIC'">
            <li>
              Bonus de parrainage de <b>10% (+5% niveau 2)</b>.
            </li>
            <li>
              Accès à toutes les rencontres sur <b>7 jours</b>.
            </li>
            <li>
              Accès à <b>{{ _countLeagues('FOOTBALL', 'BASIC') }} ligues</b> Football
            </li>
          </ul>

          <ul v-if="_formule === 'FULL'">
            <li>
              Bonus de parrainage de <b>15% (+10% niveau 2)</b>.
            </li>
            <li>
              Accès à toutes les rencontres sur <b>7 jours</b>.
            </li>
            <li>
              Accès à <b>{{ _countLeagues('FOOTBALL', 'FULL') }} ligues</b> Football
            </li>
          </ul>

          <button class="info small mt-2" @click.stop="_showLeagues()" style="width: 100%">
            <font-awesome-icon icon="fa-solid fa-trophy"/>
            Voir les compétitions incluses
          </button>
        </fieldset>

      </div>
      <a-alert v-if="_formule === 'BASIC'"
               message="Pour un accès illimité, opte pour l'offre ULTIMATE." type="info" class="m-2"/>
      <table>
        <tbody>
        <tr>
          <th class="text-left px-2">Prix de base</th>
          <td>{{ _amountTotal }}€</td>
        </tr>
        <tr>
          <th class="text-left px-2">Crédit</th>
          <td>{{ _amountFromAccount }}€</td>
        </tr>
        <tr>
          <th class="text-left px-2">Reste à payer</th>
          <td><b>{{ _amountFromPaypal }}€</b></td>
        </tr>
        </tbody>
      </table>
      <hr/>
      <fieldset class="mx-1 my-2 no-border">
        <legend>Code promo</legend>
        <div class="flex">
          <input v-model="codePromo" placeholder="Code promo" class="flex-grow mr-2"/>
          <button class="small info" @click="_applyCodePromo()">Appliquer</button>
        </div>
        <div class="mt-1">
          <a-alert type="success" v-if="_codePromoMessage" :message="_codePromoMessage"/>
          <a-alert type="error" v-if="appliedCodePromo && !_codePromoMessage"
                   message="Le code promo n'a pas pu être appliqué"/>
        </div>
      </fieldset>

      <div id="paypal-button-container" class="px-2 mt-2"></div>
      <a-alert v-if="_isAuthenticated"
               message="Conformément à nos conditions générales de vente, aucun remboursement ne sera effectué."
               class="m-2" type="warning" show-icon/>

      <div v-if="!_isAuthenticated" class="m-2">
        <a-alert message="Tu dois te connecter pour acheter ta licence." type="info" show-icon/>
        <div class="flex">

        </div>
      </div>

    </div>

    <template #footer>
      <div class="m-2 flex">
        <button class="accent" @click="_seeUltimate" v-if="_fullAmountTotal && _canTakeUltimate && _isAuthenticated">Passer ULTIMATE
          ({{ _fullAmountTotal }}€)
        </button>
        <button class="primary flex-grow" @click="_signWithGoogle()" v-if="!_isAuthenticated">
          <font-awesome-icon icon="fa-solid fa-right-to-bracket"/>
          Connexion avec Google
        </button>
        <div class="flex-grow"/>
        <button class="" @click="_onCancel">Annuler</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.formule {
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  text-align: left;

  ul {
    margin: 0;
    padding-left: @padding-regular;

    li {
      list-style-type: disc;
      margin-left: 1rem;
      color: @text-color;
      font-size: @font-size-small;
    }
  }
}
</style>