<script>
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {modalStore} from "@/stores/Modal.store";
import {message} from "ant-design-vue";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";

export default {
  name: "PerspectiveSelectionModal",

  data() {
    return {
      form: {},
    }
  },

  computed: {
    _isVisible() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return false;
      return visibleModal.uid === 'perspectiveSelectionModal';
    },
    _payload() {
      let visibleModal = modalStore().getVisibleModal;
      if (!visibleModal) return null;
      return visibleModal.payload
    },
    _perspectives() {
      if (!this._payload) return []
      return perspectiveStore().getBySportAndType(contextStore().getSport, this._payload.type)
    }
  },

  methods: {
    _onCancel() {
      modalStore().closeModal();
    },
    _isSelected(uid) {
      return uid === statsRouteStore().getSelectedPerspectiveUID()
    },
    _setSelectedUID(uid) {
      statsRouteStore().setSelectedPerspectiveUID(uid)
      this._onCancel()
    },
    _create() {
      if (!contextStore().isAuthenticated()) {
        message.error('Tu dois être connecté pour créer une perspective')
        return
      }

      // Limite lié a ta licence
      const allPerspectives = perspectiveStore().getBySportAndType(this._selectedSport, 'STATS')
      switch (contextStore().getLicenseType()) {
        case 'NONE':  // si pas de licence, impossible de créer une perspective
          message.warn("Sans licence, tu ne peux pas créer de perspective.")
          return
        case 'BASIC':  // si pas de licence ULTIMATE, limiter le nombre de perspectives à 2
          if (allPerspectives.length >= 2) {
            message.warn("Sans licence ULTIMATE, tu ne peux pas avoir plus de 2 perspectives.")
            return
          }
          break
        case 'FULL': // si licence ULTIMATE, on limite a 8
          if (allPerspectives.length >= 8) {
            message.warn("Il n'est pas possible d'avoir plus de 8 perspectives.")
            return
          }
          break
      }

      const sport = contextStore().getSport
      perspectiveStore().create('STATS', sport, (perspective) => {
        this._showPerspectiveSettingsModal(perspective, true)
      })
    },
    _showPerspectiveSettingsModal(perspective, creation = false) {
      if (perspective.userUID !== contextStore().getLoggedUserUID()) {
        return
      }

      modalStore().openModal({
        uid: 'perspectiveSettingsModal', payload: {
          uid: perspective.uid,
          creation: creation
        }
      })
    }
  }

}
</script>

<template>
  <AModal v-if="_isVisible" :open="true" :width="500" @cancel="_onCancel">
    <header class="ant-modal-header">
      Choix d'une perspective
    </header>

    <div :class="{'perspective-item m-1': true, 'selected': _isSelected(perspective.uid)}" v-for="perspective in _perspectives" :key="perspective.uid" @click="_setSelectedUID(perspective.uid)">
      <label>{{ perspective.name }}</label>
      <p>{{perspective.description}}</p>
    </div>

    <template #footer>
      <div class="m-2 flex">
        <div class="flex-grow"></div>
        <button class="primary" @click="_create">Créer une perspective</button>
      </div>
    </template>

  </AModal>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.perspective-item {
  padding: @padding-regular;
  cursor: pointer;
  text-align: left;
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border: 1px solid @stroke-color;
  border-radius: @radius-regular;
  flex: 1 1 320px; /* permet aux div de croître et se contracter, avec une base de 350px */
  box-sizing: border-box;
  max-width: calc(100%);

  &.selected {
    border-top: 1px solid @accent-color;
    background: linear-gradient(90deg, #494081aa 0%, #49408199 33%, #49408133 50%, #FFFFFF00 100%) !important;
  }

  &:hover {
    border-top: 1px solid #f97f28;
    background: linear-gradient(90deg, #f97f2877 0%, #f97f2855 33%, #fea32822 50%, #FFFFFF00 100%) !important;
  }

  label {
    font-size: 14px;
    color: @text-color;
    font-weight: bold;
    margin: 0;
  }

  p{
    font-size: 12px;
    color: @text-color;
    margin: 0;
  }
}
</style>