<script>
import axiosService from "@/services/axios.service";
import GameDate from "@/components/common/GameDate.vue";
import GameEvent from "@/components/common/GameEvent.vue";
import GameScore from "@/components/common/GameScore.vue";
import IndicateurCircle from "@/components/common/IndicateurCircle.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import TeamName from "@/components/common/TeamName.vue";
import LeagueLogo from "@/components/common/LeagueLogo.vue";
import {playerStore} from "@/stores/Player.store";

export default {
  name: "PlayerPaneItem.H2H.Tab",
  components: {LeagueLogo, TeamName, GameResultCircle, TeamLogo, GameEvent, GameDate, IndicateurCircle, GameScore},
  props: {
    playerUid: {
      required: true
    },
    gameUid: {
      required: true
    }
  },

  data() {
    return {
      data: null,

      filters: {
        type: 'ALL',
        moment: 'FT',
        league: 'ANY'
      },
    }
  },
  beforeMount() {
    this._refreshData()
  },

  computed: {
    _events() {
      if (!this.data) return []
      const results = [...this.data.events]
      return results.sort((a, b) => {
        return new Date(a.date) - new Date(b.date)
      })
    },
    _games() {
      if (!this.data) return []
      return this.data.games
    },
    _sortedGames() {
      if (!this._games) return []
      return this._games.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },
    _player() {
      return playerStore().get(this.playerUid)
    },
  },

  methods: {
    _refreshData() {
      const payload = {
        gameUID: this.gameUid,
        playerUID: this.playerUid
      }
      axiosService.put('/dta/player/basic-data/h2h', payload).then(response => {
        this.data = response.data
      })
    },
    _gameEvents(game) {
      return this._events.filter(e => e.gameUID === parseInt(game.uid))
    },
    _homeScore(game) {
      if (!game) return '?'
      switch (this.filters.moment) {
        case 'FT':
          return game.scoreFtHome
        case 'P1':
          return game.scoreP1Home
        case 'P2':
          return game.scoreP2Home
        case 'P3':
          return game.scoreP3Home
        case 'P4':
          return game.scoreP4Home
        default:
          return '?'
      }
    },
    _awayScore(game) {
      if (!game) return '?'
      switch (this.filters.moment) {
        case 'FT':
          return game.scoreFtAway
        case 'P1':
          return game.scoreP1Away
        case 'P2':
          return game.scoreP2Away
        case 'P3':
          return game.scoreP3Away
        case 'P4':
          return game.scoreP4Away
        default:
          return '?'
      }
    },
    _playerTeamUID(game) {
      if (!this._player) return null
      if (!game) return null
      if (game.homeTeamUID === this._player.domesticTeamUID || game.homeTeamUID === this._player.nationalTeamUID) {
        return game.homeTeamUID
      }
      return game.awayTeamUID
    },
  },
}
</script>

<template>
  <div>
    <fieldset class="no-border text-left mt-2">
      <legend class="m-0 ml-1" style="height: 22.5px; line-height: 22.5px">Historique des tête-à-tête</legend>

      <div v-for="g in _sortedGames">
        <table :class="{'last-player-game-row': true}">
          <tbody>
            <tr>
              <td class="date" rowspan="2">
                <game-date :uid="g.uid"/>
              </td>
              <td class="league" rowspan="2">
                <league-logo :uid="g.leagueUID"/>
              </td>
              <td class="team-logo">
                <team-logo :uid="g.homeTeamUID" size="small"/>
              </td>
              <td class="team-name">
                <team-name :uid="g.homeTeamUID"/>
              </td>
              <td class="events" rowspan="2">
                <game-event :item="e" style="margin-right: -6px; display: inline-block" v-for="e in _gameEvents(g)"/>
              </td>
              <td class="score">{{ _homeScore(g) }}</td>
              <td class="result" rowspan="2">
                <game-result-circle :team-uid="_playerTeamUID(g)" :game-uid="g.uid" :moment="filters.moment"/>
              </td>
            </tr>
            <tr>
              <td class="team-logo">
                <team-logo :uid="g.awayTeamUID" size="small"/>
              </td>
              <td class="team-name">
                <team-name :uid="g.awayTeamUID"/>
              </td>
              <td class="score">{{ _awayScore(g) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table.last-player-game-row {
  border-bottom: 1px solid @stroke-color;

  tr, td {
    border: none;
    padding: 0;
    background: transparent;
  }

  &.over {
    background-color: @hover-color;
  }

  .date {
    width: 64px;
  }

  .league {
    width: 32px;
  }

  .team-logo {
    width: 32px;
  }

  .team-name {
    text-align: left;
  }

  .events {
    width: 64px;
    text-align: right;
  }

  .score {
    width: 32px;
  }

  .result {
    width: 36px;
    padding: @padding-regular;
  }
}
</style>