<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import StatisticValueButton from "@/components/common/StatisticValueButton.vue";
import TeamLogo from "@/components/common/TeamLogo.vue";
import GameResultCircle from "@/components/common/GameResultCircle.vue";
import {gameStore} from "@/stores/Game.store";
import TeamName from "@/components/common/TeamName.vue";
import axiosService from "@/services/axios.service";
import {contextStore} from "@/stores/Context.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import WidgetPeriodParamButton from "@/components/common/widgets/parameter/WidgetPeriodParamButton.vue";
import WidgetTeamParamButton from "@/components/common/widgets/parameter/WidgetTeamParamButton.vue";
import WidgetLocationParamButton from "@/components/common/widgets/parameter/WidgetLocationParamButton.vue";
import WidgetLeagueParamButton from "@/components/common/widgets/parameter/WidgetLeagueParamButton.vue";
import {teamStore} from "@/stores/Team.store";
import {widgetDataStore} from "@/stores/WidgetData.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";

export default {
  name: "IndicateurFormeClubWidget",
  components: {
    WidgetLoadingContent,
    WidgetLeagueParamButton,
    WidgetLocationParamButton,
    WidgetTeamParamButton,
    WidgetPeriodParamButton,
    TeamName, GameResultCircle, TeamLogo, StatisticValueButton, FontAwesomeIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [],
    }
  },
  mounted() {
    this._onGameUIDChange()
    window.emitter.on('context-store:game-uid:change', () => {
      this._onGameUIDChange()
    })

    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
  },
  beforeUnmount() {
    window.emitter.off('context-store:game-uid:change')
    window.emitter.off('perspective-store:widget-setting:change')
  },
  computed: {
    _gameStore() {
      return gameStore();
    },
    _contextStore() {
      return contextStore()
    },
    _gameUID() {
      return this._contextStore.gameUID
    },
    _game() {
      return this._gameStore.get(this._gameUID)
    },
    _teamUID() {
      return perspectiveStore().getParameterTeamValue(this.item.uid)
    },
    _5LastGames() {
      if (!this.data) return []
      if (this.data.length <= 5) return this.data
      return this.data.slice(0, 5)
    },
    _5PreviousGames() {
      if (!this.data) return []
      if (this.data.length <= 5) return []
      return this.data.slice(5, 10)
    },
    _itemUID() {
      return this.item ? this.item.uid : null
    },
  },
  watch: {
    _itemUID() {
      this._refresh()
    }
  },
  methods: {
    teamStore,
    _onGameUIDChange() {
      this._refresh()
    },
    _refresh() {
      if (!contextStore().gameUID) return;

      // On vérifie que le widget fait partie de la perspective courante
      if (!perspectiveStore().isWidgetInCurrentPerspective(this.item.uid)) return;

      this.data = null // => loading
      setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {

      const wds = widgetDataStore()
      const data = wds.get(this.item.uid, contextStore().gameUID)
      if (data) {
        this.data = data
        return;
      }

      if (!this._game) {
        console.info("Cannot refresh LastTeamGamesWidget because game is not available")
        return;
      }

      const payload = {
        teamUID: perspectiveStore().getParameterTeamValue(this.item.uid),
        leagueUID: this._game.leagueUID,
        at: this._game.date,
        sport: perspectiveStore().getCurrentSport(),
        league: perspectiveStore().getParameterValue(this.item.uid, 'league'),
      }
      axiosService.put('/wid/indicateur-forme-team', payload).then(response => {
        this.data = response.data.gameUIDs
        wds.put(this.item.uid, contextStore().gameUID, this.data)
      })
    },
    _diff(gameUID) {
      const game = this._gameStore.get(gameUID)
      if (!game) return null
      if (!(game.scoreFtHome >= 0) || !(game.scoreFtAway >= 0)) return null
      const diff = game.homeTeamUID === this._teamUID ? game.scoreFtHome - game.scoreFtAway : game.scoreFtAway - game.scoreFtHome

      if (diff === 0) {
        return ""
      }
      if (diff > 0) {
        return "+" + diff
      } else {
        return diff
      }
    },
    _serieValue(gameUIDs) {

      if (!gameUIDs || gameUIDs.length === 0) {
        return "-"
      }

      let played = 0
      let value = 0
      gameUIDs.forEach(gameUID => {
        let diff = this._diff(gameUID);

        if (!diff) {
          value += 0  // point
        } else if (diff === "") {
          value += 1  // point
          played += 1
        } else if (diff < 0) {
          value += 0  // défaite
          played += 1
        } else if (diff > 0) {
          value += 3  // victoire
          played += 1
        }
      })

      return (value / (played * 3) * 100.0).toFixed(0) + "%"
    },
  }
}
</script>

<template>
  <div>
    <div class="widget-header">
      Forme {{ teamStore().get(_teamUID).name }}
      <div class="widget-header-params flex">
        <widget-location-param-button :widget="item"/>
        <widget-team-param-button :widget="item"/>
        <widget-league-param-button :widget="item"/>
      </div>
    </div>

    <widget-loading-content v-if="!data"/>
    <div class="widget-content flex" v-else>
      <table>
        <tbody>
        <tr style="height: 50px">
          <td rowspan="2" class="label">L5</td>
          <td rowspan="2" v-for="gameUID in _5LastGames">
            <div style="position: absolute; top:4px;">
              {{ _diff(gameUID) }}
            </div>
            <game-result-circle :game-uid="gameUID" :team-uid="_teamUID" class="mt-2"/>
          </td>
          <th rowspan="2" style="width: 45px">
            {{ _serieValue(_5LastGames) }}
          </th>
          <td rowspan="2" class="label ll">L10</td>
          <td rowspan="2" v-for="gameUID in _5PreviousGames">
            <div style="position: absolute; top:4px;">
              {{ _diff(gameUID) }}
            </div>
            <game-result-circle :game-uid="gameUID" :team-uid="_teamUID" class="mt-2"/>
          </td>
          <th rowspan="2" style="width: 45px">
            {{ _serieValue(data) }}
          </th>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: @text-color;
  font-size: @font-size-small;

  tr {
    border-bottom: 1px solid @stroke-color;

    &:last-child {
      border-bottom: none;
    }

    th, td {
      padding: @padding-small;
      text-align: center;
      font-family: 'SourceCodePro', serif;
      font-weight: 400;
    }

    th {
      background-color: @background-color-light;
    }

    td {
      &.label {
        text-orientation: mixed;
        writing-mode: vertical-rl;

        &.ll {
          border-left: 1px solid @stroke-color;
        }
      }
    }
  }
}
</style>