<script>

import {selectionStore} from "@/stores/Selection.store";
import {selectionPaneStore} from "@/components/routes/selection/panes/selection/SelectionPane.store";
import {modalStore} from "@/stores/Modal.store";
import {monitoringStore} from "@/stores/Monitoring.store";
import {bankrollStore} from "@/stores/Bankroll.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {contextStore} from "@/stores/Context.store";
import SelectionPaneHeaderCalendar from "@/components/routes/selection/panes/selection/header/SelectionPaneHeader.Calendar.vue";

export default {
  name: "SelectionPaneHeader",
  components: {SelectionPaneHeaderCalendar, FontAwesomeIcon},

  props: {
    offsetY: {
      type: Number,
      default: 0
    }
  },

  computed: {

    _selectionActive() {
      let selectedSelectionUID = selectionPaneStore().getSelectionActiveUIDBySport(contextStore().getSport);
      return selectionStore().get(selectedSelectionUID);
    },
    _selectionActiveName() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return "Appliquer une sélection";

      // Keep only the first 20 characters
      if (selectionActive.name.length > 25) {
        return selectionActive.name.substring(0, 25) + '...'
      }

      return selectionActive.name
    },
    _selectionActiveMonitoringWaitingBetsCount() {
      let selectionActive = this._selectionActive;
      if (!selectionActive) return null;
      const allMonitorings = monitoringStore().getBySelectionUID(selectionActive.uid)
      let betCount = 0
      allMonitorings.forEach(m => {
        const mBk = bankrollStore().get(m.bankrollUID)
        if (mBk) {
          betCount += mBk.ticketWaitCount
        }
      })
      return betCount
    },

    _styles() {
      return {
        top: '-' + this.offsetY + 'px',
      }
    }
  },
  methods: {
    _onClickSelection() {
      modalStore().openModal({uid: "selectionsModal", payload: {type: "GAME"}})
      this._emitAction()
    },
    _clearSelection() {
      selectionPaneStore().clearSelection()
      selectionPaneStore().runSelection()
    },
    _openMonitoringList() {
      if (!this._selectionActive) return
      modalStore().openModal({
        uid: "selectionMonitoringListModal",
        payload: {selectionUID: this._selectionActive.uid}
      })
    },
    _emitAction(){
      this.$emit('action')
    }
  }
}
</script>

<template>
  <div id="selection-pane-header" :style="_styles">

    <header >
      <div class="flex pt-2 mx-2">
        <button class="accent flex-grow" @click="_onClickSelection">
          {{ _selectionActiveName }}
        </button>
        <a-tooltip title="Voir les monitoring" placement="right" v-if="_selectionActive">
          <a-badge :count="_selectionActiveMonitoringWaitingBetsCount" size="small">
            <button class="ml-1 info" @click="_openMonitoringList()">
              <font-awesome-icon :icon="['fas', 'heart-pulse']"/>
            </button>
          </a-badge>
        </a-tooltip>
        <button class="info ml-1" v-if="_selectionActive" @click="_clearSelection">
          <font-awesome-icon icon="fa-solid fa-close"/>
        </button>
      </div>
    </header>

    <selection-pane-header-calendar @action="_emitAction"/>

  </div>

</template>

<style lang="less" scoped>
@import "@/assets/styles/variables.less";

#selection-pane-header{
  position: absolute;
  z-index: 10;
  background: @background-color;
  border-radius: @radius-regular;
  overflow: hidden;
  box-shadow: 0px 8px 15px 0px rgba(110, 81, 215, 0.33);
  width: @selection-pane-width;
}

.mobile {
  #selection-pane-header{
    width: 100%;
  }
}
</style>