<script>
import {GridItem, GridLayout} from 'vue3-grid-layout-next'
import {perspectiveStore} from "@/stores/Perspective.store";
import {contextStore} from "@/stores/Context.store";
import {analysePaneStore} from "@/components/routes/selection/panes/analyse/AnalysePane.store";
import {widgetDefinitionsStore} from "@/stores/WidgetDefinitions.store";
import {message} from "ant-design-vue";


import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {gameStore} from "@/stores/Game.store";

// Widgets
import ResultWidget from "@/components/routes/selection/panes/analyse/widgets/Result.Widget.vue";
import BothTeamsToScoreWidget from "@/components/routes/selection/panes/analyse/widgets/BothTeamsToScore.Widget.vue";
import GoalsWidget from "@/components/routes/selection/panes/analyse/widgets/Goals.Widget.vue";
import GoalsByPeriodWidget from "@/components/routes/selection/panes/analyse/widgets/GoalsByPeriod.Widget.vue";
import IndicateurFormeClubWidget from "@/components/routes/selection/panes/analyse/widgets/IndicateurFormeClub.Widget.vue";
import LastTeamGamesWidget from "@/components/routes/selection/panes/analyse/widgets/LastTeamGames.Widget.vue";
import TeamsGoalsChartWidget from "@/components/routes/selection/panes/analyse/widgets/TeamsGoalsChart.Widget.vue";
import TeamsPerformanceCumulativeChartWidget from "@/components/routes/selection/panes/analyse/widgets/TeamsPerformanceCumulativeChart.Widget.vue";
import TeamsFormeEvolutionChartWidget from "@/components/routes/selection/panes/analyse/widgets/TeamsFormeEvolutionChart.Widget.vue";
import TeamAllOverChartsWidget from "@/components/routes/selection/panes/analyse/widgets/TeamAllOverCharts.Widget.vue";
import GameAllUnderWidget from "@/components/routes/selection/panes/analyse/widgets/GameAllUnder.Widget.vue";
import GameAllOverWidget from "@/components/routes/selection/panes/analyse/widgets/GameAllOver.Widget.vue";
import NextTeamGameWidget from "@/components/routes/selection/panes/analyse/widgets/NextTeamGame.Widget.vue";
import TeamMarkovLawWidget from "@/components/routes/selection/panes/analyse/widgets/TeamMarkovLaw.Widget.vue";
import ExpectedGoalsWidget from "@/components/routes/selection/panes/analyse/widgets/ExpectedGoals.Widget.vue";
import OddsWidget from "@/components/routes/selection/panes/analyse/widgets/Odds.Widget.vue";
import SimilarGamesWidget from "@/components/routes/selection/panes/analyse/widgets/SimilarGames.Widget.vue";
import FrequencyStatsWidget from "@/components/routes/stats/widgets/FrequencyStats.Widget.vue";
import FinSerieWidget from "@/components/routes/selection/panes/analyse/widgets/FinSerie.Widget.vue";
import GameStatisticWidget from "@/components/routes/selection/panes/analyse/widgets/GameStatistic.Widget.vue";
import {modalStore} from "@/stores/Modal.store";

export default {
  name: "AnalysePerspective",
  components: {
    FontAwesomeIcon,
    GridItem, GridLayout,

    // Widgets
    ResultWidget,
    BothTeamsToScoreWidget,
    GoalsWidget,
    GoalsByPeriodWidget,
    IndicateurFormeClubWidget,
    LastTeamGamesWidget,
    TeamsGoalsChartWidget,
    TeamsPerformanceCumulativeChartWidget,
    TeamsFormeEvolutionChartWidget,
    TeamAllOverChartsWidget,
    GameAllUnderWidget,
    GameAllOverWidget,
    NextTeamGameWidget,
    TeamMarkovLawWidget,
    ExpectedGoalsWidget,
    OddsWidget,
    SimilarGamesWidget,
    FrequencyStatsWidget,
    FinSerieWidget,
    GameStatisticWidget
  },
  computed: {
    _currentGameUID() {
      return contextStore().getSelectedGameUID()
    },
    _widgetDefinitionsStore() {
      return widgetDefinitionsStore();
    },
    _perspectiveStore() {
      return perspectiveStore();
    },
    _perspective() {
      if (!this._currentGameUID) return null
      return analysePaneStore().getPerspectiveBySport(contextStore().getSport)
    },
    _isPerspectiveYours() {
      if (!contextStore().isAuthenticated()) return false
      const perspective = this._perspective
      if (!perspective) return false
      return perspective.userUID === contextStore().getLoggedUserUID()
    },
    _perspectiveInEditMode() {
      const isYours = this._isPerspectiveYours
      if (!isYours) return false
      if(!this._perspective) return false
      if (!this._perspective.editMode) return false
      return this._perspective.editMode
    },
    _widgets() {
      if (!this._perspective) return []
      return this._perspectiveStore.getWidgetsByPerspectiveUID(this._perspective.uid)
    },
    _hasWidgets() {
      return this._widgets.length > 0
    },
    _canAccessGame() {
      return gameStore().canAccessGame(this._currentGameUID)
    },
    _cannotAccessGameLabel() {
      const code = this._canAccessGame
      switch (code) {
        case 'KO_GAME_IS_LOADING':
          return 'Chargement...'
        case 'KO_GAME_NOT_FOUND':
          return 'La rencontre n\'existe pas.'
        case 'KO_LEAGUE_NOT_FOUND':
          return 'La compétition n\'existe pas.'
        case 'KO_LEAGUE_OUT_OF_SUBSCRIPTION':
          return 'Ton abonnement ne te permet pas d\'accéder à cette rencontre.'
        case 'KO_LIMIT_TO_3_DAYS_AHEAD':
          return 'Tu ne peux pas accéder à une rencontre qui a lieu dans plus de 3 jours. Abonne-toi pour y accéder'
        case 'KO_LIMIT_TO_1_DAY_AHEAD':
          return 'Tu ne peux pas accéder à une rencontre qui a lieu dans plus de 1 jour. Connecte-toi pour y accéder'
        default:
          return ''
      }
    },
    _layout() {
      const layout = []
      if (!this._widgets) return layout

      this._widgets.forEach((widget) => {
        const widgetDefinition = this._widgetDefinitionsStore.getByType(widget.type)
        if (!widgetDefinition) {
          console.warn("Widget definition not found for widget type: " + widget.type)
          return
        }
        layout.push({
          'i': widget.uid,
          'uid': widget.uid,
          'x': widget.x, 'y': widget.y,
          'w': widgetDefinition.dimensions.width, 'h': widgetDefinition.dimensions.height,
          'type': widget.type
        })
      })
      return layout
    }
  },
  methods: {
    _openWidgetStore() {
      if (!this._perspective) return
      if (this._perspective.userUID !== contextStore().getLoggedUserUID()) {
        message.error('Tu dois être le propriétaire de la perspective pour ajouter un widget')
        return
      }
      modalStore().openModal({
        uid: 'widgetStoreModal', payload: {
          perspective: this._perspective
        }
      })
    },
    _onLayoutUpdated(layout) {
      if (!this._perspectiveInEditMode) return
      perspectiveStore().updateLayout(this._perspective.uid, layout)
    }
  }
}
</script>

<template>
  <div id="forbidden-game-perspective" v-if="_canAccessGame !== true">
    <p>
      <font-awesome-icon icon="fa-solid fa-ban" size="5x"/>
      {{ _cannotAccessGameLabel }}
    </p>
  </div>
  <div id="empty-perspective" v-else-if="!_hasWidgets">
    <p>Cette perspective ne contient aucun widget</p>
    <button class="info" @click="_openWidgetStore">Ajouter un widget</button>
  </div>
  <div id="analyse-perspective-wrapper" v-else>
    <div id="analyse-perspective">
      <grid-layout :layout.sync='_layout'
                   :col-num='128'
                   :is-draggable='_perspectiveInEditMode'
                   :row-height='8'
                   :is-resizable='false'
                   :vertical-compact='false'
                   :use-css-transforms='true'
                   @layout-updated="_onLayoutUpdated">
        <grid-item v-for='item in _layout' class="widget"
                   :x='item.x'
                   :y='item.y'
                   :w='item.w'
                   :h='item.h'
                   :i='item.i'>
          <component :is="item.type" :item="item"/>
        </grid-item>
      </grid-layout>
    </div>
  </div>

</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#analyse-perspective-wrapper {
  height: calc(100dvh - @toolbar-regular * 2 - @toolbar-small);
  width: calc(100vw - 400px - 3 * @padding-small);
  overflow: auto;
  overflow-y: scroll;
  scrollbar-width: none;

  #analyse-perspective {
    height: 2096px;
    width: 2096px;
    overflow: hidden;
  }
}

#empty-perspective, #forbidden-game-perspective {
  margin: auto;
  width: calc(100vw - 400px);

  p {
    margin: 200px auto @padding-large auto;
    max-width: 400px;
    text-align: center;
    font-size: 2em !important;
    color: @text-color;
    opacity: 0.1;
  }
}

#forbidden-game-perspective {
  p {
    color: @warning-color;
    opacity: 1;
  }
}
</style>