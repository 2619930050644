<script>

import DateTime from "@/components/common/DateTime.vue";
import {notificationStore} from "@/stores/Notification.store";
import {contextStore} from "@/stores/Context.store";
import NotificationInstance from "@/components/common/modal/notifications/instance/NotificationInstance.vue";

export default {
  name: "NotificationInstanceItem",
  components: {NotificationInstance, DateTime},

  props: {
    instance: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },
  computed: {
    _classes() {
      return {
        'notification-instance-item': true,
        'selected': this.selected
      }
    },
    _label() {
      switch (this.instance.type) {
        case 'BET_ADDED_IN_BANKROLL':
          return "Un bet ajouté dans bankroll"
        case 'BET_NOT_COMPLIANT_WITH_SELECTION':
          return "Un bet n'est plus conforme à la sélection"
        default:
          return this.instance.type
      }
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
  },
  methods: {
    _onClicked() {
      this.$emit('selected', this.instance)
    },
    archive(){
      this.$emit('archive', this.instance)
    }
  },
  watch: {}
}
</script>

<template>
  <div :class="_classes" @click="_onClicked">
    <div class="flex">
      <div class="m-1 flex-grow">{{_label}}</div>

      <button @click="archive" class="small mt-1 mr-1 secondary" v-if="selected" style="width: 28px">
        <font-awesome-icon icon="fa-solid fa-trash-can"/>
      </button>
      <button class="small mt-1 mr-1" v-else style="width: 28px">
        <font-awesome-icon icon="fa-solid fa-chevron-right"/>
      </button>
    </div>
    <date-time :value="instance.creationDate" :time="true" class="mr-1"/>
    <div v-if="_isMobile && selected">
      <hr class="my-2"/>
      <notification-instance :instance="instance" class="m-1"/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.notification-instance-item {
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;
  color : @text-color;


  &.selected {
    border-top: 1px solid @accent-color;
    background: linear-gradient(90deg, #494081aa 0%, #49408199 40%, #49408166 80%, rgba(37, 37, 42, 0) 90%);
  }

  &:hover, &.hover {
    border-top: 1px solid #f97f28;
    background: linear-gradient(90deg, #f97f28aa 0%, #f97f2899 33%, #fea32866 66%, #fea32888 100%) !important;
  }
}
</style>