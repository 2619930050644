<script>
import {modalStore} from "@/stores/Modal.store";
import {contextStore} from "@/stores/Context.store";
import {message} from "ant-design-vue";
import axiosService from "@/services/axios.service";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import {perspectiveStore} from "@/stores/Perspective.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {selectionStore} from "@/stores/Selection.store";
import WidgetLoadingContent from "@/components/common/widgets/loading/WidgetLoadingContent.vue";
import GameRow from "@/components/common/GameRow.vue";
import TeamName from "@/components/common/TeamName.vue";
import GameSelectionStatsWidgetRow from "@/components/routes/stats/widgets/GameSelection.Stats.Widget.Row.vue";

export default {
  name: "GameSelectionStatsWidget",
  components: {GameSelectionStatsWidgetRow, TeamName, GameRow, WidgetLoadingContent},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => {
    return {
      refreshTimeout: null,
      data: null,
      minScore: null,
      maxScore: null,

      // Gestion du click long sur une ligne
      rowClickTimer: null,
    }
  },

  mounted() {
    window.emitter.on('stats-route:widgets-need-to-refresh', () => {
      this._refresh()
    })
    window.emitter.on('perspective-store:widget-setting:change', (widgetUID) => {
      if (widgetUID === this.item.uid) {
        this._refresh()
      }
    })
    this._refresh()
  },

  computed: {
    _itemUID() {
      return this.item ? this.item.uid : null
    },
    _widget() {
      if (!this._itemUID) return null
      return perspectiveStore().getWidgetByUID(this._itemUID)
    },
    _isWidgetYours() {
      if (!this._widget) return false
      return this._widget.userUID === contextStore().getLoggedUserUID()
    },
    _currentPerspectiveUID() {
      return statsRouteStore().getSelectedPerspectiveUID()
    },
    _parameters() {
      return perspectiveStore().getParametersByWidgetUID(this.item.uid)
    },
    _selectionUID() {
      return perspectiveStore().getParameterValue(this.item.uid, 'selection_uid')
    },
    _selectionLabel() {
      const selection = selectionStore().get(this._selectionUID)
      if (!selection) return 'Sélectionner une statistique'
      return selection.name
    },
    _sortedData() {
      if (!this.data) return []

      const sortedData = [...this.data]
      sortedData.sort((a, b) => {
        return b.matchingScore - a.matchingScore
      })

      // Keep only 15 first rows
      return sortedData.slice(0, 15)
    },

    _hasFullLicence() {
      return contextStore().hasFullLicence();
    },

  },
  watch: {
    _selectionUID() {
      this._refresh()
    },
    _widget() {
      this._refresh()
    }
  },

  methods: {

    _refresh() {

      if (!this._widget) {
        return
      }

      const isInCurrentPerspective = this._widget.perspectiveUID === this._currentPerspectiveUID
      if (!isInCurrentPerspective) {
        return
      }

      this.data = null // => loading
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this._refreshNow()
      }, Math.random() * 200 + 50)
    },

    _refreshNow() {
      const payload = statsRouteStore().buildBaseWidgetPayload()
      payload.selectionUID = this._selectionUID

      axiosService.put('/wid/stats/game-selection', payload).then(response => {
        this.data = response.data.results
        this.minScore = response.data.minScore
        this.maxScore = response.data.maxScore
      }).catch(error => {
        console.error(error)
      })
    },
    _updateOverContext(row, pin = false) {
      statsRouteStore().setOverContext({
        statUID: row.statUID,
        teamUID: row.teamUID,
        gameUID: row.gameUID,
        leagueUID: row.leagueUID
      }, pin)
    },

    _openDataSelectorModal() {
      modalStore().openModal({
        uid: "statSelectorModal",
        payload: {
          category: 'selection',
          selectionUID: this._selectionUID,
          type: 'GAME',
          sport: contextStore().getSport,
          callback: (category, selection) => {

            if ('selection' !== category) {
              return
            }

            // Si non authentifié, message d'erreur
            if (!contextStore().isAuthenticated()) {
              message.error('Tu dois être connecté pour personnalisé les data');
              return;
            }
            const parameterDefinition = this._parameters.find(p => p.key === 'selection_uid')

            perspectiveStore().setWidgetParameterValue(this.item.uid, parameterDefinition, {'key': selection.uid})
            perspectiveStore().saveWidgetParameters(this.item.uid)
          }
        }

      });
    },

    _expend() {


      // Autorisé uniquement avec l'offre ULTIMATE
      if(!this._hasFullLicence){
        message.error('Tu dois être abonné à l\'offre ULTIMATE pour voir plus de résultats');
        return;
      }

      modalStore().openModal({
        uid: "rawStatsModal",
        payload: {
          widgetUID: this.item.uid,
          statFixedUID: this._selectionUID
        }
      });
    },

    _startClickTimer(row) {
      if (!row.gameUID) {
        return
      }
      clearTimeout(this.rowClickTimer)
      this.rowClickTimer = null
      const self = this
      this.rowClickTimer = setTimeout(() => {
        self._updateOverContext(row, true)
        this.rowClickTimer = null
      }, 400)
    },

    _endClick(row) {
      if (this.rowClickTimer) {
        clearTimeout(this.rowClickTimer)
        this.rowClickTimer = null
      }
    },
    _deleteWidget() {
      if (!this._isWidgetYours) return
      perspectiveStore().deleteWidget(this._itemUID, () => {
        modalStore().closeModal();
      })
    }
  }
}
</script>

<template>
  <div class="widget team-event-stats">
    <div class="widget-header flex">
      SEL.
      <button class="small no-radius flex-grow text-left" @click="_openDataSelectorModal" style="height: 28px">
        {{ _selectionLabel }}
      </button>
      <div class="widget-header-params flex">
        <button class="secondary small no-radius" @click="_deleteWidget" v-if="_isWidgetYours">
          <font-awesome-icon icon="fa-solid fa-trash-can"/>
        </button>
      </div>
    </div>
    <div class="widget-content">
      <div v-if="data">

        <game-selection-stats-widget-row v-for="(r, index) in _sortedData" :even="index % 2 === 0"
                                         @mouseenter="_updateOverContext(r)"
                                         @mousedown="_startClickTimer(r)" @mouseup="_endClick(r)" :row="r"
                                         :min-score="minScore" :max-score="maxScore"/>

        <a-alert v-if="_sortedData.length === 0" type="info" class="m-2" show-icon
                 message="Aucune rencontre ne correspond à cette sélection avec les critères actuels."/>

      </div>
      <widget-loading-content v-else/>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.team-event-stats {
  overflow: hidden;

  .widget-header {
    background: -webkit-linear-gradient(0deg, @selected-color, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00, #FFFFFF00);
  }

  .widget-content {
    height: auto;
    overflow: hidden;
  }
}

table {
  .logo {
    width: 24px;
    text-align: center;
  }

  .name {
    text-align: left;
    max-width: 150px;
    padding: 0 !important;
  }

  .relative-value {
    width: 30px;
    text-align: right;
    color: @text-color-dark;
  }

  .absolute-value {
    width: 29px;
    text-align: right;
  }

  tr {
    cursor: pointer;

    &.over-context-league {
      background: fade(@hover-color, 50%);
    }

    &.over-context-game {
      background: fade(@selected-color, 75%);
    }

    &.over-context-team {
      background: fade(@primary-color, 40%);
    }

    &:hover {
      background: fade(@primary-color, 40%);
    }

    &.anonymous {
      opacity: 0.25;
    }

    .against-data {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 7pt;
      color: @text-color-dark;
    }
  }
}

</style>