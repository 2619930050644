<script>

import {teamStore} from "@/stores/Team.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {colorStore} from "@/stores/Color.store";
import {imageStore} from "@/stores/Image.store";

export default {
  name: "TeamLogo",
  components: {FontAwesomeIcon},
  props: {
    uid: {
      required: true
    },
    size: {
      type: String,
      default: "regular"
    },
    anonymous: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      teamFound: null
    }
  },

  beforeMount() {
    if(!this.uid) return null
    if(!this._team) return null
    if(!this._team.imageUID) return null
    imageStore().get(this._team.imageUID)
  },

  computed: {
    _team() {
      if(this.anonymous) return null
      if(!this.uid) return null

      if(this.teamFound) {
        return this.teamFound // On a déjà trouvé l'équipe dans le store donc on la retourne
      }

      let _team = teamStore().get(this.uid);
      this.teamFound = _team
      return _team
    },
    _teamSport() {
      if(!this._team) return null
      return this._team?.sport ?? null;
    },
    _teamName() {
      if(!this._team) return '_'
      return this._team?.name ?? "_";
    },
    _teamExternalUID() {
      if(!this._team) return null
      return this._team?.externalUID ?? null;
    },
    _buildCssClasses() {
      return {
        'team-logo': true,
        'small': this.size === 'small',
        'large': this.size === 'large'
      }
    },
    _defaultFaIcon() {
      if(this.anonymous) return 'fa-lock'
      if(this._teamSport === 'TENNIS') return 'fa-user'
      return 'fa-shield'
    },
    _canDisplayLogo() {
      if(!this._team) return false
      if(this._teamSport === 'TENNIS') return false
      return this._teamExternalUID !== null;
    },
    _altColor() {
      const cs = colorStore();
      if(this.anonymous) return cs.get('anonymous');
      return cs.get(this._teamName);
    },
    _buildCssStyle() {
      return {
        color: this._altColor,
        height: '22px'
      }
    },
    _base64() {
      if(!this._team) return null
      if(!this._team.imageUID) return null
      const image = imageStore().get(this._team.imageUID)
      if(!image) return null
      return 'data:image/png;base64,' + image.data
    }
  },

  watch: {
    uid() {
      this.teamFound = null
    }
  }
}
</script>

<template>
  <span>
    <font-awesome-icon :icon="['fa-solid', 'fa-lock']" :class="_buildCssClasses" v-if="anonymous" :style="_buildCssStyle"/>
    <font-awesome-icon :icon="['fa-solid', _defaultFaIcon]" :class="_buildCssClasses" v-else-if="!_canDisplayLogo || !_base64" :style="_buildCssStyle"/>
    <img :class="_buildCssClasses" :src="_base64" v-else-if="_base64" alt=""/>
  </span>
</template>

<style scoped lang="less">
@import "@/assets/styles/base.less";

.team-logo {

  display: inline-block;
  max-height: 24px;
  max-width: 24px;

  &.small {
    max-height: 16px;
    max-width: 16px;
  }

  &.large {
    max-height: 64px;
    max-width: 64px;
  }
}
</style>