<script>
import UserDropdownMenu from "@/components/common/UserDropdownMenu.vue";
import {contextStore} from "@/stores/Context.store";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {preferencesStore} from "@/stores/Preferences.store";
import {sportStore} from "@/stores/Sport.store";
import {message} from "ant-design-vue";


export default {
  name: "MainHeader",
  components: {FontAwesomeIcon, UserDropdownMenu},
  computed: {
    _loggedUser() {
      return contextStore().loggedUser;
    },
    _isLicenced() {
      return contextStore().isLicensed()
    },
    _isAllowedToProfiler() {
      if(!this._loggedUser) return false

      const allowedUID = [
          1,
        26,  // llou
        49,  // Tiffany
        160, //stefragui
        405, // Sebastien 78800 discord
        233, // Syapretaporter
      ]

      return allowedUID.includes(this._loggedUser.uid)
    },
    _isMobile() {
      return contextStore().isMobileDevice()
    },
    _currentRoute() {
      return this.$route.name
    },
    _currentRouteIcon() {
      if (!this._currentRoute) return ''
      switch (this._currentRoute) {
        case 'main':
          return 'fa-solid fa-bars'
        case 'stats':
          return 'fa-solid fa-chart-pie'
        case 'bankroll':
          return 'fa-solid fa-wallet'
        case 'players':
          return 'fa-solid fa-user'
        case 'games':
          return 'fa-solid fa-object-group'
        case 'profiler':
          return 'fa-solid fa-chart-simple'
        default:
          return 'fa-solid fa-bars'
      }
    },
    _currentRouteLabel() {
      if (!this._currentRoute) return ''
      switch (this._currentRoute) {
        case 'stats':
          return 'Stats'
        case 'bankrolls':
          return 'Bankrolls'
        case 'games':
          return 'Matchs'
        case 'players':
          return 'Joueurs'
        case 'profiler':
          return 'Profiler'
        case 'main':
        default:
          return 'DoctoBET'
      }
    },
    _sports() {
      return sportStore().getAll
    },
    _selectedSport() {
      return sportStore().get(contextStore().getSport)
    },
    _shouldShowControls() {
      return this._currentRoute !== 'main'
    },
    _sportClass() {
      return this._selectedSport ? this._selectedSport.code : ''
    }
  },
  methods: {

    _goToMain() {
      this.$router.push({name: 'main'})
    },
    _goToStats() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['stats'],
          () => {
            this.$router.push({name: 'stats'})
          }
      )
    },
    _goToGames() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['games'],
          () => {
            this.$router.push({name: 'games'})
          }
      )
    },
    _goToPlayers() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['players'],
          () => {
            this.$router.push({name: 'players'})
          }
      )
    },
    _goToBankroll() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['bankrolls'],
          () => {
            this.$router.push({name: 'bankrolls'})
          }
      )
    },
    _goToProfiler() {
      preferencesStore().save('DEFAULT_ROUTE', 'INIT_ROUTE', ['profiler'],
          () => {
            this.$router.push({name: 'profiler'})
          }
      )
    },

    _changeSport(sport) {

      if(!this._isLicenced && sport.code !== 'FOOTBALL'){
        message.error('Tu dois être abonné pour accéder à ce sport.');
        return;
      }

      contextStore().setSport(sport.code)

      // On enregistre le sport par defaut.
      preferencesStore().save('SPORT', 'FAVORITE_SPORT', [sport.code])
    },

  }
}
</script>

<template>
  <header id="main-header">

    <div id="main-header-left">
      <span id="app-logo-gradient" :class="_sportClass"></span>
      <span id="app-logo" class="float-left" @click="_goToMain()">
          <img src="../../assets/images/logo-header.png" alt="DoctoBET" style="height: 24px; cursor: pointer; margin-top: 10px"/>
      </span>

      <div class="flex mt-1" v-if="_shouldShowControls">

        <a-dropdown :trigger="['click']" class="ml-2">
          <button class="translucide">
            <font-awesome-icon :icon="_selectedSport.icon"/>
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="sport in _sports" :key="sport.uid" @click="_changeSport(sport)" class="text-left"
                           style="font-size: 11pt !important;">
                <font-awesome-icon :icon="sport.icon" style="width: 20px"/>
                <span class="ml-2">{{ sport.label }}</span>
                <font-awesome-icon class="ml-2" v-if="!_isLicenced && sport.code !== 'FOOTBALL'" icon="fa-solid fa-crown" style="float: right; font-size: 15pt; color:#fea328"/>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

        <a-dropdown :trigger="['click']">
          <button class="translucide ml-1">
            {{ _currentRouteLabel }}
            <font-awesome-icon icon="fa-solid fa-caret-down" style="margin-left: 5px"/>
          </button>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="_goToStats" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-chart-pie" style="width: 20px"/>
                <span class="ml-2">Stats</span>
              </a-menu-item>
              <a-menu-item @click="_goToGames" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon :icon="_selectedSport.icon" style="width: 20px"/>
                <span class="ml-2">Matchs</span>
              </a-menu-item>
              <a-menu-item @click="_goToPlayers" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-user" style="width: 20px"/>
                <span class="ml-2">Joueurs</span> <a-tag color="red" style="width:36px; margin-left:10px">BETA</a-tag>
              </a-menu-item>
              <a-menu-item @click="_goToProfiler" class="text-left" style="font-size: 11pt !important;" v-if="_isAllowedToProfiler">
                <font-awesome-icon icon="fa-solid fa-chart-simple" style="width: 20px"/>
                <span class="ml-2">Profiler</span>
              </a-menu-item>
              <a-menu-item @click="_goToBankroll" class="text-left" style="font-size: 11pt !important;">
                <font-awesome-icon icon="fa-solid fa-wallet" style="width: 20px"/>
                <span class="ml-2">Bankrolls</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </div>
    </div>

    <div id="main-header-right" v-if="_shouldShowControls">
      <user-dropdown-menu/>
    </div>

  </header>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

#main-header {
  background-color: transparent;

  #main-header-search-input {
    width: 400px;
    height: calc(@toolbar-regular - @padding-regular);
    border-radius: @radius-regular;
    text-align: center;
    background: @background-color-light;
  }

  #main-header-left {
    float: left;

    #app-logo {
      display: inline-block;
      position: relative;
      left: 0;
      top: 0;
      margin-left: 8px;
      opacity: 0.25;
    }

    #app-logo-gradient {
      width: 200px;
      height: @toolbar-large;
      display: inline-block;
      position: absolute;
      left: 0;
      opacity: 0.65;
      background: rgb(97, 233, 255);
      background: linear-gradient(90deg, rgba(97, 233, 255, 1) 0%, rgba(34, 193, 195, 1) 20%, rgba(253, 187, 45, 1) 40%, rgba(255, 132, 110, 1) 60%, rgba(43, 45, 48, 1) 85%);

      &.FOOTBALL {
        background: linear-gradient(90deg, #4CAF50 0%, #4CAF50 40%, #4CAF50 60%, transparent 100%);
      }

      &.TENNIS {
        background: linear-gradient(90deg, #FFEB3B 0%, #FFEB3B 40%, #FFEB3B 60%, transparent 100%);
      }

      &.BASKETBALL {
        background: linear-gradient(90deg, #FF9800 0%, #FF9800 40%, #FF9800 60%, transparent 100%);
      }

      &.RUGBY {
        background: linear-gradient(90deg, #795548 0%, #795548 40%, #795548 60%, transparent 100%);
      }

      &.HANDBALL {
        background: linear-gradient(90deg, #2196F3 0%, #2196F3 40%, #2196F3 60%, transparent 100%);
      }

      &.AMERICAN_FOOTBALL {
        background: linear-gradient(90deg, #D32F2F 0%, #D32F2F 40%, #D32F2F 60%, transparent 100%);
      }

      &.HOCKEY {
        background: linear-gradient(90deg, #FF9800 0%, #FF9800 40%, #FF9800 60%, transparent 100%);
      }
    }
  }

  #main-header-right {
    float: right;
  }
}

.mobile {
  #main-header {
    text-align: left;

    #main-header-search-input {
      width: 32px;
      margin-left: 10px;
    }
  }
}

</style>