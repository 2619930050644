<script>

import {gameStore} from "@/stores/Game.store";
import {contextStore} from "@/stores/Context.store";

// Tabs
import {leagueStore} from "@/stores/League.store";
import {selectionStore} from "@/stores/Selection.store";
import SelectionPaneItemLine1 from "@/components/routes/selection/panes/selection/items/SelectionPaneItem.Line1.vue";
import {notebookStore} from "@/stores/NotebookStore";
import {noteStore} from "@/stores/Note.store";
import ReferringAd from "@/components/common/ReferringAd.vue";
import {flagStore} from "@/stores/Flag.store";
import Flag from "@/components/common/Flag.vue";
import OfferChoiceButtonNew from "@/components/common/OfferChoiceButtonNew.vue";
import {statsPaneStore} from "@/components/routes/stats/panes/stats/StatsPane.store";
import {statsRouteStore} from "@/components/routes/stats/StatsRoute.store";
import Statistic from "@/components/common/Statistic.vue";


export default {
  name: "StatsGameItem",
  components: {
    Statistic,
    OfferChoiceButtonNew,
    Flag,
    ReferringAd,
    SelectionPaneItemLine1
  },
  props: {
    item: {
      required: true
    },
    showAd: {
      required: true
    }
  },

  data() {
    return {
      odds: null
    }
  },

  computed: {
    _offers() {
      if (!this.item.offers) return null
      return this.item.offers
    },
    _isOffersAreLoading() {
      return statsRouteStore().isOffersAreLoading()
    },
    _selectedSelectionUID() {
      return null // TODO
    },
    _selectedSelection() {
      if (!this._selectedSelectionUID) return null
      return selectionStore().get(this._selectedSelectionUID);
    },
    _selectedSelectionOFCUID() {
      if (!this._selectedSelection) return null
      return this._selectedSelection.offerFamilyChoiceFixedUID
    },
    _contextStore() {
      return contextStore();
    },
    _game() {
      if (!this.item) return null
      return gameStore().get(this.item.gameUID)
    },
    _leagueUID() {
      if (!this._game) return null
      return this._game.leagueUID
    },
    _league() {
      return leagueStore().get(this._leagueUID)
    },
    _area() {
      if (!this._league) return null
      return this._league.area
    },
    _flags() {

      if (!this._game) return []

      const results = []
      const params = {
        sport: this._contextStore.getSport,
        area: this._area,
        teamUID: this._game.homeTeamUID,
        leagueUID: this._leagueUID,
        previousGameLeagueUID: this._game.previousGameLeagueUID,
        nextHomeTeamGameLeagueUID: this._game.nextHomeTeamGameLeagueUID,
      }
      flagStore().matches(params).forEach(flag => {
        results.push(flag)
      })
      params.teamUID = this._game.awayTeamUID
      flagStore().matches(params).forEach(flag => {
        results.push(flag)
      })

      // On supprime les doublons (basé sur l'uid)
      const flags = []
      results.forEach(flag => {
        if (!flags.find(f => f.uid === flag.uid)) {
          flags.push(flag)
        }
      })

      return flags
    },
    _cssClasses() {
      return {
        'selection-pane-item': true,
        'selected': this._isSelected
      };
    },
    _selectedGameUID() {
      return statsPaneStore().selectedGameUID
    },
    _isSelected() {
      return this._selectedGameUID === this.item.gameUID;
    },
    _isInNotebook() {
      return notebookStore().isIn(this.item.gameUID)
    },
    _personalNote() {
      return noteStore().getByGameUID(this.item.gameUID)
    },
    _personalNoteContent() {
      if (!this._personalNote) return null
      if (!this._personalNote.content) return null
      return this._personalNote.content
    },
    _arg() {
      return statsRouteStore().getOfferArgument()
    },
    _matchingStats() {
      return statsRouteStore().getGameMatchingData(this.item.gameUID)
    },
    _rawMatchingScore() {
      if(!this.item) return '-.-'
      return this.item.rawMatchingScore.toFixed(1)
    },
    _normalizedMatchingScore() {
      return this.item.normalizedMatchingScore * 6
    },
  },

  methods: {
    _openGameDetails() {
      statsRouteStore().setOverContext({
        statUID: this.item.statUID,
        gameUID: this.item.gameUID,
        leagueUID: this.item.leagueUID
      }, true)
      statsPaneStore().setSelectedGameUID(this.item.gameUID)
    },
  }
}
</script>

<template>
  <div>
    <div :class="_cssClasses">
      <selection-pane-item-line1 :game-uid="item.gameUID" @click="_openGameDetails"/>
      <div class="pb-1">
        <div v-for="offer in _offers" v-if="_offers && !_isOffersAreLoading" class="flex-grow flex">
          <offer-choice-button-new :offer-family="offer" :choice="c" :argument="_arg" v-for="c in offer.choices"
                                   v-if="offer" class="flex-grow flex mx-1"
                                   :selected="c.fixedUID === _selectedSelectionOFCUID" :loading="_isOffersAreLoading"/>
        </div>
        <div v-if="_isOffersAreLoading" class="flex-grow flex">
          <offer-choice-button-new :offer-family="null" :choice="null" :argument="null" class="flex-grow flex mx-1"
                                   :loading="_isOffersAreLoading"/>
          <offer-choice-button-new :offer-family="null" :choice="null" :argument="null" class="flex-grow flex mx-1"
                                   :loading="_isOffersAreLoading"/>
          <offer-choice-button-new :offer-family="null" :choice="null" :argument="null" class="flex-grow flex mx-1"
                                   :loading="_isOffersAreLoading"/>
        </div>
      </div>
      <div class="personal-note" v-if="(_isInNotebook || _isSelected) && _personalNoteContent">
        <p>{{ _personalNoteContent }}</p>
      </div>
      <hr/>
      <div class="matching-note-line flex" v-if="_normalizedMatchingScore > 0">
        <h5 class="flex-grow text-left">Comment ça match ?</h5>
        <div class="item-matching-indicator">

          <font-awesome-icon icon="fa-regular fa-star" v-if="_normalizedMatchingScore < 1/6.0"
                             :style="{color: _normalizedMatchingScore > ((0/6.0 + 1/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>
          <font-awesome-icon icon="fa-solid fa-star" v-else
                             :style="{color: _normalizedMatchingScore >= ((1/6.0 + 2/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>

          <font-awesome-icon icon="fa-regular fa-star" v-if="_normalizedMatchingScore <= 3/6.0"
                             :style="{color: _normalizedMatchingScore >= ((2/6.0 + 3/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>
          <font-awesome-icon icon="fa-solid fa-star" v-else
                             :style="{color: _normalizedMatchingScore >= ((3/6.0 + 4/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>

          <font-awesome-icon icon="fa-regular fa-star" v-if="_normalizedMatchingScore <= 5/6.0"
                             :style="{color: _normalizedMatchingScore >= ((4/6.0 + 5/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>
          <font-awesome-icon icon="fa-solid fa-star" v-else
                             :style="{color: _normalizedMatchingScore >= ((5/6.0 + 6/6.0) / 2.0) ? '#49aa72' : '#464749'}"
                             class="mr-1"/>
          <button class="small square p-0 mr-1">
            {{ _rawMatchingScore }}
          </button>
        </div>
      </div>

      <div v-if="_isSelected">
        <statistic v-for="s in _matchingStats" :key="s.statUID" :uid="s.statUID" :period="s.period"/>
      </div>

      <div v-if="_flags.length > 0">
        <flag :item="flag" v-for="flag in _flags" :key="flag.uid" class="m-1"/>
      </div>
    </div>

    <div v-if="showAd" class="inline-block text-center my-5">
      <referring-ad/>
      <!--<gambling-affiliation-ad/>-->
    </div>
  </div>
</template>

<style scoped lang="less">
@import "@/assets/styles/variables.less";

.selection-pane-item {
  background: @background-color;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;
  border: 1px solid #444;

  &.selected {
    border-top: 1px solid @accent-color;

    .game-item-header {
      background: linear-gradient(180deg, #494081aa 0%, #49408199 33%, #49408133 50%, #FFFFFF00 100%) !important;
    }
  }

  &:hover {
    border-top: 1px solid #f97f28;

    .game-item-header {
      background: linear-gradient(180deg, #f97f2877 0%, #f97f2855 33%, #fea32822 50%, #FFFFFF00 100%) !important;
    }
  }

  .personal-note {
    border-top: 1px solid @stroke-color;
    font-style: italic;
    background: @background-color-light;

    p {
      padding: @padding-regular;
      margin: 0;
    }

  }

}
</style>