<script>

import NotificationInstanceBetAddedInBankroll from "@/components/common/modal/notifications/instance/NotificationInstance.BetAddedInBankroll.vue";
import NotificationInstanceBetNotCompliantWithSelection from "@/components/common/modal/notifications/instance/NotificationInstance.BetNotCompliantWithSelection.vue";
import {notificationStore} from "@/stores/Notification.store";

export default {
  name: "NotificationInstance",

  components: {
    NotificationInstanceBetAddedInBankroll,
    NotificationInstanceBetNotCompliantWithSelection
  },

  props: {
    instance: {
      type: Object,
      required: true
    }
  },

  mounted() {
    // mark notification as read
    notificationStore().markAsRead(this.instance.uid, () => {
      setTimeout(() => {
        this.instance.readDate = new Date();
      }, 10000);
    });
  },

  computed:{
    _component(){
      const componentName = 'NotificationInstance' + this.instance.type
          .toLowerCase()
          .split('_')
          .map((word, index) =>
              index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.charAt(0).toUpperCase() + word.slice(1)
          )
          .join('');

      // Check if the component exists
      if (this.$options.components[componentName]) {
        return componentName; // Return the component name
      } else {
        console.error(`Component ${componentName} does not exist`);
        return null;
      }
    }
  }
}
</script>

<template>
  <div class="instance-notification" v-if="_component">
    <component :is="_component" :instance="instance" :unread="!instance.readDate"/>
  </div>
</template>

<style lang="less">
@import "@/assets/styles/variables.less";

.instance-notification {
  background: rgb(255, 255, 255);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.08) 20%, rgba(115, 115, 115, 0.05) 33%, rgba(115, 115, 115, 0.10) 100%);
  border-radius: @radius-regular;
  cursor: pointer;

  &.unread {
    background: @title-color;
    background: linear-gradient(135deg, fade(@title-color, 40%) 0%, fade(@title-color, 35%) 20%, rgba(115, 115, 115, 0.05) 50%, rgba(115, 115, 115, 0.10) 100%);
  }

  .instance-notification-header {
    font-size: 8pt;
    padding: @padding-small;
    color: @text-color;
    border-bottom: 1px solid @stroke-color;
  }
}
</style>